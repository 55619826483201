import { Box, Button, Input, Typography } from "@mui/material";
import { style } from "../../../util/themes/styles";
import { useEffect, useState } from "react";
import { useNotification } from "../../../util/NotificationContext";
import { FetchStockList } from "../../../api/stocks";
import { AdddStock } from "../../../api/discover";
import addCircleIcon from "../../../assets/images/addCircleIcon.png";
import clearIcon from "../../../assets/images/ClearIcon.png";
import { IToastTypes } from "../../../components/toast/Toast";
import searchIcon from "../../../assets/images/searchIcon.png";
import useDeviceType from "../../../util/useDeviceType";

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // hwight: 'auto',
        height: '630px',
        justifyContent: 'space-between',
       
        overflow:'scroll',
        '& .MuiInputBase-root': {
            borderRadius: '8px',
            background: '#343942',
            width: '100%',
            padding: '10px'
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        },
        ul: {
            width: '100%'
        },
        [style.breakpoints.down('md')]: {
            fontSize: '12px',
            height:'80%',
        }
    },
    selectedList: {
        borderRadius: '8px',
        padding: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: ' space-between',
        margin: '8px 0px',
        background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))'
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        // margin: '10px',
        width: '104px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
    chipView: {
        height: '80%',
        overflow: 'scroll',
        width: '100%',
    },
    selectedChipView: {
        height: '200px',
        overflow: 'scroll',
        width: '100%',
    }
}

interface IAddNewOrder {
    buttonText?: string;
    modalHeading?: string;
    handleClose?: any;
    listName?: any;
    selectedCategory?: any;
    stockItemId?: any;
    setRowsDataUpdated?:any;
    setstockItemIds?:any;
    setUpdatedStockList?:any;
}

interface Item {
    id: number;
    name: string;
}

export const AddStock = (props: IAddNewOrder) => {
    const { setRowsDataUpdated, modalHeading, handleClose, selectedCategory, stockItemId ,setUpdatedStockList,setstockItemIds} = props;
    const [rowsData, setRowsData] = useState([]);
    const { setNotify } = useNotification();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<Item[]>([]);
    const [selectedItemIds, setSelectedItemIds] = useState<any>(stockItemId);
    const [serachItems, setSearchItem] = useState<any>([]);
    const {isMobile} = useDeviceType();

    console.log("selectedItemIds",selectedItemIds);
    console.log("selectedItems",selectedItems);
    

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        const filteredRows = rowsData.filter((row: any) => {
            return row?.shortName?.includes(searchTerm) || row?.shortName?.toLowerCase()?.includes(searchTerm) || row?.sr?.startsWith(searchTerm);;
        });
        setSearchItem(filteredRows);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ';' && searchTerm.trim() !== '') {
            const selectedItem: any = rowsData.find((item: any) => item?.sr.toLowerCase() === searchTerm.toLowerCase());
            if (selectedItem !== undefined) {
                if (selectedItem && !selectedItems.find((item: any) => item.id === selectedItem.id)) {
                    setSelectedItems([...selectedItems, selectedItem]);
                    setSearchTerm('');
                }

                setSelectedItemIds((prevSelected: any) => {
                    if (prevSelected.includes(selectedItem)) {
                        return prevSelected.filter((id: any) => id !== selectedItem._id);
                    } else {
                        return [...prevSelected, selectedItem._id];
                    }
                });
                setSearchTerm('');
            }
        }
    };

    const handleRemoveItem = (itemId: number) => {
        const updatedSelectedItems = selectedItems.filter(item => item.id !== itemId);
        setSelectedItems(updatedSelectedItems);
    };

    const handleSelect = (item: any) => {
        const selectedIndex = selectedItems.findIndex(selected => selected.id === item.id);
        if (selectedIndex === -1) {
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems.filter(selected => selected.id !== item.id));
        }

        setSelectedItemIds((prevSelected: any) => {
            if (prevSelected.includes(item._id)) {
                return prevSelected.filter((id: any) => id !== item._id);
            } else {
                return [...prevSelected, item._id];
            }
        });

    };

    const handleCancelLastItem = (itemToRemove: any) => {
        setSearchTerm('');
    };

    const handleSubmit = () => {
        addStockInList();
    }
    const getStock = async () => {
        await FetchStockList()
            .then((res) => {
                if (res.status === 200) {
                    const data = res?.data?.data;
                    setRowsData(
                        data.filter((stock: any) => !stock.isDelete)
                            .map((stock: any, index: any) => ({
                                id: index + 1,
                                _id: stock._id,
                                image: stock?.image,
                                shortName: stock?.shortName,
                                sr: stock?.sr,
                            }))
                    );
                }
            })
    }

    const addStockInList = async () => {
        await AdddStock(selectedItemIds, selectedCategory)
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>",res.data);
                    const data = res?.data?.stockData;
                    setUpdatedStockList(res?.data?.data?.stockIds);
                    setRowsDataUpdated(
                        data?.map((stock: any, index: any) => {
                                setstockItemIds((prev: any) => {
                                    return [...prev, stock._id];
                                })
                                return ({
                                    id: index + 1,
                                    _id: stock._id,
                                    image: stock?.image,
                                    shortName: stock?.shortName,
                                    sr: stock?.sr,
                                    pl_percentage: stock?.pl_percentage,
                                    industry: stock?.industry,
                                    current_price: stock?.current_price.toFixed(2),
                                })
                            })
                    );
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                    handleClose(data);
                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
    }

    useEffect(() => {
        getStock();
    }, [])

    return (
        <Box  width={isMobile?'300px':'500px'} height={isMobile?'100%':'700px'}>
            <Box sx={styles.loginForm}>
                <div style={{ width: '100%' }}>
                    Search Stock
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Search "
                        startAdornment={
                            <img src={searchIcon} alt="clear" height='14px' width='14px' style={{ marginRight: '4px', marginBottom: '2px' }} />
                        }
                        endAdornment={(

                            <Button onClick={handleCancelLastItem} size="small">
                                <img src={clearIcon} alt="clear" height='18px' width='18px' />
                            </Button>

                        )}
                    />
                    {/* {selectedItems?.length > 0 &&
                        <ul style={styles.selectedChipView} className="customScroll">
                            {selectedItems.map((item: any) => (
                                <li key={item.id} style={styles.selectedList}>
                                    {item?.shortName}
                                    <button onClick={() => handleRemoveItem(item.id)}>
                                        <img src={clearIcon} alt="clear" height='24px' width='24px' />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    } */}
                </div>
                <div style={styles.chipView} className="customScroll">
                    {serachItems.map((item: any) => (
                        <li key={item.id} style={styles.selectedList}>
                            {/* {`${item?.shortName} (${item?.sr})`} */}
                            {item?.shortName}
                            {selectedItemIds.includes(item._id) ? 
                            <button onClick={() => handleRemoveItem(item.id)}>
                               <img src={clearIcon} alt="clear" height='22px' width='22px' />
                            </button> : <button onClick={() => handleSelect(item)}>
                                <img src={addCircleIcon} alt="clear" height='26px' width='26px' />
                            </button>}
                        </li>
                    ))}
                </div>

                <div className="form-group  mt-3" style={styles.formGroup}>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleSubmit}
                        sx={styles.addRecordButton}>
                        <Typography>Submit</Typography>
                    </Button>
                </div>
            </Box>
        </Box >
    )
}