import axios from 'axios'
import { CHANGE_DEFAULT_AMOUNT, EDIT_CRON, FEEDBACK, GET_DEFAULT_AMOUNT, GET_STOCK_LIMIT, GET_TRADE_LIMIT, GET_WATCHLIST_LIMIT, STOCKS_LIMIT, TRADE_LIMIT, USER_KYC, WATCHLIST_LIMIT } from '../constant/common';

export const ChangeDefaultAmount = async (value: any) => {
    try {
        return await axios.put(`${CHANGE_DEFAULT_AMOUNT}`, {
            balance: value,
            // reward: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const getTradeLimit = async () => {
    try {
        return await axios.get(`${GET_TRADE_LIMIT}`);
    }
    catch (error) {
        throw error;
    }
}

export const getStockLimit = async () => {
    try {
        return await axios.get(`${GET_STOCK_LIMIT}`);
    }
    catch (error) {
        throw error;
    }
}

export const getWatchlistLimit = async () => {
    try {
        return await axios.get(`${GET_WATCHLIST_LIMIT}`);
    }
    catch (error) {
        throw error;
    }
}


export const getDefaultAmount = async () => {
    try {
        return await axios.get(`${GET_DEFAULT_AMOUNT}`);
    }
    catch (error) {
        throw error;
    }
}

export const EditCronTime = async (value: any) => {
    try {
        return await axios.put(`${EDIT_CRON}`, {
            id: "65ca14cbb039184171e8ff7d",
            screenName: "updateStockPrice",
            expression: value,
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditWatchlistLimit = async (value: any) => {
    try {
        return await axios.post(`${WATCHLIST_LIMIT}`, {
            limit: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditTradeLimit = async (value: any) => {
    try {
        return await axios.post(`${TRADE_LIMIT}`, {
            tradeLimit: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditStocksLimit = async (value: any) => {
    try {
        return await axios.post(`${STOCKS_LIMIT}`, {
            limit: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const FeedbackList = async () => {
    try {
        return await axios.get(`${FEEDBACK}`,
        );
    }
    catch (error) {
        throw error;
    }
}

export const getUserKYC = async() =>{
    try {
        return await axios.get(`${USER_KYC}`,
        );
    }
    catch (error) {
        throw error;
    }
}