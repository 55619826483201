import { Theme } from "@emotion/react";
import { Box, Snackbar, SvgIcon, SxProps, Typography } from "@mui/material";
import { ReactComponent as SuccessIcon } from '../../assets/images/toast_success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/toast_error.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast_close.svg';

export enum IToastTypes {
    Success = 'success',
    Error = 'error'
}

interface IToastProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    message: string,
    type?: IToastTypes,
    style?: SxProps<Theme>,
    successMessageStyle?: SxProps<Theme>,
    vertical?: 'top' | 'bottom',
    horizontal?: 'left' | 'center' | 'right',
    icon?: React.FC,
    dismissOnClickAway?: boolean,
    showCloseIcon?: boolean,
}

const styles = {
    successToast: (showCloseIcon: boolean) => ({
        px: '18px',
        py: '13px',
        pr: showCloseIcon ? '53px' : '18px'
    }),
    errorToast: (showCloseIcon: boolean) => ({
        p: '20px',
        pr: showCloseIcon ? '55px' : '20px'
    }),
    successTextStyle: {
        maxWidth: 170,
        ml: '12px'
    },
    errorTextStyle: {    
        maxWidth: 285,
        ml: '12px',
    },
    closeIconContainer: (type: IToastTypes) => ({
        backgroundColor: type === IToastTypes.Success ? '#07A82A' : '#FF4F4F',
        borderRadius: '100px',
        width: '27px',
        height: '27px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 8,
        right: 8,
        cursor: 'pointer',
        '.MuiSvgIcon-root': {
            width: '14px',
            height: '14px',
            color: type === IToastTypes.Success ? '#DCFFDD' : '#FFF3F3'
        }
    }),
    toast: {
        default: {
          boxShadow: '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
        },
        success: {
          backgroundColor: '#07A82A',
        },
        error: {
          backgroundColor: '#CF0000',
        },
        successText: {
          fontFamily: 'Nunito',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#DCFFDD',
      },
      errorText: {
        fontFamily: 'Nunito',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#FFFFFF',
      }
    }
}

const Toast = (props: IToastProps) => {
    const {
        open,
        setOpen,
        message,
        type = IToastTypes.Success,
        style = {},
        successMessageStyle = {},
        vertical = 'top',
        horizontal = 'center',
        icon,
        dismissOnClickAway = false,
        showCloseIcon = true
    } = props;

    const autoHideDuration = 2000; 

    const defaultBoxStyle = type === IToastTypes.Success ?
    { ...styles?.toast?.success, ...styles.successToast(showCloseIcon) } :
    { ...styles?.toast?.error, ...styles.errorToast(showCloseIcon) };

const defaultMessageStyle = type === IToastTypes.Success ?
    { ...styles?.toast?.successText, ...styles.successTextStyle, ...successMessageStyle } :
    { ...styles?.toast?.errorText, ...styles.errorTextStyle };

    const toastIcon = icon ?? type === IToastTypes.Success ? SuccessIcon : ErrorIcon;

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (!dismissOnClickAway && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const renderCloseIcon = () => {
        return (
            <Box sx={styles.closeIconContainer(type)} onClick={() => setOpen(false)}>
                <SvgIcon component={CloseIcon} inheritViewBox />
            </Box>
        )
    }

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
        <Box display={'flex'} flexDirection={'row'} sx={{ ...styles?.toast?.default, ...defaultBoxStyle, ...style }}>
            <SvgIcon component={toastIcon} inheritViewBox />
            <Typography sx={defaultMessageStyle}>{message}</Typography>
            {showCloseIcon && renderCloseIcon()}
        </Box>
    </Snackbar >
    )
}

export default Toast;