import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Paper, SvgIcon, Popper, List, ListItemButton, ListItemText, ClickAwayListener, IconButton } from "@mui/material"
import userIcon from '../../assets/images/userIcon.png';
import stockIcon from "../../assets/images/stockIcon.png";
import fundsIcon from "../../assets/images/fundsInOutIcon.png";
import portfolio from "../../assets/images/portfolioIcon.png";
import watchlistIcon from '../../assets/images/watchListIcon.png';
import feedbackIcon from '../../assets/images/feedbackIcon.png';
import { DataChart } from '../../components/dataChart/DataChart';
import PercentageEditable from '../../components/PercentageEditable';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useState } from 'react';
import { UpdateModal } from '../../components/UpdateModal/UpdateModal';
import { getAnalytics } from '../../api/dashboard';
import { style } from '../../util/themes/styles';
import useDeviceType from '../../util/useDeviceType';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderComponent from '../../components/HeaderComponent';

const styles = {
    root: {
        padding: '24px',
        '& .css-ibyx69-MuiModal-root .MuiBox-root ': {
            height: '40%',
            overflow: 'scroll',
        },
        [style.breakpoints.down('md')]: {
            padding: '4px',
            '& .css-eo0a4l-MuiTypography-root': {
                fontSize: '14px'
            },
            '& .css-19xqxr5-MuiTypography-root ': {
                fontSize: '16px'
            },

            '& .css-ibyx69-MuiModal-root .MuiBox-root ': {
                height: '40%',
                overflow: 'scroll',
            }
        }
    },
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                color: '#ffffff',
                fontSize: '18px',
            }
        }
    },
    card: {
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '12px',
            },
            '& .MuiTypography-root h6': {
                fontSize: '12px'
            },
        },


    },
    cardLayout: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [style.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& .MuiSvgIcon-root': {
                height: '40px',
                width: '40px',
            },
            img: {
                height: '40px',
                width: '40px',
            },
            '& .css-1ua7g6d-MuiPaper-root .MuiTypography-root': {
                fontSize: '12px'
            },
            '& .css-sky8b5-MuiTypography-root h3': {
                fontSize: '12px'
            },
        }
    }
}

export const HomePage = ({ handleDrawer }: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [homePageState, setHomePageState] = useState({
        openModal: false,
        settingsModalHeading: '',
        graphData: [],
        noOfUsers: '',
        noOfStock: '',
        avgBalancePerUser: '',
        balanceCurrency: '',
        noOfFeedback: '',
        avgPortfolio: 0,
        portfolioCurrency: '',
        avgInOut: {
            credit: 0,
            debit: 0,
            currency: '',
        },
        noOfWaitlist: 0,
        PersonalAndInvestment: 0,
        open: false,
    })
    const drawer = localStorage.getItem("open");
    const { isMobile } = useDeviceType();
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    console.log("drawer", drawer);

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleclickSettings = (name: string) => {
        console.log("handleClickSettings");

        setHomePageState((prev) => {
            return {
                ...prev,
                openModal: true,
                settingsModalHeading: name,
            }
        });
    }

    const handleClose = () => {
        console.log("handleClose");
        
        setHomePageState((prev) => {
            return {
                ...prev,
                openModal: false,
            }
        });
    }


    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'simple-popover' : undefined;


    const Item = styled(Paper)(({ theme }) => ({
        background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        height: "223px",
        width: "100%",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.text.primary,
        '& .MuiTypography-root': {
            color: '#ffffff'
        },
        '& img': {
            tintColor: '#ffffff',
            margin: '8px',
            height: '66px',
            width: '66px'
        },
        '& .MuiSvgIcon-root': {
            mr: '38px',
            color: '#FFFFFF',
            height: '66px',
            width: '66px'
        },
    }));

    const getAnayticsData = async () => {
        await getAnalytics()
            .then((res) => {
                if (res.status === 200) {
                    const data = res?.data?.data;
                    setHomePageState((prev) => {
                        return {
                            ...prev,
                            noOfUsers: data?.numberOfUser,
                            noOfStock: data?.numberOfStocks,
                            avgBalancePerUser: data?.avgBalancePerUser[0].avgAmount,
                            balanceCurrency: data?.avgBalancePerUser[0]?.currencyDetails[0]?.currencySymbol,
                            noOfFeedback: data?.feedback,
                            avgPortfolio: data?.avgPortfolio[0].avgPortfolio,
                            portfolioCurrency: data?.avgPortfolio[0]?.currencyDetails?.currencySymbol,
                            avgInOut: {
                                credit: data?.averageInOut[0].amount,
                                debit: data?.averageInOut[1].amount,
                                currency: data?.averageInOut[0]?.currencyDetails[0]?.currencySymbol,
                            },
                            noOfWaitlist: data?.numberOfWaitlist,
                            PersonalAndInvestment: data?.userPersonalAndInvestment,
                        }
                    })
                }
            })
    }


    useEffect(() => {
        getAnayticsData();
    }, [])

    return (
        <Box display={'flex'} flexDirection={'column'} sx={styles.root}>
            {/* <Box sx={styles.pageHeader}>
                <Box display={'flex'}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h3'>Hello Admin!</Typography>
                </Box>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <SettingsIcon onClick={handleClick} />
                </ClickAwayListener>
                <Popper
                    id={id}
                    open={isOpen}
                    anchorEl={anchorEl}
                    placement={"left-start"}
                    style={{ zIndex: 2 }}
                >
                    <Box
                        className="popper-dropdown-box"
                        sx={{
                            borderRadius: "10px",
                            boxShadow:
                                "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
                            backgroundColor: "#FFF",
                            //   position: "absolute",
                            left: "0px",
                            top: "0px",
                            '& .MuiTypography-root ': {
                                color: '#000000',
                            }
                        }}
                    >
                        <List component="nav">
                           
                            <ListItemButton selected={false} >
                                <ListItemText primary={"Watchlist per user"} onClick={() => handleclickSettings('Watchlist per user')} />
                            </ListItemButton>
                            <ListItemButton selected={false} >
                                <ListItemText primary={"Stocks per watchlist"} onClick={() => handleclickSettings('Stocks per watchlist')} />
                            </ListItemButton>
                            <ListItemButton selected={false} >
                                <ListItemText primary={"Set trade limit"} onClick={() => handleclickSettings('Set trade limit')} />
                            </ListItemButton>
                            <ListItemButton selected={false}>
                                <ListItemText primary={"Set amount limit"} onClick={() => handleclickSettings('Set amount limit')} />
                            </ListItemButton>
                        </List>
                    </Box>fo
                </Popper>

            </Box> */}
            <HeaderComponent handleDrawer={handleDrawer} handleclickSettings={handleclickSettings} settings heading={'Hello Admin!'} />
            <Grid container spacing={2} sx={{ padding: isMobile ? '4px' : '24px' }}>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={userIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Users</Typography>
                                <Typography variant='h4'>{homePageState?.noOfUsers}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={stockIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Stock</Typography>
                                <Typography variant='h4'>{homePageState?.noOfStock}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={feedbackIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Feedback</Typography>
                                <Typography variant='h4'>{homePageState?.noOfFeedback}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={watchlistIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Waitlist</Typography>
                                <Typography variant='h4'>{homePageState?.noOfWaitlist}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ padding: isMobile ? '4px' : '24px' }}>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={userIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Avg Balance</Typography>
                                <Typography variant='h4'>{homePageState?.balanceCurrency}{homePageState?.avgBalancePerUser}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={stockIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Survey</Typography>
                                <Typography variant='h4'>{homePageState?.PersonalAndInvestment}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={fundsIcon} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Avg fund In/Out</Typography>
                                <Typography variant='h5'>Funds: {homePageState?.avgInOut?.currency}{homePageState?.avgInOut?.credit.toFixed(2)}</Typography>
                                <Typography variant='h5' >Withdrawal: {homePageState?.avgInOut?.currency}{homePageState?.avgInOut?.debit.toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={6} lg={3} sx={styles.card}>
                    <Item>
                        <Box sx={styles.cardLayout}>
                            <img src={portfolio} alt='ICON' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='h5'>Avg Portfolio</Typography>
                                <Typography variant='h4'>{homePageState?.portfolioCurrency}{homePageState?.avgPortfolio.toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    </Item>
                </Grid>
            </Grid>
            <DataChart />
            {homePageState?.openModal &&
                <UpdateModal isOpenModal={homePageState?.openModal} handleClose={handleClose}  backdrop={false}>
                    <PercentageEditable heading={homePageState?.settingsModalHeading} handleClose={handleClose} />
                </UpdateModal>
            }
        </Box>
    )
}