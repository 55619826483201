import axios from "axios";
import { ADD_IMAGES_IN_STOCKCATEGORIES, ADD_STOCK_IN_CONTENT_CATEGORY, CREATE_CATEGORY_CONTENT, CREATE_CONTENTLIST, DELETE_CATEGORY_cONTENT, DELETE_CONTENTLIST, DELETE_IMAGES_IN_STOCKCATEGORY, EDIT_CATEGORY_cONTENT, EDIT_CONTENTLIST, GET_CONTENTLIST } from "../constant/common";


export const getContentList = async () => {
    try {
        return await axios.get(`${GET_CONTENTLIST}`);
    }
    catch (error) {
        throw error;
    }
}

export const CreateContentList = async (value: any) => {
    try {
        return await axios.post(`${CREATE_CONTENTLIST}`, {
            name: value.listName,
            style: value.style,
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditContentList = async (value: any) => {
    try {
        return await axios.post(`${EDIT_CONTENTLIST}`, {
            listIds: value      // Array of objects
        });
    }
    catch (error) {
        throw error;
    }
}

export const DeleteContentList = async (value: any) => {
    try {
        return await axios.post(`${DELETE_CONTENTLIST}`, {
            listIds: value      // Array of Ids
        });
    }
    catch (error) {
        throw error;
    }
}

export const CreateCategoryContentList = async (formData: any) => {
    try {
        return await axios.post(`${CREATE_CATEGORY_CONTENT}`, formData,
            { headers: { 'content-type': 'multipart/form-data' } });
    }
    catch (error) {
        throw error;
    }
}

export const AddImagesInStockCategory = async (formData: any) => {
    try {
        return await axios.post(`${ADD_IMAGES_IN_STOCKCATEGORIES}`, formData,
            { headers: { 'content-type': 'multipart/form-data' } });
    }
    catch (error) {
        throw error;
    }
}

export const  EditCategoryContentList = async(value:any,id:string) =>{
    try {
        return await axios.post(`${EDIT_CATEGORY_cONTENT}`, {
            listId: id,
            categoryNames:value
        });
    }
    catch (error) {
        throw error;
    }
}

export const DeleteCategoryContentList = async (value: any, id: any) => {
    try {
        return await axios.post(`${DELETE_CATEGORY_cONTENT}`, {
            listId: id,
            categoryIds: value
        });
    }
    catch (error) {
        throw error;
    }
}
export const DeleteImagesContentList = async (formData: any) => {
    try {
        return await axios.post(`${DELETE_IMAGES_IN_STOCKCATEGORY}`, formData,
            { headers: { 'content-type': 'multipart/form-data' } });
    }
    catch (error) {
        throw error;
    }
}


export const AdddStockInContentCategory = async (value: any, id: any) => {
    try {
        return await axios.post(`${ADD_STOCK_IN_CONTENT_CATEGORY}`, {
            catId: id,
            stockIds: value
        });
    }
    catch (error) {
        throw error;
    }
}
