import { Box, InputBase, InputBaseProps, Paper, Popper, Typography } from '@mui/material'
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { GridColTypeDef } from '@mui/x-data-grid';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';
import { FeedbackList } from '../../api/config';
import { style } from '../../util/themes/styles';
import moment from 'moment';
import HeaderComponent from '../../components/HeaderComponent';


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px ',
    [style.breakpoints.down('md')]: {
      padding: '4px ',
    }
  },
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: '#ffffff'
    },
    [style.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 500,
      }
    }
  },
  DataGridStyle: {
    '& MuiPaper-root  ': {
      background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%);",
      color: 'black',
    },
    '& .MuiInputBase-root': {
      color: '#1f2937'
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
    [style.breakpoints.down('md')]: {
      padding: '2px  13px',
      margin: '0px',
    }
  },
  tableGrid: {
    display: 'flex',
    padding: '50px 0px',
    justifyContent: 'center',
    [style.breakpoints.down('md')]: {
      padding: '30px 0px'
    }
  }
}

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();


  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event,
      );
    },
    [apiRef, field, id],
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start" sx={styles.DataGridStyle}>
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: 'both' }, width: '100%' }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}


export const Feedback = ({handleDrawer}:any) => {
  const [rowsData, setRowsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [feedbackState, setFeedbackState] = useState({
    loading: false,
  });

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const userNameColumn: GridColDef = {
    field: "userName",
    headerName: "User Name",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const feedbackColumn: GridColDef = {
    field: "message",
    headerName: "Feedback",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const ratingColumn: GridColDef = {
    field: "rating",
    headerName: "Rating",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const updatedAtColumn: GridColDef = {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const columns: GridColDef[] = [];
  columns.push(idColumn);
  columns.push(userNameColumn);
  columns.push(feedbackColumn);
  columns.push(ratingColumn);
  columns.push(updatedAtColumn);


  const handleData = (data: any, searchTerm: any) => {

    const filteredRows = data.filter((row: any) => {
      return row?.message?.toLowerCase()?.includes(searchTerm) || row?.userName?.toLowerCase()?.startsWith(searchTerm);
    });

    setRowsData(filteredRows)
  }

  const getUsersFeedback = async () => {
    setFeedbackState((prev) => {
      return {
        ...prev,
        loading: true,
      }
    })
    await FeedbackList()
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          // rows
          setRowsData(
            data.map((stock: any, index: any) => ({
              id: index + 1,
              _id: stock._id,
              userName: `${stock?.user?.firstName} ${stock?.user?.lastName} `,
              message: stock?.feedback,
              rating: stock?.rating,
              updatedAt: moment(stock?.updatedAt).format('lll'),
            }))
          );
        }
      })
    setFeedbackState((prev) => {
      return {
        ...prev,
        loading: false,
      }
    })
  }

  const handleIsEmpty = () => {
    getUsersFeedback();
  }

  useEffect(() => {
    getUsersFeedback()
  }, [])

  return (
    <Box sx={styles.root} >
      <HeaderComponent  handleDrawer={handleDrawer} heading={'Feedback'}/>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} handleIsEmpty={handleIsEmpty} />
        <Box sx={styles.tableGrid}>
          <DataGridTable
            rowsData={rowsData}
            columns={columns}
            setRows={setRowsData}
            setRowModesModel={setRowModesModel}
            loading={feedbackState.loading}
          />
        </Box>
      </Box>
    </Box>
  )
}
