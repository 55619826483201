import axios from "axios";
import { GET_SWITCHLIST, UPDATE_SWITCHLIST } from "../constant/common";


export const FetchSwitchList = async () => {
    try {
        return await axios.get(`${GET_SWITCHLIST}`,
        );
    }
    catch (error) {
        throw error;
    }
}

export const UpdateSwitch = async (value: any) => {
    console.log("value==>",value);
    
    try {
        return await axios.post(`${UPDATE_SWITCHLIST}`, {
            tradeExecution: value?.tradeExecution,
            incomeStatement: value?.incomeStatement,
            balanceSheet: value?.balanceSheet,
            cashFlow: value?.cashFlow,
            shariaScore: value?.shariaScore,
            currencySettings: value?.currencySettings,
            positionsByAssetClass:value?.positionsByAssetClass,
        });
    }
    catch (error) {
        throw error;
    }
}
