import { Box, ClickAwayListener, IconButton, List, ListItemButton, ListItemText, Popper, Typography } from '@mui/material';
import menuIcon from '../assets/images/toggleIcon.png';
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useEffect, useState } from 'react'
import { style } from '../util/themes/styles';
import useDeviceType from '../util/useDeviceType';

const styles = {
    pageHeader: (isMobile: boolean) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        '& MuiSvgIcon-root': {
            height: isMobile ? '30px' : '40px',
            width: isMobile ? '30px' : '40px',
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                color: '#ffffff',
                fontSize: '18px',
            },
            '& MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            },
        }
    }),
}

interface IHeaderComponent {
    handleDrawer?: any;
    handleclickSettings?: any;
    settings?: boolean;
    heading?: string;
}
const HeaderComponent = (props: IHeaderComponent) => {
    const { handleDrawer, handleclickSettings, settings, heading } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(true);
    const { isMobile } = useDeviceType();

    const handleClick = (event: any) => {
        console.log("event", event);

        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleclickSetting = (e:React.TouchEvent<HTMLDivElement>,value?:string) =>{
        e.stopPropagation()
        handleclickSettings(value)
    }


    const handleDrawerOpen = () => {
        setOpen(!open);
        handleDrawer(open);

    }

    useEffect(() => {
        handleDrawer(true);
    }, [])

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? 'simple-popover' : undefined;
    return (
        <Box sx={styles.pageHeader(isMobile)}>
            <Box display={'flex'} alignItems={'center'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                // sx={{ ...(homePageState.open && { display: 'none' }) }}
                >
                   <img src={menuIcon}  alt='Icon' height={'24px'} width={'24px'}/>
                </IconButton>
                <Typography variant='h3'>{heading}</Typography>
            </Box>
            {settings &&
                <>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <SettingsIcon onClick={handleClick} />
                    </ClickAwayListener>

                    <Popper
                        id={id}
                        open={isOpen}
                        anchorEl={anchorEl}
                        placement={"left-start"}
                        style={{ zIndex: 2 }}
                    >
                        <Box
                            className="popper-dropdown-box"
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
                                backgroundColor: "#FFF",
                                //   position: "absolute",
                                left: "0px",
                                top: "0px",
                                '& .MuiTypography-root ': {
                                    color: '#000000',
                                }
                            }}
                        >
                            <List component="nav">
                                {/* <ListItemButton selected={false} >
                        <ListItemText primary={"Market frequency"} onClick={() => handleclickSettings('Market frequency')} />
                    </ListItemButton> */}
                                <ListItemButton selected={false} onClick={() => handleclickSettings('Watchlist per user')} onTouchStart={(e) => handleclickSetting(e,'Watchlist per user')} >
                                    <ListItemText primary={"Watchlist per user"} />
                                </ListItemButton>
                                <ListItemButton selected={false} onClick={() => handleclickSettings('Stocks per watchlist')} onTouchStart={(e) => handleclickSetting(e,'Stocks per watchlist')}>
                                    <ListItemText primary={"Stocks per watchlist"} />
                                </ListItemButton>
                                <ListItemButton selected={false} onClick={() => handleclickSettings('Set trade limit')} onTouchStart={(e) => handleclickSetting(e,'Set trade limit')} >
                                    <ListItemText primary={"Set trade limit"} />
                                </ListItemButton>
                                <ListItemButton selected={false} onClick={() => handleclickSettings('Set amount limit')} onTouchStart={(e) => handleclickSetting(e,'Set amount limit')}>
                                    <ListItemText primary={"Set amount limit"} />
                                </ListItemButton>
                            </List>
                        </Box>
                    </Popper>
                </>
            }

        </Box>
    )
}

export default HeaderComponent