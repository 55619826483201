import React, { useContext, useState } from 'react';
import './App.css';
import { Box, ThemeProvider } from '@mui/material';
import SideNavBar from './components/sideNavBar/SideNavBar';
import { AppRoutes } from './components/app-routes/AppRoutes';
import { AuthContextProvider, useAuth } from './util/AuthContext';
import { Login } from './pages';
import { theme } from "../src/util/themes/styles";
import NotificationProvider from './util/NotificationContext';
import AppNotification from './components/app-notification/AppNotification';
const AppContext = React.createContext<any>({
  Toast: {
    showToast: false,
    setShowToast: () => { },
  }
});

const useToast = () => useContext<any>(AppContext);

const AppContainer = () => {
  const [loading, setLoading] = useState(true);
  const { setIsInitialized } = useAuth();
  const [isOpen,setIsOpen] = useState();
  const loginFlag = localStorage.getItem("LoginFlag");
  const drawer = localStorage.getItem("open");
  const handleDrawer = (data:any) =>{
    console.log("data",data);
    setIsOpen(data);

  }

  if (loginFlag === "true") {
    return (
      <SideNavBar drawer={isOpen}>
        <Box sx={{ background: "linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)" }}>
          <AppRoutes handleDrawer={handleDrawer}/>
        </Box>
      </SideNavBar>
    )
  }
  else {
    return <Login setIsInitialized={setIsInitialized} setLoading={setLoading} />
  }
}

export default function App() {
  const [showToast, setShowToast] = useState(false);
  return (
    <AppContext.Provider value={{
      showToast,
      setShowToast,
    }}>
      <AuthContextProvider>
        <NotificationProvider>
          <AppNotification />
          <ThemeProvider theme={theme}>
            <div className="App">
              <AppContainer />
            </div>
          </ThemeProvider>
        </NotificationProvider>
      </AuthContextProvider>
    </AppContext.Provider>
  );
}

export { useToast };
