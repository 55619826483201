import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
    typography: {
        fontFamily: ['Roboto'].join(','),
        overline: {
            letterSpacing: '0.4%',
        },
    },
    palette: {
        primary: {
            main: '#3FA2A1',
            dark: '#35919D',
            light: '#70D9E7',
            contrastText: '#FEFEFE',
        },
        secondary: {
            main: '#3F72A2',
            dark: '#223E58',
            light: '#52C0BE',
            contrastText: '#FEFEFE',
        },
        error: {
            main: '#DC4C4B',
            dark: '#D22E2E',
            light: '#E47272',
            contrastText: '#FEFEFE',
        },
        warning: {
            main: '#FE9700',
            dark: '#F47B00',
            light: '#FEB64C',
            contrastText: '#FEFEFE',
        },
        info: {
            main: '#1F95F2',
            dark: '#1875D1',
            light: '#63B4F5',
            contrastText: '#FEFEFE',
        },
        success: {
            main: '#34C759',
            dark: '#378D3B',
            light: '#80C683',
            contrastText: '#FEFEFE',
        },
        divider: '#dddfe2',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 768,
            lg: 1025,
            xl: 1366,
            xxl: 1440,
            xxxl: 1920,
        } as any,
    },
});

export { baseTheme };