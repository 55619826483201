import { Box, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModesModel,
  GridRowModes,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { style } from '../../util/themes/styles';
import { UpdateModal } from '../../components/UpdateModal/UpdateModal';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { AddNewRecord } from './components/AddNewRecord';
import { ConfirmDelete } from '../../components/dataGridTable/components/ConfirmDelete';
import { DeleteStock, FetchStockList } from '../../api/stocks';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useNotification } from '../../util/NotificationContext';
import { IToastTypes } from '../../components/toast/Toast';
import moment from 'moment';
import HeaderComponent from '../../components/HeaderComponent';


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px ',
    [style.breakpoints.down('md')]: {
      padding: '4px ',
    }
  },
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: style.palette.text.primary,
    },
    [style.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 500,
      }
    }
  },
  customCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: style.palette.text.primary,
    },
    '& .MuiTypography-h6': {
      color: '#8E98A8'
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
    '& .css-rysmhu .MuiTextField-root': {
      width: '350px'
    },
    '& .css-i4bv87-MuiSvgIcon-root': {
      width: '32px',
      height: '32px'
    },
    [style.breakpoints.down('md')]: {
      padding: '2px  13px',
      margin: '0px',
    }
  },
  tableGrid: {
    display: 'flex',
    padding: '50px 0px',
    justifyContent: 'center',
    [style.breakpoints.down('md')]: {
      padding: '30px 0px'
    }
  }
}

const CustomImageCell = (imageUrl: any) => {
  return <img src={imageUrl.data} alt='Icon' height="40px" width="40px" />
}


export const ListManagement = ({handleDrawer}:any) => {
  const [rowsData, setRowsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [openModal, setOpenModal] = useState(false);
  const [buttonText, setButtonText] = useState('Save');
  const [open, setOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('Add New');
  const [updateUseDetail, setUpdateUseDetail] = useState<any>();
  const [categories, setCategories] = useState<any>([]);
  const [stocksPageData, setStocksPageData] = useState({
    stockIdForDelete: '',
    isStockDeleted: false,
    loading: false,
  })

  const { setNotify } = useNotification();

  const handleEditClick = (data: any) => () => {
    setUpdateUseDetail(data);
    setButtonText('Update');
    setModalHeading('Update')
    setOpenModal(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpen(true);
    setStocksPageData((prev: any) => {
      return {
        ...prev,
        stockIdForDelete: id
      }
    })
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleData = (data: any, searchTerm: any) => {
    const filteredRows = data.filter((row: any) => {
      return row?.shortName?.toLowerCase()?.includes(searchTerm) || row?.sr?.toLowerCase()?.startsWith(searchTerm);;
    });
    setRowsData(filteredRows)
  }

  const handleClose = () => {
    setOpenModal(false);
    getStocks();
  }

  const handleCloseDelete = () => {
    setOpen(false);
  }

  const getActions = (param: GridRenderCellParams) => {
    const isInEditMode = rowModesModel[param?.row.id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(param?.row.id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(param?.row.id)}
          color="inherit"
        />,
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(param?.row)}
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(param?.row._id)}
        color="inherit"
      />,
    ];
  }

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    minWidth: 100,
    width: 100,
    maxWidth: 350,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const logoColumn: GridColDef = {
    field: "image",
    headerName: "Logo",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => (
      <CustomImageCell data={cellValues.value} />
    ),
  };

  const stockColumn: GridColDef = {
    field: "shortName",
    headerName: "Stock Name",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const srColumn: GridColDef = {
    field: "sr",
    headerName: "SR Name",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };
  const earningColumn: GridColDef = {
    field: "earningType",
    headerName: "Earning Type",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };
  const dateColumn: GridColDef = {
    field: "date",
    headerName: "Date",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const closingPriceColumn: GridColDef = {
    field: "pl_percentage",
    headerName: "Pl Percentage",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const currentPriceColumn: GridColDef = {
    field: "current_price",
    headerName: "Current Price",
    minWidth: 100,
    width: 200,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const industryColumn: GridColDef = {
    field: "industry",
    headerName: "Industry",
    minWidth: 100,
    width: 150,
    maxWidth: 350,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const actionColumn: GridColDef = {
    field: "action",
    headerName: "Actions",
    minWidth: 100,
    width: 300,
    maxWidth: 350,
    flex: 1,
    renderCell: getActions,
    resizable: false,
  };

  const columns: GridColDef[] = [];
  columns.push(idColumn);
  columns.push(logoColumn);
  columns.push(stockColumn);
  columns.push(srColumn);
  columns.push(earningColumn);
  columns.push(dateColumn);
  columns.push(industryColumn);
  columns.push(closingPriceColumn);
  columns.push(currentPriceColumn);
  columns.push(actionColumn);

  const getStocks = async () => {
    setStocksPageData((prev) => {
      return {
        ...prev,
        loading: true,
      }
    })
    await FetchStockList()
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          setCategories(res?.data?.category)
          // rows
          setRowsData(
            data.filter((stock: any) => !stock.isDelete)
              .map((stock: any, index: any) => ({
                key: stock._id,
                id: index + 1,
                _id: stock._id,
                image: stock?.image,
                shortName: stock?.shortName,
                sr: stock?.sr,
                earningType: stock?.earningType,
                date: moment(stock?.date).format('ll'),
                pl_percentage: stock?.pl_percentage,
                industry: stock?.industry,
                current_price: stock?.current_price.toFixed(2),
              }))
          );
        }
      })

    setStocksPageData((prev) => {
      return {
        ...prev,
        loading: false,
      }
    })
  }

  const handleDelete = async () => {
    setOpen(false);
    await DeleteStock(stocksPageData?.stockIdForDelete)
      .then((res) => {
        if (res.status === 200) {
          setStocksPageData((prev) => {
            return {
              ...prev,
              isStockDeleted: !stocksPageData.isStockDeleted,
            }
          })
          setNotify({
            open: true,
            type: IToastTypes.Success,
            message: res?.data?.message,
          });
        }
      })

  }

  const handleIsEmpty = () => {
    getStocks();
  }

  useEffect(() => {
    getStocks()
  }, [stocksPageData.isStockDeleted])

  return (
    <Box sx={styles.root}>
      <HeaderComponent  handleDrawer={handleDrawer} heading={'Stock Management!'}/>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} handleIsEmpty={handleIsEmpty} />
        <Box sx={styles.tableGrid}>
          <DataGridTable
            rowsData={rowsData}
            columns={columns}
            setRows={setRowsData}
            setRowModesModel={setRowModesModel}
            setModalHeading={setModalHeading}
            setOpenModal={setOpenModal}
            setButtonText={setButtonText}
            loading={stocksPageData?.loading}
          />
        </Box>
      </Box>
      {openModal &&
        <UpdateModal isOpenModal={openModal} handleClose={handleClose}>
          <AddNewRecord buttonText={buttonText} modalHeading={modalHeading} updateUseDetail={updateUseDetail} categories={categories} handleClose={handleClose} />
        </UpdateModal>}
      {open &&
        <ConfirmDelete open={open} handleCloseDelete={handleCloseDelete} handleDelete={handleDelete} />
      }
    </Box>
  )
}
