import axios from 'axios'
import { LOGIN, LOGOUT } from '../constant/common';

export const AdminLogin = async (value: any) => {
    try {
        return await axios.post(`${LOGIN}`, {
            email: value.username,
            password: value.password,
        });
    }
    catch (error) {
        throw error;
    }
}

export const AdminLogout = async (value: any) => {
    try {
        return await axios.post(`${LOGOUT}`, {
            token: value,
        });
    }
    catch (error) {
        throw error;
    }
}



