import HomeIcon from '../assets/images/HomeIcon.png';
import discoverIcon from '../assets/images/discoverIcon.png';
import notificationIcon from '../assets/images/notificationIcon.png';
import pulseIcon from '../assets/images/pulseIcon.png';
import userIcon from '../assets/images/userIcon.png';
import watchlistIcon from '../assets/images/watchListIcon.png';
import feedbackIcon from '../assets/images/feedbackIcon.png';
import stockIcon from '../assets/images/stockIcon.png';
import discoverSvg from '../assets/images/discoverSvg.svg';
import listIcon from '../assets/images/listIcon.png';
// import HomeIcon from '@mui/icons-material/Home';

interface ISideNavSubMenu {
      title: string;
      urlKey: string;
      icon?: any;
}

interface ISideNavMenu {
      sectionTitle?: string;
      subMenu: ISideNavSubMenu[];
}

const sideMenuConfig: ISideNavSubMenu[] = [
      {
            title: 'Home',
            urlKey: '/home',
            icon: HomeIcon
      },
      {
            title: 'Stock Management',
            urlKey: '/list-management',
            icon: stockIcon
      },
      {
            title: 'Discover',
            urlKey: '/discover',
            icon: discoverIcon
      },
      {
            title: 'Content Type',
            urlKey: '/content-type',
            icon: listIcon
      },
      {
            title: 'User Management',
            urlKey: '/user-management',
            icon: userIcon
      },
      {
            title: 'Feedback',
            urlKey: '/feedback',
            icon: feedbackIcon
      },
      {
            title: 'Switch List',
            urlKey: '/switch-list',
            icon: pulseIcon
      },
      {
            title: 'Notifications',
            urlKey: '/notification',
            icon: notificationIcon
      },
      {
            title: 'Waitlist',
            urlKey: '/waitlist',
            icon: watchlistIcon
      },
];

export {
      sideMenuConfig,
      type ISideNavSubMenu
}  