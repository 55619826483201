import axios from 'axios'
import { DELETE_STOCK, EDIT_STOCK, GET_STOCK } from '../constant/common';


export const FetchStockList = async () => {
    try {
        return await axios.get(`${GET_STOCK}`);
    }
    catch (error) {
        throw error;
    }
}

export const DeleteStock = async(id:string) =>{
    try {
        return await axios.get(`${DELETE_STOCK}/${id}`);
    }
    catch (error) {
        throw error;
    }
}

export const EditStock = async (formData:any,id:any) => {
    console.log("id",id);
    
    try {
        return await axios.put(`${EDIT_STOCK}/${id}`, formData ,
       { headers: { 'content-type': 'multipart/form-data' }}
        );
    }
    catch (error) {
        throw error;
    }
}
