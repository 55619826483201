import { Box, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { randomId } from '@mui/x-data-grid-generator';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';
import { ConfirmDelete } from '../../components/dataGridTable/components/ConfirmDelete';


const styles = {
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: '#ffffff'
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
  },
  tableGrid: {
    display: 'flex',
    padding: '50px 0px',
    justifyContent: 'center'
  }
}


const initialRows: GridRowsProp = [
  { id: randomId(), Watchlist: 'Snow', limit: '25' },
  { id: randomId(), Watchlist: 'Lannister', limit: '25' },
  { id: randomId(), Watchlist: 'Lannister', limit: '25' },
  { id: randomId(), Watchlist: 'Stark', limit: '25' },
  { id: randomId(), Watchlist: 'Targaryen', limit: '25' },
  { id: randomId(), Watchlist: 'Melisandre', limit: '25' },
  { id: randomId(), Watchlist: 'Clifford', limit: '25' },
  { id: randomId(), Watchlist: 'Frances', limit: '25' },
  { id: randomId(), Watchlist: 'Roxie', limit: '25' },
];


export const Uselimit = () => {
  const [rowsData, setRowsData] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [open,setOpen] = useState(false);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpen(true);
    // setRowsData(rowsData.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rowsData.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRowsData(rowsData.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsData(rowsData.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'Id', width: 100, editable: true },
    {
      field: 'Watchlist',
      headerName: 'Watchlist Name',
      width: 250,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'limit',
      headerName: 'limit',
      width: 250,
      editable: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleData = (data: any) => {
    setRowsData(data);
  }

  const handleCloseDelete = () =>{
    setOpen(false);
  }

  return (
    <Box display={'flex'} flexDirection={'column'} padding={'24px'}>
      <Box sx={styles.pageHeader}>
        <Typography variant='h3'>
          Use Limit
        </Typography>
      </Box>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} />
        <Box sx={styles.tableGrid}>
          <DataGridTable
            rowsData={rowsData}
            columns={columns}
            setRows={setRowsData}
            setRowModesModel={setRowModesModel}
          />
        </Box>
      </Box>
      {open && 
        <ConfirmDelete open={open} handleCloseDelete={handleCloseDelete} />
        }
    </Box>
  )
}
