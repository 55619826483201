import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "../../../assets/images/searchIcon.png";
import { style } from "../../../util/themes/styles";

const styles = {
    searchBox: {
        display: 'flex',
        '& .MuiTextField-root': {
            width: '25%',
            borderRadius: '8px',
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            "&.Mui-focused fieldset": {
                borderColor: '#fff',
                color: '#fff',
            },
            '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: '#fff',
            },
            [style.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        '&:focused': {
            '& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: '#fff',
            },
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#fff',
        },
        '& .css-19nmkvd-MuiInputBase-root-MuiOutlinedInput-root': {
            borderRadius: '10px',
        },
        '& .css-1bkgzmh-MuiInputBase-input-MuiOutlinedInput-input': {
            // border: "1px solid",
            borderRadius: '10px',
        },
        '& .css-5yre9j-MuiFormLabel-root-MuiInputLabel-root': {
            color: '#fff',
        },
        '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root': {
            color: '#fff',
            backgroundColor: 'inherit'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 200,
            "&.Mui-focused fieldset": {
                borderColor: '#fff',
                color: '#fff',
            }
        },
        'img': {
            marginRight: '5px',
            marginBottom: '4px',
        },
        '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
            color: '#fff',
        },
        '& .css-1wvzkdi.Mui-focused': {
            color: '#fff',
        },
        [style.breakpoints.down('md')]: {
            width: '100%',
            '& .css-14f9imj-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '15px'
            },
            '& .css-1bkgzmh-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '10px 8px'
            }
        }
    }
}

interface IDataGridSearch {
    rowsData?: any;
    handleData?: any;
    handleIsEmpty?: any;
}

export const DataGridSearch = (prop: IDataGridSearch) => {
    const { rowsData, handleData, handleIsEmpty } = prop;
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState(rowsData);

    const handleSearch = (event: any) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchText(searchTerm);
        if (searchTerm !== "") {
            handleData(rowsData, searchTerm);
        } else {
            handleIsEmpty();
        }
    };

    useEffect(() => {
        setData(rowsData);
    }, []);

    return (
        <Box sx={styles.searchBox}>
            <TextField
                label={<Box display={"flex"}>
                    <img src={SearchIcon} alt="Icon" />
                    Search
                </Box>}
                variant="outlined"
                value={searchText}
                onChange={handleSearch}
                fullWidth
                margin="normal"
            />
        </Box>
    )
}