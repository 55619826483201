import { Box, Button, Input, Typography } from "@mui/material";
import { style } from "../../../util/themes/styles";
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import { CreateCategory } from "../../../api/discover";
import { useNotification } from "../../../util/NotificationContext";
import { IToastTypes } from "../../../components/toast/Toast";
import useDeviceType from "../../../util/useDeviceType";

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '90%',
        overflow: 'scroll',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        },
        [style.breakpoints.down('md')]:{
            fontSize:'12px'
        }
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '104px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IAddNewOrder {
    buttonText?: string;
    modalHeading?: string;
    handleClose?: any;
    listName?: any;
}

export const AddCategory = (props: IAddNewOrder) => {
    const { buttonText, modalHeading, handleClose, listName } = props;
    const [dataArray, setDataArray] = useState<any>([]);
    const { setNotify } = useNotification();
    const {isMobile} = useDeviceType();

    const handleSubmit = async () => {
        await CreateCategory(dataArray, listName._id)
            .then((res) => {
                if (res.status === 200) {
                    console.log("res",res?.data?.data);
                    handleClose(res?.data?.data)
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })
    }

    const handleAddInput = () => {
        setDataArray([...dataArray, { name: '' }]);
    }

    const handleInputChange = (index: any, fieldName: any, value: any) => {
        const updatedDataArray = [...dataArray];
        updatedDataArray[index][fieldName] = value;
        setDataArray(updatedDataArray);
    };

    const handleCancelLastItem = () => {
        const updatedDataArray = [...dataArray];
        updatedDataArray.pop();
        setDataArray(updatedDataArray);
    };

    return (
        <Box height={'100%'} width={isMobile?'300px':'500px'}>
            <Box sx={styles.loginForm}>
                Category Name
                {dataArray.map((data: any, index: any) => (
                    <div className="formik-field_wrap row auth_form pb-3">
                        <div className="formik-field-left col col-12 auth_field">
                            <Input
                                style={{ padding: "10px 15px", width: "100%" }}
                                id="category"
                                value={data.name || ''}
                                onChange={(e: any) => handleInputChange(index, 'name', e.target.value)}
                                className="form-control category"

                                endAdornment={(
                                    index === dataArray.length - 1 && (
                                        <Button onClick={handleCancelLastItem} size="small">
                                            <CancelIcon />
                                        </Button>
                                    )
                                )}
                            />
                        </div>
                    </div>
                ))}
                <p style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    fontSize: '14px',
                    color: '#927EE2',
                    marginTop: '4px',
                }} onClick={handleAddInput}>+ Add more</p>
                <div className="form-group mt-3" style={styles.formGroup}>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleSubmit}
                        sx={styles.addRecordButton}>
                        <Typography>{buttonText}</Typography>
                    </Button>
                </div>

            </Box>
        </Box>
    )
}