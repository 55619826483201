import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { style } from "../../../util/themes/styles";

interface IConfirmDelete {
    open: boolean;
    handleCloseDelete?: any;
    handleDelete?:any;
}
export const ConfirmDelete = (props: IConfirmDelete) => {
    const { open, handleCloseDelete, handleDelete } = props;
    return (
        <Dialog
            open={open}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .css-1r3er46-MuiPaper-root-MuiDialog-paper':{
                    backgroundColor:'#271A59'
                },
                '& .css-1loq536-MuiTypography-root-MuiDialogContentText-root':{
                    fontSize:'18px',
                    fontWeight:400,
                    color:style.palette.text.primary,
                },
                '& .css-mtbob2-MuiButtonBase-root-MuiButton-root':{
                    textTransform:'capitalize',
                    fontWeight:300,
                    color:style.palette.text.primary
                }
            }}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this record?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDelete}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}