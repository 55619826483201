import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "../assets/images/searchIcon.png";
import { style } from "../util/themes/styles";
import { Form, Formik } from "formik";
import { TextSelect } from "./textSelect/TextSelect";
import useDeviceType from "../util/useDeviceType";

const styles = {
    formGroup: {
        display: 'flex',
        justifyContent: 'center'
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '400px',
        height: '55px',
        [style.breakpoints.down('md')]:{
            width: '80%',
            margin:'0px',
            marginBottom:'8px',
        },
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
    searchBox: {
        display: 'flex',
        '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '11px 14px',
        },
        '& .MuiTextField-root': {
            width: '100%',
            borderRadius: '8px',
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            "&.Mui-focused fieldset": {
                borderColor: '#fff',
                color: '#fff',
            },
            '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: '#fff',
            },
            [style.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        '&:focused': {
            '& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
                color: '#fff',
            },
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#fff',
        },
        '& .css-19nmkvd-MuiInputBase-root-MuiOutlinedInput-root': {
            borderRadius: '10px',
        },
        '& .css-1bkgzmh-MuiInputBase-input-MuiOutlinedInput-input': {
            // border: "1px solid",
            borderRadius: '10px',
        },
        '& .css-5yre9j-MuiFormLabel-root-MuiInputLabel-root': {
            color: '#fff',
        },
        '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root': {
            color: '#fff',
            backgroundColor: 'inherit'
        },
        "& .MuiOutlinedInput-root": {
            fontWeight: 200,
            "&.Mui-focused fieldset": {
                borderColor: '#fff',
                color: '#fff',
            }
        },
        'img': {
            marginRight: '5px',
            marginBottom: '4px',
        },
        '& .css-ppzgdl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
            color: '#fff',
        },
        '& .css-1wvzkdi.Mui-focused': {
            color: '#fff',
        },
        [style.breakpoints.down('md')]: {
            width: '100%',
            '& .css-14f9imj-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '15px'
            },
            '& .css-1bkgzmh-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '10px 8px'
            }
        }
    }
}

interface IDataGridSearch {
    rowsData?: any;
    handleValues?: any;
    handleIsEmpty?: any;
}

const investmentGoalsOptions = [
    "Short-term",
    "Long-term",
    "Retirement",
    "Capital"
]

const riskToleranceOptions = [
    "High Risk",
    "Moderate risk",
    "Low risk",
    "Very low risk"
]

const tradingFrequencyOptions = [
    "Day trading",
    "Long-term investing",
    "Swing trading",
    "Position trading"
]

const interestedAssetsOptions = ['Stock', 'Bonds', 'Cryptocurrencies', 'ETFs'];
const researchAndAnalysisOptions = ['Technical analysis', 'Fundamental analysis', 'Sentiment analysis', 'Social trading'];
export const MultiSearchField = (prop: IDataGridSearch) => {
    const { rowsData, handleValues, handleIsEmpty } = prop;
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState(rowsData);
    const { isMobile } = useDeviceType();



    useEffect(() => {
        setData(rowsData);
    }, []);

    const handleChangeInvestmentGoal = (event: any, setFieldValue: any) => {
        setFieldValue("investment_goals", event.target.value)
    }

    const handleChangeRiskTolerance = (event: any, setFieldValue: any) => {
        setFieldValue("risk_tolerance", event.target.value)
    }

    const handleChangeTradingFrequency = (event: any, setFieldValue: any) => {
        setFieldValue("trading_frequency", event.target.value)
    }

    const handleChangeInterestedAssets = (event: any, setFieldValue: any) => {
        setFieldValue("interested_assets", event.target.value)
    }

    const handleChangeResearchAndAnalysis = (event: any, setFieldValue: any) => {
        setFieldValue("research_and_analysis", event.target.value)
    }


    const validateForm = () => { }

    const handleFormAction = (values: any) => {
        console.log("search Values==>", values);
        handleValues(values)
    }

    const handleCancelSearch = (resetForm:any) =>{
        resetForm();
        handleIsEmpty();
    }

    return (
        <Box sx={styles.searchBox}>
            <Formik
                initialValues={{
                    investment_goals: "",
                    risk_tolerance: "",
                    trading_frequency: '',
                    interested_assets: '',
                    research_and_analysis: '',
                }}
                validate={validateForm}
                onSubmit={handleFormAction}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                }) => {
                    return (
                        <Form
                            name="loginForm"
                            className="loginForm"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={2} sm={6}>
                                    <TextSelect
                                        id="investment_goals"
                                        value={values.investment_goals}
                                        label="investment goal"
                                        onChange={(event: any) => handleChangeInvestmentGoal(event, setFieldValue)}
                                        options={investmentGoalsOptions}
                                        style={{ width: isMobile ? '100%' : "350px", minWidth: isMobile? '100px':'150px' }}
                                        inputStyleProps={{ padding: isMobile ? '0px' : '15px', width: isMobile ? '100%' : '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2} sm={6}>
                                    <TextSelect
                                        id="risk_tolerance"
                                        value={values.risk_tolerance}
                                        label="Risk Tolerance"
                                        onChange={(event: any) => handleChangeRiskTolerance(event, setFieldValue)}
                                        options={riskToleranceOptions}
                                        style={{ width: isMobile ? '100%' : "350px", minWidth: isMobile? '':'150px' }}
                                        inputStyleProps={{ padding: isMobile ? '0px' : '15px', width: isMobile ? '100%' : '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2} sm={6}>
                                    <TextSelect
                                        id="interested_assets"
                                        value={values.interested_assets}
                                        label="Interested Assets"
                                        onChange={(event: any) => handleChangeInterestedAssets(event, setFieldValue)}
                                        options={interestedAssetsOptions}
                                        style={{ width: isMobile ? '100%' : "350px", minWidth: isMobile? '':'150px' }}
                                        inputStyleProps={{ padding: isMobile ? '0px' : '15px', width: isMobile ? '100%' : '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2} sm={6}>
                                    <TextSelect
                                        id="trading_frequency"
                                        value={values.trading_frequency}
                                        label="Trading Frequency"
                                        onChange={(event: any) => handleChangeTradingFrequency(event, setFieldValue)}
                                        options={tradingFrequencyOptions}
                                        style={{ width: isMobile ? '100%' : "350px", minWidth: isMobile? '':'150px' }}
                                        inputStyleProps={{ padding: isMobile ? '0px' : '15px', width: isMobile ? '100%' : '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2} sm={6}>
                                    <TextSelect
                                        id="research_and_analysis"
                                        value={values.research_and_analysis}
                                        label="Research and Analysis"
                                        onChange={(event: any) => handleChangeResearchAndAnalysis(event, setFieldValue)}
                                        options={researchAndAnalysisOptions}
                                        style={{ width: isMobile ? '100%' : "350px", minWidth: isMobile? '':'150px' }}
                                        inputStyleProps={{ padding: isMobile ? '0px' : '15px', width: isMobile ? '100%' : '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        sx={styles.addRecordButton}>
                                        <Typography>Search</Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} lg={3} sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        onClick={() =>handleCancelSearch(resetForm)}
                                        sx={styles.addRecordButton}>
                                        <Typography>Cancel</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>

        </Box>
    )
}