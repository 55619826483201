import { Box, Button, Modal, Stack } from "@mui/material";
import clearIcon from '../../assets/images/ClearIcon.png';
import useDeviceType from "../../util/useDeviceType";

interface IUpdateModal {
    isOpenModal: boolean,
    handleClose: any,
    children: any;
    backdrop?: boolean;
}

const styles = {
    ModalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBox-root': {
            padding: '25px',
            borderRadius: '8px',
            background: " linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)",
        },
        '& .MuiPaper-root': {
            backgroundColor: '#271A59'
        },
        '& .MuiPaper-root .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            backgroundColor: '#271A59'
        }
    },
    paper: {
        // position: 'absolute',
        width: 400,
        // backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    closeButton: {
        position: 'absolute',
        // top: theme.spacing(1),
        // right: theme.spacing(1),
    },
}

export const UpdateModal = (props: IUpdateModal) => {
    const { isOpenModal, handleClose, children, backdrop } = props;
    const { isMobile } = useDeviceType();
    return (
        <Box sx={styles.ModalStyle} className='modal'>
            <Modal
                open={isOpenModal}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                hideBackdrop={isMobile ? true : false}
                sx={styles.ModalStyle}
            >
                <Stack alignSelf={"center"}>
                    {isMobile ?
                        <Stack sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            height: '80%'
                        }}>

                            {children}
                            <Button sx={{ marginLeft: '-35px', marginTop: '-15px' }} onClick={handleClose}><img src={clearIcon} alt='Close Icon' height={'30px'} width={'30px'} /></Button>
                        </Stack> :
                        <>
                            {children}
                        </>
                    }
                </Stack>
            </Modal>
        </Box>
    )
}