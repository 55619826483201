import React, { useState } from 'react';

interface ColorPaletteProps {
  colors: string[]; // Array of color hex codes
  onColorSelect: (color: string) => void; // Callback function when a color is selected
  value?:string;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({ colors, onColorSelect,value }) => {
  const [selectedColor, setSelectedColor] = useState<string | null>(value || null);

  const handleColorClick = (color: string) => {
    setSelectedColor(color);
    onColorSelect(color);
  };

  return (
    <div>
      <h2>Choose a Color</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {colors.map((color, index) => {
          return (
            <div
              key={index}
              onClick={() => handleColorClick(color)}
              style={{
                background:`${color}`,
                width: '50px',
                height: '50px',
                margin: '5px',
                cursor: 'pointer',
                border: selectedColor === color ? '2px solid black' : 'none',
              }}
            ></div>
          )
        }


        )}
      </div>
    </div>
  );
};

export default ColorPalette;
