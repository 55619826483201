import axios from 'axios'
import { ADD_USER, APPROVE_WAITLIST, DELETE_USER, EDIT_USER, GET_USER, GET_WAITLIST_USERS } from '../constant/common';

export const FetchApprovedUsers = async () => {
    try {
        return await axios.get(`${GET_USER}`,
        );
    }
    catch (error) {
        throw error;
    }
}

export const FetchWaitlistUsers = async () => {
    try {
        return await axios.get(`${GET_WAITLIST_USERS}`);
    }
    catch (error) {
        throw error;
    }
}

export const DeleteUser = async (id: string) => {
    try {
        return await axios.get(`${DELETE_USER}/${id}`);
    }
    catch (error) {
        throw error;
    }
}

export const EditUser = async (value: any, id?: string) => {
    console.log(`${EDIT_USER}/${value?.id}`, "url", value);
    if (id) {
        try {
            return await axios.put(`${EDIT_USER}/${id}`, {
                firstName: value?.firstName,
                lastName: value?.lastName,
                email: value?.email,
                phoneNumber: value?.mobile,
                password: value?.password,
                reward: value?.addMoney,
            });
        }
        catch (error) {
            throw error;
        }
    } else {
        try {
            return await axios.post(`${ADD_USER}`, {
                firstName: value?.firstName,
                lastName: value?.lastName,
                email: value?.email,
                phoneNumber: value?.mobile,
                password: value?.password,
                reward: value?.reward,
            });
        }
        catch (error) {
            throw error;
        }
    }
}

export const AddUser = async (value: any) => {
    try {
        return await axios.post(`${ADD_USER}`, {
            firstName: value?.firstName,
            lastName: value?.lastName,
            email: value?.email,
            phoneNumber: value?.mobile,
            password: value?.password,
            reward: value?.reward,
        });
    }
    catch (error) {
        throw error;
    }
}

export const getApproved = async (value: any) => {
    try {
        return await axios.post(`${APPROVE_WAITLIST}`, {
            users: value,
        });
    }
    catch (error) {
        throw error;
    }
}