import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material"
import {
    GridColDef,
    GridRowModel,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer,
    DataGrid,
    GridFooterContainer
} from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import VerifiedIcon from '@mui/icons-material/Verified';
import { style } from "../../util/themes/styles";
import { TextSelect } from "../textSelect/TextSelect";
import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import useDeviceType from "../../util/useDeviceType";
import { getApproved } from "../../api/users";
import { useNotification } from "../../util/NotificationContext";
import { IToastTypes } from "../toast/Toast";
import DataNotFoundImage from '../../assets/images/no-data-found-img.png';

type IDataGridTable = {
    rowsData: Readonly<GridRowModel<any>[]>;
    columns: Readonly<GridColDef<any>[]>;
    loading?: boolean;
    setRows?: any;
    setRowModesModel?: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    addRecord?: boolean;
    handleOnCellClick?: any;
    setOpenModal?: any;
    setModalHeading?: any;
    setButtonText?: any;
    approve?: boolean;
    handleApproved?: any;
    addStock?: boolean;
    setOpenAddStockModal?: any;
    addContentList?: boolean;
    handleAddRecord?:any;
};

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

const styles = {
    paperGrid: {
        width: '100%',
        background: ' linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)',
        '& .MuiDataGrid-columnHeaderTitle': {
            ...style.palette.typography.headline,
            // color: style.palette.text.primary
        },
        '& ..css-1dfpn3g-MuiPaper-root-MuiDataGrid-paper': {
            backgroundColor: '#271A59'
        },
        '& .MuiDataGrid-root': {
            borderColor: 'none'
        },
        '& .css-11t6u1k-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon': {
            display: 'none'
        },
        '& .MuiDataGrid-cellContent': {
            fontWeight: 300,
            color: style.palette.text.primary
        },
        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            backgroundColor: 'transparent',
        },
        '& .css-lixmsw-MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            backgroundColor: 'transparent',
        },
        '& .MuiTablePagination-displayedRows': {
            color: style.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '& .css-5yiyuy-MuiPaper-root .MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'Roboto'
        },
        '& .css-1crpkjc-MuiInputBase-root-MuiOutlinedInput-root': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
        },
        '& .css-lixmsw-MuiDataGrid-root .MuiDataGrid-menuIcon': {
            display: 'none'
        },
        '& .css-lixmsw-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer': {
            display: 'none',
            visibility: 'hidden'
        },
        '& .css-lixmsw-MuiDataGrid-root .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer': {
            display: 'none',
            visibility: 'hidden'
        },
        '& .css-8oyb1z .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
            display: 'none',
            visibility: 'hidden'
        },
        '& .css-f7djji': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
        },
        '& .css-tvpg66.Mui-focused': {
            color: style.palette.text.primary
        },
        [style.breakpoints.down('md')]: {
            '& .css-lixmsw-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
                fontSize: '12px'
            },
            '& MuiDataGrid-iconButtonContainer css-ltf0zy-MuiDataGrid-iconButtonContainer': {
                display: 'none'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px'
            },
            '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '10px 14px'
            },
            '& .css-14f9imj-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '12px'
            },
            '& .css-edun3l .MuiOutlinedInput-root': {
                marginTop: '8px'
            }
        }
    },
    addRecordButton: {
        backgroundColor: style.palette.primary[600],
        padding: '10px',
        margin: '10px',
        width: '200px',
        height: '52px',
        '& .MuiTypography-root': {
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        },
        [style.breakpoints.down('md')]: {
            width: '90%',
            height: '36px',
            '& .MuiTypography-root': {
                fontSize: '12px'
            },
            '& .MuiSvgIcon-root': {
                fontSize: '12px'
            },

        }
    },
    addRecordText: {
        fontWeight: 400,
        ...style.palette.typography.h5.medium,
        color: style.palette.text.primary
    },
    alignEmptyState: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        img: {
            height: '500px',
            [style.breakpoints.down('md')]: {
                height: '100%',
            }
        }
    }
}

export const DataGridTable = (props: IDataGridTable) => {
    const { rowsData, columns, setRows, setRowModesModel, setOpenAddStockModal, addRecord, approve, handleOnCellClick, setOpenModal, setModalHeading, setButtonText, loading, handleApproved, addStock, addContentList,handleAddRecord } = props;
    const [noOfUsers, setNoOfUsers] = useState();
    const { isMobile } = useDeviceType();
    const [usersToApprove, setUsersToApprove] = useState<any>([]);
    const { setNotify } = useNotification();

    const handleApprove = async () => {
        await getApproved(usersToApprove)
            .then((res) => {
                if (res.status === 200) {
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                    handleApproved();
                }
            })

    }

    const getUsersToApprove = async (number: any) => {
        const result = rowsData.slice(0, number).map((users: any) => {
            return users?._id
        })
        setUsersToApprove(result)
    }

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            setOpenModal(true);
            setModalHeading('Add New');
            setButtonText('Save')
        };
        const number = [
            { id: 0, _id: 1 },
            { id: 1, _id: 10 },
            { id: 2, _id: 50 },
            { id: 3, _id: 100 },
            { id: 4, _id: 200 },
            { id: 5, _id: 500 },
            { id: 6, _id: 1000 },
            { id: 7, _id: 2000 },
            { id: 8, _id: 5000 },
            { id: 9, _id: 10000 },
            { id: 10, _id: 15000 },
            { id: 11, _id: 20000 },
            { id: 12, _id: 25000 },
            { id: 13, _id: 30000 },
            { id: 14, _id: 35000 },
            { id: 15, _id: 40000 },
            { id: 16, _id: 45000 },
            { id: 17, _id: 50000 },
        ];

        const handleChange = (e: any) => {
            setNoOfUsers(e?.target?.value)
            getUsersToApprove(e?.target?.value);
        }

        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {addRecord &&
                    <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} sx={styles.addRecordButton}>
                        <Typography sx={styles.addRecordText}>Add record</Typography>
                    </Button>
                }
                {approve &&
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'flex-start' : 'flex-end', width: '100%' }}>
                        <TextSelect
                            id="categories"
                            label={"Select number"}
                            value={noOfUsers}
                            onChange={(e: number) => handleChange(e)}
                            options={number}
                            style={{ width: isMobile ? "90%" : "150px", margin: "10px" }}
                            inputStyleProps={{ padding: '0px 15px' }}
                        />
                        <Button color="primary" startIcon={<VerifiedIcon />} onClick={handleApprove} sx={styles.addRecordButton}>
                            <Typography sx={styles.addRecordText}>Approve</Typography>
                        </Button>
                    </Box>
                }
            </GridToolbarContainer >
        );
    }

    const handleAddStock = () => {
        setOpenAddStockModal(true)

    }

    useEffect(() => {
        getUsersToApprove(noOfUsers);
    }, []);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Paper sx={styles.paperGrid}>
                {loading ?
                    <Box sx={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                    : rowsData.length <= 0 ? <>
                        <Box sx={styles.alignEmptyState}>
                            <img src={DataNotFoundImage} alt="Data Not Found" height={'300px'} />
                            <Typography variant="caption">Data not found</Typography>
                        </Box>
                    </> :
                        <DataGrid
                            rows={rowsData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            onCellClick={handleOnCellClick}
                            slots={{
                                toolbar: addRecord || approve || addStock ? EditToolbar : null,

                            }}
                            slotProps={{
                                toolbar: { setRows, setRowModesModel },
                            }}
                        />
                }
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {addStock &&
                        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddStock} sx={styles.addRecordButton}>
                            <Typography sx={styles.addRecordText}>Add Stock</Typography>
                        </Button>
                    }
                    {addContentList &&
                        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRecord} sx={styles.addRecordButton}>
                            <Typography sx={styles.addRecordText}>Add Record</Typography>
                        </Button>
                    }
                </Box>
            </Paper>
        </div>
    )
}