import { Box, Typography } from '@mui/material';
import {
  GridRowModesModel,
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { style } from '../../util/themes/styles';
import useDeviceType from '../../util/useDeviceType';
import { FetchWaitlistUsers, getApproved } from '../../api/users';
import moment from 'moment';
import HeaderComponent from '../../components/HeaderComponent';
import VerifiedIcon from '@mui/icons-material/Verified';
import { getUserKYC } from '../../api/config';
import { MultiSearchField } from '../../components/MultiSearchField';
import { useNotification } from '../../util/NotificationContext';
import { IToastTypes } from '../../components/toast/Toast';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px ',
    [style.breakpoints.down('md')]: {
      padding: '4px ',
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
    [style.breakpoints.down('md')]:{
      padding: '2px  13px',
      margin: '0px',
    }
  },
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: '#ffffff'
    },
    [style.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 500,
      }
    }
  }
}


export const Waitlist = ({ handleDrawer }: any) => {
  const [rowsData, setRowsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [kycData ,setKycData] = useState([]);
  const { isMobile } = useDeviceType();
  const { setNotify } = useNotification();
  const [usersToApprove,setUsersToApprove] = useState([]);
console.log("kycData",kycData);

  const handleDetails = (data: any) => {
    console.log("Data==>", data);
    const value = [];
    value.push(data?._id);
    console.log("value==>", value);
    handleApprove(value)
  }

  const getActions = (params: any) => {

    return [
      <GridActionsCellItem
        icon={<VerifiedIcon />}
        label="Verifiy"
        onClick={()=>handleDetails(params.row)}
        color="inherit"
      />,
    ];
  }

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width: isMobile ? 50 : 50,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const uniqueIdColumn: GridColDef = {
    field: "waitList_id",
    headerName: "Unique ID",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const firstNameColumn: GridColDef = {
    field: "firstName",
    headerName: "First Name",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const lastNameColumn: GridColDef = {
    field: "lastName",
    headerName: "Last Name",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const mobileNoColumn: GridColDef = {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const emailColumn: GridColDef = {
    field: "email",
    headerName: "Email",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const investment_goalsColumn: GridColDef = {
    field: "investment_goals",
    headerName: "Investment Goals",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const risk_toleranceColumn: GridColDef = {
    field: "risk_tolerance",
    headerName: "Risk Tolerance",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const trading_frequencyColumn: GridColDef = {
    field: "trading_frequency",
    headerName: "Trading Frequency",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const research_and_analysisColumn: GridColDef = {
    field: "research_and_analysis",
    headerName: "Research and Analysis",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const interested_assetsColumn: GridColDef = {
    field: "interested_assets",
    headerName: "Interested Assets",
    width: isMobile ? 90 : 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };


  const createdAtColumn: GridColDef = {
    field: "createdAt",
    headerName: "Created At",
    width: 250,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const actionColumn: GridColDef = {
    field: "action",
    headerName: "Actions",
    flex: 1,
    minWidth: 100,
    width: 300,
    maxWidth: 350,
    renderCell: getActions,
    resizable: false,
  };


  const columns: GridColDef[] = [];
  columns.push(idColumn);
  columns.push(uniqueIdColumn);
  columns.push(firstNameColumn);
  columns.push(lastNameColumn);
  columns.push(mobileNoColumn);
  columns.push(emailColumn);
  columns.push(investment_goalsColumn);
  columns.push(risk_toleranceColumn);
  columns.push(interested_assetsColumn);
  columns.push(trading_frequencyColumn);
  columns.push(research_and_analysisColumn);
  columns.push(createdAtColumn);
  columns.push(actionColumn);


  const getUsers = async () => {
    await FetchWaitlistUsers()
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          let ques: any[];
          setRowsData(
            data.map((user: any, index: any) => {
              const KYCData = user?.kycData;
              KYCData?.map((que: any) => {
                ques = que?.questions.map((item: any, indx: any) => {
                  return ({
                    question: item?.questionId === que.questionData[indx]._id && que.questionData[indx]?.question,
                    answer: item?.answer[0]
                  })
                })
              })

              console.log("KYCData",KYCData);
              console.log("ques",ques);
              
              

              return ({
                id: index + 1,
                _id: user._id,
                waitList_id: user?.waitList_id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                phoneNumber: user?.phoneNumber,
                email: user?.email,
                investment_goals: ques?.[0]?.answer !== undefined ? ques[0]?.answer : "",
                risk_tolerance: ques?.[1]?.answer !== undefined ? ques[1]?.answer : "",
                interested_assets: ques?.[2]?.answer !== undefined ? ques[2]?.answer : "",
                trading_frequency: ques?.[3]?.answer !== undefined ? ques[3]?.answer : "",
                research_and_analysis: ques?.[4]?.answer !== undefined ? ques[4]?.answer : "",
                createdAt: moment(user?.createdAt).format('lll')
              })
            })
          );

        }
      })
  }

  const handleData = (data: any, searchTerm: any) => {
    const filteredRows = data.filter((row: any) => {
      return row?.waitList_id?.toLowerCase()?.includes(searchTerm) || row?.waitList_id?.toLowerCase()?.startsWith(searchTerm);
    });
    setRowsData(filteredRows)
  }

  const getKYCData = async () => {
    await getUserKYC()
      .then((res) => {
        if (res.status == 200) {
          console.log("res==>", res?.data);
          const data =  res?.data?.data
          // data?.map((que: any) => {
          //   setKycData( que?.questions.map((item: any, indx: any) => {
          //     return ({
          //       question: item?.questionId === que.questionData[indx]._id && que.questionData[indx]?.question,
          //       answer: item?.answer[0]
          //     })
          //   }))
          // })
        }
      })
  }

  const handleApproved = () => {
    getUsers();
  }

  const handleIsEmpty = () => {
    getUsers();
  }


  const handleValues = (values: any) => {
    console.log("rowsData",rowsData);
    
    const filteredRows = rowsData.filter((row: any) => {
      console.log("row?.investment_goals",row?.investment_goals);
      
      return row?.investment_goals?.toLowerCase()?.includes(values?.investment_goals.toLowerCase()) 
      && row?.risk_tolerance?.toLowerCase()?.includes(values.risk_tolerance.toLowerCase())
      && row?.trading_frequency?.toLowerCase()?.includes(values.trading_frequency.toLowerCase())
      && row?.interested_assets?.toLowerCase()?.includes(values.interested_assets.toLowerCase())
      && row?.research_and_analysis?.toLowerCase()?.includes(values.research_and_analysis.toLowerCase())
    });
    console.log("filteredRows", filteredRows);

    setRowsData(filteredRows)
  }

  const handleApprove = async (value:any) => {
    await getApproved(value)
        .then((res) => {
            if (res.status === 200) {
                setNotify({
                    open: true,
                    type: IToastTypes.Success,
                    message: res?.data?.message,
                });
                handleApproved();
            }
        })

}


  useEffect(() => {
    getUsers();
    getKYCData();
  }, [])


  return (
    <Box sx={styles.root}>
      <HeaderComponent handleDrawer={handleDrawer} heading={'Waitlist'} />
      <MultiSearchField handleValues={handleValues} handleIsEmpty={handleIsEmpty}/>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} handleIsEmpty={handleIsEmpty} />
      <Box sx={{ display: 'flex', paddingTop: isMobile ? '20px' : '50px', justifyContent: 'center' }}>
        <DataGridTable
          rowsData={rowsData}
          columns={columns}
          setRows={setRowsData}
          setRowModesModel={setRowModesModel}
          approve
          handleApproved={handleApproved}
        />
      </Box>
      </Box>
    </Box>
  )
}
