import { Box, Button, ClickAwayListener, CssBaseline, Drawer, Grid, List, ListItemButton, ListItemText, Popper, Typography } from "@mui/material"
import { sideMenuConfig } from "../../util/sidemenuconfig"
import { Children, useEffect, useState } from "react";
import { style } from "../../util/themes/styles";
import NavBarItem from "./components/NavBarItem";
import logo from '../../assets/images/sanaLogoIcon.png';
import { useAuth } from "../../util/AuthContext";
import LogoutIcon from '@mui/icons-material/Logout';
import useDeviceType from "../../util/useDeviceType";

const drawerWidth = 100;

const styles = {
  root: {
    height: '100vh',
    display: 'flex',
    [style.breakpoints.down('md')]: {
      display: 'block',
      background: "linear-gradient(180deg, #5F3B67 0%, #5F3B67 100%)",
    }
  },
  main: (isMobile: boolean,drawer:any) => ({
    flexGrow: 1,
    paddingLeft: isMobile && drawer== true ? '80px' : '0px',
    background: "linear-gradient(180deg, #5F3B67 0%, #5F3B67 100%)",
    [style.breakpoints.down('md')]: {
      paddingLeft:isMobile && drawer== true ? '80px' : '0px',
    }
  }),

  selectedDividerStyle: {
    borderRadius: '100px',
    borderColor: '#5B67FF',
    borderWidth: '3px',
  },

  drawer:(drawerOpen:any)=>({
    width:drawerOpen == true ? drawerWidth : 0,
    flexShrink: drawerOpen == true ? 0 : null,
    position:'relative',
    left:drawerOpen == true ? 0 : '-100px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    '& .MuiDrawer-paper': {
      width:drawerOpen == true? drawerWidth : 0,
      left:drawerOpen == true ? 0 : '-100px',
      overflowY: 'visible',
      zIndex: 1,
      background: ' linear-gradient(180deg,#5F3B67 0%, #5F3B67 100%)'
    },
    '& .css-1dck8yh': {
      left: '30px'
    },
    [style.breakpoints.down('md')]: {
      width:drawerOpen == true ? 80 : 0,
      '& .MuiDrawer-paper': {
        width:drawerOpen == true ? 80 : 0,
        overflowY: 'visible',
        zIndex: 1,
        // height: '100vh',
        overflowX: 'scroll',
      }
    }
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '26px 26px 30px 26px'
  },
  subMenuTitleStyle: {
    display: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '27px',
    ml: '8px',
    color: style.palette.text.primary
  },
  profileAvtar: {
    height: '60px',
    width: '60px'
  },
  logout: {
    mb: 6,
    '& .css-v2gau4-MuiButtonBase-root-MuiButton-root': {
      color: '#fff'
    },
    [style.breakpoints.down('md')]: {
      fontSize: '10px',
      '& .MuiTypography-root': {
        fontSize: '10px'
      },
    }

  }

}

interface IDrawerProps {
  children?: React.ReactNode;
  drawer?:any;
}

export default function SideDrawer(props: IDrawerProps) {
  const { children,drawer } = props;
  const { logout } = useAuth();
  const { isMobile } = useDeviceType();
  const [drawerOpen,setDrawerOpen] = useState<any>(false);
  // const drawer =  localStorage.getItem("open");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  console.log("drawer===>",drawer);
  console.log("drawerOpen===>",drawerOpen);
  
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onLogoutClick = () => {
    logout();

  };

  useEffect(()=>{
    // const drawer =  localStorage.getItem("open");
    setDrawerOpen(drawer);
  },[])

  const open = Boolean(anchorEl);
  const popperId = "header_avatar_dropwdown";
  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer
        sx={styles.drawer(drawer)}
        variant="permanent"
      >
        <Grid sx={styles.drawerHeader} >
          <img src={logo} alt="sidenav_logo" height={isMobile ? '30px' : '50px'} width={isMobile ? '30px' : '50px'} />
        </Grid>
        <Grid spacing={6}>
          {Children.toArray(sideMenuConfig.map((item, index) => (<NavBarItem item={item} isFirst={index === 0} />)))}
        </Grid>
        <Grid
          display={"flex"}
          alignItems={"end"}
          flex={1}
          sx={styles.logout}
          justifyContent={"center"}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Button
              aria-describedby={popperId}
              onClick={handleClick}
              sx={{
                minWidth: 0,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <LogoutIcon />
                <Typography variant="caption">logout</Typography>
              </Box>
            </Button>
          </ClickAwayListener>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"right-start"}
            style={{ zIndex: 2 }}
          >
            <Box
              className="popper-dropdown-box"
              sx={{
                borderRadius: "10px",
                boxShadow:
                  "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
                backgroundColor: "#FFF",
                position: "absolute",
                left: "-40px",
                top: "-26px",
                '& .MuiTypography-root ': {
                  color: '#000000',
                }
              }}
            >
              <List component="nav">
                <ListItemButton onClick={onLogoutClick} selected={false}>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </List>
            </Box>
          </Popper>
        </Grid>
      </Drawer>
      <main style={styles.main(isMobile,drawer)}>{children}</main>
    </Box>
  )
}