import { Box, Button, Input, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { style } from "../../../util/themes/styles";
import { TextSelect } from "../../../components/textSelect/TextSelect";
import { EditStock } from "../../../api/stocks";
import { useNotification } from "../../../util/NotificationContext";
import { IToastTypes } from "../../../components/toast/Toast";
import FormData from "form-data";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '90%',
        overflow: 'scroll',
        '& .css-tid9aw-MuiPaper-root-MuiPickersPopper-paper': {
            backgroundColor: '#271A59'
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            padding: '10px 15px',
            border: ' 1px solid rgb(206, 212, 218)'
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: '#fff'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "none",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            },

        },
        '& .MuiPaper-root': {
            backgroundColor: '#271A59'
        },
        '& .MuiOutlinedInput-root .MuiSelect-select': {
            '-webkit-box-shadow': 'none',
            caretColor: 'transparent'
        },
        '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            backgroundColor: '#271A59'
        },
        '& .css-pwi4qy .MuiOutlinedInput-root .MuiSelect-select, .css-pwi4qy .MuiOutlinedInput-root input': {
            '-webkit-box-shadow': 'none',
            caretColor: 'transparent'
        }
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center'
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '90%',
        height: '50px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IAddNewRecord {
    buttonText?: string;
    modalHeading?: string;
    updateUseDetail?: any;
    categories?: any;
    handleClose?: any;
}

interface FormValues {
    stockName?: string;
    srValue?: string;
    status?: string;
    price?: string;
    categories?: string;
    earningType?:string;
    date?:string;
}

interface FormErrors {
    stockName?: string;
    srValue?: string;
    status?: string;
    price?: string;
    categories?: string;
    earningType?:string;
    date?:string;
}

const earningType = [
    'earnings',
    'dividend',
];


export const AddNewRecord = (props: IAddNewRecord) => {
    const { buttonText, modalHeading, updateUseDetail, categories, handleClose } = props;
    const { setNotify } = useNotification();
    // const classes = useStyles();
    const validateForm = (values: FormValues) => {
        const errors: FormErrors = {};

        if (!values.stockName) {
            errors.stockName = "Stock Name is required";
        }else if(!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test((values.stockName))){
            errors.stockName = "Please enter valid name";
        }

        if (!values.srValue) {
            errors.srValue = "SR Value is required";
        }

        if (!values.status) {
            errors.status = "Percentage is required";
        }

        if (!values.price) {
            errors.price = "Price is required";
        }
        if (!values.earningType) {
            errors.earningType = "Earning Type is required";
        }
        if (!values.date) {
            errors.date = "Date is required";
        }

        console.log("Validating errors -- ", errors);

        return errors;
    };

    const handleFormAction = async (values: any) => {
        let formData = new FormData();

        formData.append('typename', 'stock');
        formData.append('name', values.stockName);
        formData.append('sr', values.srValue);
        formData.append('industry', values.categories);
        formData.append('pl_percentage', values.status);
        formData.append('earningType', values.earningType);
        formData.append('date', values.date);
        formData.append('file', values.logo);

        // setOpen(false);
        await EditStock(formData, values?.id)
            .then((res) => {
                if (res.status === 200) {
                    handleClose()
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })

    }

    const handleChangeCategory = (e: any, setFieldValue: any) => {
        setFieldValue("categories", e.target.value)
    }

    const handleChangeEarningType = (e: any, setFieldValue: any) => {
        setFieldValue("earningType", e.target.value)
    }

    const handleChangeDate = (e: any, setFieldValue: any) => {
        setFieldValue("date", e)
    }

    const handleKeyPress = (event: any) => {
        // Check if the pressed key is the minus sign and prevent its input
        if (event.key === '-') {
            event.preventDefault();
        }
    }

    return (
        <Box height={'100%'}>
            <Typography variant="h4" paddingBottom={'18px'}>{modalHeading} Stock</Typography>
            <Box sx={styles.loginForm} className="loginForm-box">
                <Formik
                    initialValues={{
                        id: updateUseDetail ? updateUseDetail?._id : "",
                        stockName: updateUseDetail ? updateUseDetail?.shortName : "",
                        srValue: updateUseDetail ? updateUseDetail?.sr : "",
                        status: updateUseDetail ? updateUseDetail?.pl_percentage : "",
                        price: updateUseDetail ? updateUseDetail?.current_price : "",
                        categories: updateUseDetail ? updateUseDetail?.industry : "",
                        earningType: updateUseDetail ? updateUseDetail?.earningType : "",
                        date: updateUseDetail ? updateUseDetail?.date : "",
                        logo: updateUseDetail ? updateUseDetail?.image : "",
                    }}
                    validate={validateForm}
                    onSubmit={handleFormAction}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => {
                        return (
                            <Form
                                name="loginForm"
                                className="loginForm"
                            >
                                <div className="formik-field_wrap row auth_form pb-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Stock Name
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            id="stockName"
                                            value={values.stockName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control stockName"
                                            autoComplete="off"
                                        />
                                        {errors.stockName && typeof errors.stockName === 'string' && touched.stockName ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors?.stockName}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form">
                                    <div className="formik-field-left col col-12 auth_field">
                                        SR Value
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="text"
                                            id="srValue"
                                            value={values.srValue}
                                            inputProps={{
                                                onKeyPress: handleKeyPress,
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control srValue"
                                            autoComplete="off"
                                        />
                                        {errors.srValue && typeof errors.srValue === 'string' && touched.srValue ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors?.srValue}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Pl Percentage
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="number"
                                            id="status"
                                            inputProps={{
                                                onKeyPress: handleKeyPress,
                                            }}
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control status"
                                            autoComplete="off"
                                        />
                                        {errors.status && typeof errors.status === 'string' && touched.status ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.status}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Categories
                                        <TextSelect
                                            id="categories"
                                            value={values.categories}
                                            onChange={(e: any) => handleChangeCategory(e, setFieldValue)}
                                            options={categories}
                                            style={{ width: "100%" }}
                                            inputStyleProps={{ padding: '10px 15px', border: "1px solid #ced4da" }}
                                        />
                                        {errors.categories && typeof errors.categories === 'string' && touched.categories ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.categories}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Earning Type
                                        <TextSelect
                                            id="earningType"
                                            value={values.earningType}
                                            onChange={(e: any) => handleChangeEarningType(e, setFieldValue)}
                                            options={earningType}
                                            style={{ width: "100%" }}
                                            inputStyleProps={{ padding: '10px 15px', border: "1px solid #ced4da" }}
                                        />
                                        {errors.earningType && typeof errors.earningType === 'string' && touched.earningType ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.earningType}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field d-flex flex-column">
                                        Date
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className='datepicker'
                                                value={dayjs(values.date)}
                                                onChange={(e: any) => handleChangeDate(e, setFieldValue)}
                                            />
                                        </LocalizationProvider>
                                        {errors.date && typeof errors.date === 'string' && touched.date ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.date}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group mt-3" style={styles.formGroup}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        sx={styles.addRecordButton}>
                                        <Typography>{buttonText}</Typography>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    )
}