import { Box, Button, Input, Stack, Typography } from '@mui/material'
import React from 'react'
import { style } from '../../../util/themes/styles'
import useDeviceType from '../../../util/useDeviceType';

const styles = {
    root: {
        '& .MuiInputBase-root': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]: {
                minWidth: '100%',
            }
        },
        '& textarea ': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            fontSize: '18px',
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]: {
                minWidth: '100%',
                fontSize: '12px',
            }
        },
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        // margin: '10px',
        width: '104px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IContentListName {
    handleClose?:any;
}

const ContentListName = (props:IContentListName) => {
    const {handleClose} = props;
    const {isMobile} = useDeviceType();
    const handleLinkChange = () => { }

    const handleSubmit = () => { 
        handleClose();
    }

    return (
        <Box width={isMobile?'300px':'600px'}>
            <Stack sx={styles.root} direction={'column'} spacing={4}>
                Add Website link
                <Input
                    type="text"
                    placeholder='website url'
                    onChange={handleLinkChange}
                />

                <div className="form-group  mt-3" style={styles.formGroup}>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleSubmit}
                        sx={styles.addRecordButton}>
                        <Typography>Submit</Typography>
                    </Button>
                </div>
            </Stack>
        </Box>
    )
}

export default ContentListName