import axios from 'axios'
import { ADD_STOCK_IN_CATEGORY, DISCOVER_CATEGORY_DELETE, DISCOVER_CATEGORY_EDIT, DISCOVER_CATEGORY_NAME, DISCOVER_DELETE_LIST, DISCOVER_EDIT, GET_DISCOVERLIST, GET_DISCOVER_CREATE_LIST } from '../constant/common';


export const getDiscoverList = async () => {
    try {
        return await axios.get(`${GET_DISCOVERLIST}`);
    }
    catch (error) {
        throw error;
    }
}

export const CreateDiscoverList = async (value: any) => {
    try {
        return await axios.post(`${GET_DISCOVER_CREATE_LIST}`, {
            name: value.listName,
            style:value.selectType,
            description:value.description,
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditDiscoverList = async (value: any) => {
    try {
        return await axios.post(`${DISCOVER_EDIT}`, {
            listIds: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const DeleteDiscoverList = async (value: any) => {
    try {
        return await axios.post(`${DISCOVER_DELETE_LIST}`, {
            listIds: value
        });
    }
    catch (error) {
        throw error;
    }
}

export const CreateCategory = async (value: any,id:any) => {
    try {
        return await axios.post(`${DISCOVER_CATEGORY_NAME}`, {
            listId: id,
            categoryNames:value
        });
    }
    catch (error) {
        throw error;
    }
}

export const EditCategory = async (value: any,id:any) => {
    try {
        return await axios.post(`${DISCOVER_CATEGORY_EDIT}`, {
            listId: id,
            categoryNames:value
        });
    }
    catch (error) {
        throw error;
    }
}

export const DeleteCategory = async (value: any,id:any) => {
    try {
        return await axios.post(`${DISCOVER_CATEGORY_DELETE}`, {
            listId: id,
            categoryIds:value
        });
    }
    catch (error) {
        throw error;
    }
}

export const AdddStock = async (value: any,id:any) => {
    try {
        return await axios.post(`${ADD_STOCK_IN_CATEGORY}`, {
            catId: id,
            stockIds:value
        });
    }
    catch (error) {
        throw error;
    }
}