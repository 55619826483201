import { Box, Button, TextareaAutosize, Typography } from '@mui/material'
import {
  GridRowsProp,
  GridColDef,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import { style } from '../../util/themes/styles';
import sendIcon from '../../assets/images/sendIcon.png';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { GridCellParams } from '@mui/x-data-grid';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';
import { TextSelect } from '../../components/textSelect/TextSelect';
import { NotificationHistory, NotificationTypeList, SendNotification } from '../../api/notification';
import { useNotification } from '../../util/NotificationContext';
import { IToastTypes } from '../../components/toast/Toast';
import useDeviceType from '../../util/useDeviceType';
import moment from 'moment';
import _ from 'lodash';
import HeaderComponent from '../../components/HeaderComponent';


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px ',
    [style.breakpoints.down('md')]: {
      padding: '4px ',
    }
  },
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: '#ffffff'
    },
    [style.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 500,
      }
    }
  },
  notificationGrid: {
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'center',
    background: " linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)",
    width: '100%',
    '& .MuiPaper-root': {
      width: '100%'
    }
  },
  sendNotificationBox: {
    background: " linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)",
    // width:'400px'
  },
  sendNotifyBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '24px',
    paddingLeft: '24px',
    [style.breakpoints.down('md')]: {
      paddingLeft: '8px',
      width: '90%',
      '& .css-1x16m3r ' :{
        fontSize: '12px',
        top:-4,
      },  
    },
    '& textarea ': {
      background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
      fontSize: '18px',
      border: 'none',
      borderRadius: '8px',
      minWidth: '350px',
      padding: '16px',
      [style.breakpoints.down('md')]: {
        minWidth: '100%',
        fontSize: '12px',
      }
    },
       
    '& .css-1l7g3fo-MuiInputBase-root-MuiOutlinedInput-root': {
      background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
      width: '350px',
      border: 'none',
      [style.breakpoints.down('md')]: {
        minWidth: '100%',
      }

    },
    '& .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
      background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
    },
    '& .internal-autofill-selected': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#FFFFFF',
      border: 'none',
      background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
      '.MuiSelect-select, input': {
        padding: '8px 10px',
        WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
        WebkitTextFillColor: "#FFF",
        caretColor: 'white',
        [style.breakpoints.down('md')]: {
          padding: '11px 10px',
          fontSize: '12px',
        }
      },
      '& .MuiSelect-icon': {
        color: '#fff'
      },
      '& .MuiInputBase-input': {
        WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
        WebkitTextFillColor: "#FFF",
        caretColor: 'white',
      },
      '& fieldset': {
        border: '1px solid #959CA8'
      },
      '&:hover fieldset': {
        border: '1px solid #5B67FF'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #5B67FF',
        filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
        borderRadius: '4px'
      },
      '&.Mui-error': {
        border: '1.5px solid  #EA1616',
        '& fieldset': {
          border: '1.5px solid #EA1616',
          filter: 'none',
          borderRadius: '4px'
        }
      },

    },
    '& .MuiPaper-root': {
      backgroundColor: '#271A59'
    },
    '& .MuiOutlinedInput-root .MuiSelect-select': {
      '-webkit-box-shadow': 'none',
      caretColor: 'transparent'
    },
    '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
      backgroundColor: '#271A59'
    }
  },
  sendButton: {
    backgroundColor: '#5D3FD3',
    padding: '10px',
    marginLeft: '12px',
    height: '60px',
    width: '122px',
    [style.breakpoints.down('md')]: {
      marginLeft: '0px',
      margin: '12px 0px',
      height: '39px',
      width: '100%',
      '& .MuiTypography-root': {
        fontSize: '12px'
      },
    },
    '& .MuiSvgIcon-root': {
      color: style.palette.text.primary
    },
    '& .MuiTypography-root': {
      color: style.palette.text.primary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& img': {
        marginLeft: '4px',
      }
    },
    '&:hover': {
      backgroundColor: style.palette.primary[400],
      '& .MuiTypography-root': {
        color: style.palette.text.primary
      }
    }
  },
  DataGridStyle: {
    '& MuiPaper-root  ': {
      background: " linear-gradient(180deg, #0D1021 0%, #5F3B67 100%)",
      color: 'black'
      // fontSize: '18px',
      // border: 'none',
      // borderRadius: '8px',
      // minWidth:'350px',
    },
    '& .MuiInputBase-root': {
      color: '#1f2937'
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
    [style.breakpoints.down('md')]: {
      padding: '2px  13px',
      margin: '0px',
    }
  },
  tableGrid: {
    display: 'flex',
    padding: '50px 0px',
    justifyContent: 'center',
    [style.breakpoints.down('md')]: {
      padding: '30px 0px'
    }
  }
}

export const Notification = ({handleDrawer}:any) => {
  const [rowsData, setRowsData] = useState([]);
  const [slectedValue, setSelectedValue] = useState<any>();
  const [categories, setCategories] = useState();
  const [notificationCategory, setNotificationCategory] = useState([]);
  const { setNotify } = useNotification();
  const { isMobile } = useDeviceType();
  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  console.log("rowsData", rowsData);


  const messageColumn: GridColDef = {
    field: "message",
    headerName: "Message",
    width: 300,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const updatedAtColumn: GridColDef = {
    field: "updatedAt",
    headerName: "Updated At",
    width: 150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const columns: GridColDef[] = [];
  columns.push(idColumn);
  columns.push(messageColumn);
  columns.push(updatedAtColumn);

  const handleOnCellClick = (params: GridCellParams) => {
    setSelectedValue(params.value);
  }

  const handleData = (data: any, searchTerm: any) => {
    const filteredRows = data.filter((row: any) => {
      return row?.message?.toLowerCase()?.includes(searchTerm) || row?.message?.toLowerCase()?.startsWith(searchTerm);
    });
    setRowsData(filteredRows);
  }
  const getNotificationCategories = async () => {
    await NotificationTypeList()
      .then((res) => {
        if (res.status === 200) {
          setNotificationCategory(res?.data?.data?.notificationCategory)
        }
      })
  }

  const handleMessage = (e: any) => {
    setSelectedValue(e.target.value);
  }

  const handleChange = (event: any) => {
    setCategories(event.target.value)
  }

  const postNotification = async () => {
    await SendNotification(categories, slectedValue)
      .then((res) => {
        if (res.status === 200) {
          setNotify({
            open: true,
            type: IToastTypes.Success,
            message: res?.data?.message,
          });
        }
      })
  }

  const getNotificationHistoryList = async () => {
    await NotificationHistory()
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          setRowsData(
            data.filter((stock: any) => !stock.isDelete)
              .map((stock: any, index: any) => ({
                id: index + 1,
                message: stock?.message,
                updatedAt: moment(stock?.updatedAt).format('lll'),
              }))
          );
        }
      })
  }

  const handleIsEmpty = () => {
    getNotificationHistoryList();
  }

  useEffect(() => {
    getNotificationCategories();
    getNotificationHistoryList();
  }, [])

  return (
    <Box sx={styles.root}>
      <HeaderComponent  handleDrawer={handleDrawer} heading={'Notification'}/>
      <Box sx={styles.sendNotifyBox}>
        <TextareaAutosize color="neutral" minRows={isMobile ? 4 : 8} value={slectedValue} placeholder='Type here ...' onChange={(e: any) => handleMessage(e)} />
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginTop: '8px', alignItems: 'center', width: '100%' }}>
          <TextSelect
            id="categories"
            value={categories}
            label="Select Category"
            onChange={(event: any) => handleChange(event)}
            options={notificationCategory}
            style={{ width: isMobile ? '100%' : "350px" }}
            inputStyleProps={{ padding: isMobile ? '0px' : '10px 15px', width: isMobile ? '100%' : 'auto' }}
          />
          <Button sx={styles.sendButton} onClick={postNotification}>
            <Typography>
              Send
              <img src={sendIcon} alt='ICON' height={isMobile ? '12px' : '16px'} width={isMobile ? '12px' : '16px'} />
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} handleIsEmpty={handleIsEmpty} />
        <Box sx={styles.tableGrid}>
          <DataGridTable
            rowsData={rowsData}
            columns={columns}
            setRows={setRowsData}
            handleOnCellClick={handleOnCellClick}
          />
        </Box>
      </Box>
      <div id="html-dist"></div>
    </Box>
  )
}
