import { Box, Button, Input, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { style } from "../../../util/themes/styles";
import { EditUser } from "../../../api/users";
import { useNotification } from "../../../util/NotificationContext";
import { IToastTypes } from "../../../components/toast/Toast";

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '90%',
        overflow: 'scroll',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        }
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center'
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '90%',
        height: '50px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IAddNewRecord {
    buttonText?: string;
    modalHeading?: string;
    setUpdateUserDetail?: any;
    handleClose?: any;
}

interface FormValues {
    firstName?: string;
    lastName?: string;
    mobile?: string;
    email?: string;
    password?: string;
}

interface FormErrors {
    firstName?: string;
    lastName?: string;
    mobile?: string;
    email?: string;
    password?: string;
}

export const AddNewRecord = (props: IAddNewRecord) => {
    const { buttonText, modalHeading, setUpdateUserDetail, handleClose } = props;
    const { setNotify } = useNotification();

    const validateForm = (values: FormValues) => {
        const errors: FormErrors = {};
        console.log("values.mobile.length", values?.mobile?.length);

        if (!values.firstName) {
            errors.firstName = "First Name is required";
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test((values.firstName))) {
            errors.firstName = "Please enter valid name";
        }

        if (!values.lastName) {
            errors.lastName = "Last Name is required";
        } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test((values.lastName))) {
            errors.lastName = "Please enter valid name";
        }

        if (!values.mobile) {
            errors.mobile = "Mobile Number is required";
        } else if (values.mobile.length < 9) {
            errors.mobile = "Please enter valid number";
        } else if (!/^[0-9]{1,14}$/.test((values.mobile))) {
            errors.mobile = "Please enter valid number";
        }

        if (!values.email) {
            errors.email = "Email Id is required";
        } else if (
            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                String(values.email).toLowerCase()
            )
        ) {
            errors.email = "Please enter a valid email id";
        }

        if (!values.password) {
            errors.password = "Password is required";
        }

        console.log("Validating errors -- ", errors);

        return errors;
    };

    const handleFormAction = async (values: any) => {
        await EditUser(values, values.id)
            .then((res) => {
                if (res.status === 200) {
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                    handleClose()
                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
    }
    return (
        <Box height={'100%'}>
            <Typography variant="h4" paddingBottom={'18px'}>{modalHeading} User</Typography>
            <Box sx={styles.loginForm}>
                <Formik
                    initialValues={{
                        id: setUpdateUserDetail ? setUpdateUserDetail._id : "",
                        firstName: setUpdateUserDetail ? setUpdateUserDetail.firstName : "",
                        lastName: setUpdateUserDetail ? setUpdateUserDetail.lastName : "",
                        mobile: setUpdateUserDetail ? setUpdateUserDetail.phoneNumber : '',
                        email: setUpdateUserDetail ? setUpdateUserDetail.email : '',
                        password: '',
                        addMoney: '',
                    }}
                    validate={validateForm}
                    onSubmit={handleFormAction}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => {
                        return (
                            <Form
                                name="loginForm"
                                className="loginForm"
                            >
                                <div className="formik-field_wrap row auth_form pb-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        First Name
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            id="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control firstName"
                                            autoComplete="off"
                                        />
                                        {errors.firstName && typeof errors.firstName === 'string' && touched.firstName ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {errors.firstName}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Last Name
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="text"
                                            id="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control lastName"
                                            autoComplete="off"
                                        />
                                        {errors.lastName && typeof errors.lastName === 'string' && touched.lastName ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {errors.lastName}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Mobile Number
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="text"
                                            id="mobile"
                                            value={values.mobile}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control mobile"
                                            autoComplete="off"
                                        />
                                        {errors.mobile && typeof errors.mobile === 'string' && touched.mobile ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {errors.mobile}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Email
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="email"
                                            id="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control email"
                                            autoComplete="off"
                                        />
                                        {errors.email && typeof errors.email === 'string' && touched.email ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {errors.email}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Password
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="password"
                                            id="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control password"
                                            autoComplete="off"
                                        />
                                        {errors.password && typeof errors.password === 'string' && touched.password ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {errors.password}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Add Money
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="number"
                                            id="addMoney"
                                            value={values.addMoney}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control addMoney"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-group mt-3" style={styles.formGroup}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        sx={styles.addRecordButton}>
                                        <Typography>{buttonText}</Typography>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    )
}