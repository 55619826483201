import { IToastTypes } from "../components/toast/Toast";
import { createContext, useContext, useState } from "react";

const init = { open: false, type: IToastTypes.Success, message: "" };
const NotificationContext = createContext<any>(init);
export const useNotification = () => {
  return useContext(NotificationContext);
};
const NotificationProvider = ({ children }: any) => {
  const [notify, setNotify] = useState(init);
  return (
    <NotificationContext.Provider value={{ notify, setNotify }}>
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationProvider;
