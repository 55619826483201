import { Box, Button, Input, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { style } from "../../../util/themes/styles";
import { TextSelect } from "../../../components/textSelect/TextSelect";
import { CreateDiscoverList } from "../../../api/discover";
import { useNotification } from "../../../util/NotificationContext";
import { IToastTypes } from "../../../components/toast/Toast";
import useDeviceType from "../../../util/useDeviceType";

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '90%',
        overflow: 'scroll',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        },
        '& .MuiPaper-root': {
            backgroundColor: '#271A59'
        },
        '& .MuiOutlinedInput-root .MuiSelect-select': {
            '-webkit-box-shadow': 'none',
            caretColor: 'transparent'
        },
        '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            backgroundColor: '#271A59'
        },
        [style.breakpoints.down('md')]: {
            fontSize: '12px'
        }
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '90%',
        height: '50px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IAddNewOrder {
    buttonText?: string;
    modalHeading?: string;
    handleClose?: any;
    itemToEdit?:any;
}

interface FormValues {
    listName?: string;
    selectType?: string;
    description?: string;
}

interface FormErrors {
    listName?: string;
    selectType?: string;
    description?: string;
}

export const AddNewOrder = (props: IAddNewOrder) => {
    const { buttonText, modalHeading, handleClose,itemToEdit } = props;
    const { setNotify } = useNotification();
    console.log("itemToEdit",itemToEdit);
    const {isMobile} = useDeviceType();
    
    const names = [
        { id: 0, _id: 'Rec List' },
        { id: 1, _id: 'Square List' },
        { id: 2, _id: 'Trending List' },
        { id: 3, _id: 'Event List' },
        { id: 4, _id: 'Content List' },
    ];

    const validateForm = (values: FormValues) => {
        const errors: FormErrors = {};

        if (!values.listName) {
            errors.listName = "listName is required";
        }

        if (!values.selectType) {
            errors.selectType = "List Type is required";
        }

        if (!values.description) {
            errors.description = "Description is required";
        }

        console.log("Validating errors -- ", errors);

        return errors;
    };

    const handleChangeCategory = (e: any, setFieldValue: any) => {
        setFieldValue("selectType", e.target.value)
    }

    const handleLoginFormAction = async (values: any) => {
        if (modalHeading === 'Edit List') {
            handleClose(values);
        } else {
            console.log("values", values);
            await CreateDiscoverList(values)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res==>", res?.data?.data);
                        setNotify({
                            open: true,
                            type: IToastTypes.Success,
                            message: res?.data?.message,
                        });
                        handleClose(res?.data?.data);
                    }
                })
        }
    }

    return (
        <Box width={isMobile?'90%':'500px'}>
            <Box sx={styles.loginForm}>
                <Formik
                    initialValues={{
                        listName: itemToEdit?.name || "",
                        selectType: itemToEdit?.style ||  "",
                        description: itemToEdit?.description ||'',
                    }}
                    validate={validateForm}
                    onSubmit={handleLoginFormAction}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => {
                        return (
                            <Form
                                name="loginForm"
                                className="loginForm"
                            >
                                <div className="formik-field_wrap row auth_form pb-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        List Name
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            id="listName"
                                            value={values.listName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control listName"
                                            autoComplete="off"
                                        />
                                       {errors.listName && typeof errors.listName === 'string' && touched.listName ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.listName}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Select Style
                                        <TextSelect
                                            id="selectType"
                                            value={values.selectType}
                                            onChange={(e: any) => handleChangeCategory(e, setFieldValue)}
                                            options={names}
                                            style={{ width: "100%" }}
                                            inputStyleProps={{ padding: '10px 15px', border: "1px solid #ced4da" }}
                                        />
                                        {errors.selectType && typeof errors.selectType === 'string' && touched.selectType ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.selectType}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Description
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            type="text"
                                            id="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control description"
                                            autoComplete="off"
                                        />
                                         {errors.description && typeof errors.description === 'string' && touched.description ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.description}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group mt-3">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        sx={styles.addRecordButton}>
                                        <Typography>Submit</Typography>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    )
}