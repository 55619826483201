import { Autocomplete, Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    GridRowModesModel,
    GridColDef,
    GridActionsCellItem,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { UpdateModal } from '../../components/UpdateModal/UpdateModal';
import { ConfirmDelete } from '../../components/dataGridTable/components/ConfirmDelete';
import addIcon from "../../assets/images/addIcon.png";
import editIcon from "../../assets/images/editIcon.png";
import deleteIcon from "../../assets/images/deleteIcon.png";
import { TextSelect } from '../../components/textSelect/TextSelect';
import useDeviceType from '../../util/useDeviceType';
import { style } from '../../util/themes/styles';
import { useNotification } from '../../util/NotificationContext';
import HeaderComponent from '../../components/HeaderComponent';
import { AddContentList } from './components/AddContentList';
import moment from 'moment';
import StockListWithImage from './components/StockListWithImage';
import ModalTextContent from './components/ModalTextContent';
import StoryCard from './components/StoryCard';
import LinkWebsite from './components/LinkWebsite';
import ContentListName from './components/ContentListName';
import AddStockImage from './components/AddStockImage';
import { AdddStockInContentCategory, DeleteCategoryContentList, DeleteImagesContentList, getContentList } from '../../api/content';
import EditDeleteContentList from './components/editDeleteContentList';
import EditDeleteContentCategory from './components/EditDeleteContentCategory';
import { IToastTypes } from '../../components/toast/Toast';
import FormData from 'form-data';


const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px ',
        [style.breakpoints.down('md')]: {
            padding: '4px ',
        }
    },
    pageHeader: {
        display: 'flex',

        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        'select':{
            borderRadius: '8px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            border: 'none',
            background: ' var(--Primary-color, #5D3FD3)',
            marginRight: '10px',
            minWidth: '44px',
            padding:'4px'
        },
        'option':{ padding: '10px 15px', width: 'auto' },
        '& .MuiSelect-select .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input .css-1eej7l2': {
            padding: '0px',
        },
        '& .css-1eej7l2': {
            padding: '0px',
        },
        '& .MuiTypography-root': {
            color: '#ffffff',
            fontFamily: 'Roboto',
            textTransform: 'capitalize',
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '8px',
                fontWeight: 400,
            },
            '& .css-1x16m3r': {
                top: -9,
            },
            padding: '10px 0px',
            fontWeight: 400,
            fontSize: '14px',
            overflowX: 'scroll'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            border: 'none',
            background: ' var(--Primary-color, #5D3FD3)',
            marginRight: '10px',
            minWidth: '44px',
            '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                textOverflow: 'inherit',
            },
            '&:hover': {
                background: ' var(--Primary-color, #5D3FD3)',
                '& .MuiTypography-root': {
                    color: style.palette.text.primary
                }
            },
            [style.breakpoints.down('md')]: {
                height: '34px',
                width: '38px',
                fontSize: '10px',
            }
        },
        '& .css-14f9imj-MuiFormLabel-root-MuiInputLabel-root': {
            top: -4,
            [style.breakpoints.down('md')]: {
                top: -8
            }
        },
        '& .css-sht1m5-MuiButtonBase-root-MuiButton-root': {
            '&:hover': {
                background: ' var(--Primary-color, #5D3FD3)',
                '& .MuiTypography-root': {
                    color: style.palette.text.primary
                }
            },
        },
        '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
            color: '#ffffff',
        },
        '& .css-sw9456-MuiFormControl-root-MuiTextField-root': {
            borderRadius: '8px',
            background: ' var(--Primary-color, #5D3FD3)',
            display: 'flex',
            height: '40px',
            padding: '0px',
            alignItems: 'center',
            marginRight: '10px',

        },
        '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '0px'
        },
        '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            color: '#ffffff',
        }
    },
    listBox: {
        background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '18px',
        padding: '20px  40px',
        margin: '20px',
        [style.breakpoints.down('md')]: {
            padding: '2px  13px',
            margin: '0px',
            overflow: 'scroll',
            width: '100%'
        }
    },
    tableGrid: {
        display: 'flex',
        padding: '50px 0px',
        justifyContent: 'center',
        [style.breakpoints.down('md')]: {
            padding: '30px 0px'
        }
    },
    listbtn: {
        display: 'flex',
        height: '40px',
        padding: ' 7px 16px',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '8px',
        background: 'var(--Disabled-text-color, #545454)',
        marginRight: '10px',
        '&:hover': {
            background: ' var(--Primary-color, #5D3FD3)',
        },
        [style.breakpoints.down('md')]: {
            height: '34px',
            padding: ' 3px 8px',
            minWidth: '43px',
        }
    },
    listCategory: (isSelected: boolean) => ({
        display: 'flex',
        height: '40px',
        padding: ' 7px 16px',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '8px',
        background: isSelected ? ' #5D3FD3' : 'var(--Disabled-text-color, #545454)',
        marginRight: '10px',
        '&:hover': {
            background: ' var(--Primary-color, #5D3FD3)',
        },
        [style.breakpoints.down('md')]: {
            height: '34px',
            padding: ' 3px 8px',
            minWidth: '43px',
        }
    }),
}


export const ContentTypePage = ({ handleDrawer }: any) => {
    const [rowsData, setRowsData] = useState([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [openModal, setOpenModal] = useState(false);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [buttonText, setButtonText] = useState('Save');
    const [modalHeading, setModalHeading] = useState('Add New');
    const [open, setOpen] = useState(false);

    const { isMobile } = useDeviceType();
    const [listName, setListName] = useState<any>([]);
    const [contentType, setContentTYpe] = useState([])
    const [list, setList] = useState<any>();
    const [defaultValue, setDefaultValue] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [listData, setListData] = useState([]);
    const [dataFor, setDataFor] = useState('');
    const [editCategoryModal, setEditCategoryModal] = useState(false)
    const [openAddStockModal, setOpenAddStockModal] = useState(false);
    const [stockItemId, setstockItemIds] = useState<any>([]);
    const [stockIds,setStockIds] = useState([])
    const [stockIdForDelete, setStockIdForDelete] = useState<any[]>([]);
    const [imageToDelete, setImageToDelete] = useState();
    const { setNotify } = useNotification();
    const [openStockListImage, setOpenStockListImage] = useState(false);
    const [openModalText, setOpenModalText] = useState(false);
    const [openStoryCard, setOpenStoryCard] = useState(false);
    const [openWebsiteLink, setOpenWebsiteLink] = useState(false);
    const [openContentListName, setOpenContentListName] = useState(false);
    const [openStockImage, setOpenStockImage] = useState(false);
    const [storyCard, setStoryCard] = useState(false);
    const [selectedStockCategory, setSelectedStockCategory] = useState<any>();
    const [isLoadingContentTypeList, setIsLoadingContentTyeList] = useState(false);

    console.log("stockIds", stockIds);

    const CustomImageCell = (imageUrl: any) => {
        return <img src={imageUrl.data} alt='Icon' height="40px" width="40px" />
    }

    const handleEditClick = () => {
        setDataFor('edit')
        setEditModalOpen(true);
        setListData(contentType);
    };

    const handleCloseEditDelete = () => {
        setEditModalOpen(false);
    }

    const handleDeleteListClick = () => {
        setDataFor('delete')
        setEditModalOpen(true);
        setListData(contentType)
    }

    const handleDeleteClick = (row: any) => () => {
        console.log("row===>", row);
        rowsData.map((stock:any)=>{
            setStockIds((prevSelected: any) => {
                if (!prevSelected?.includes(stock._id)) {
                      console.log("stock._id");
                    return [...prevSelected, stock._id];
                } else {
                    console.log("stock._id==>");
                    return prevSelected?.filter((id: any) => id !== stock._id);
                }
            });
        });
        setOpen(true);
        setStockIdForDelete([row?._id])
        setImageToDelete(row?.image);
    };

    const getActions = (param: GridRenderCellParams) => {

        return [
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(param?.row)}
                color="inherit"
            />,
        ];
    }


    const idColumn: GridColDef = {
        field: "id",
        headerName: "ID",
        minWidth: 100,
        width: 100,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const logoColumn: GridColDef = {
        field: "image",
        headerName: "Logo",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => (
            <CustomImageCell data={cellValues.value} />
        ),
    };

    const imageColumn: GridColDef = {
        field: "image",
        headerName: "Image",
        minWidth: 100,
        width: 300,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => (
            <CustomImageCell data={cellValues.value} />
        ),
    };

    const stockColumn: GridColDef = {
        field: "shortName",
        headerName: "Stock Name",
        minWidth: 100,
        width: 250,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const srColumn: GridColDef = {
        field: "sr",
        headerName: "SR Name",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const closingPriceColumn: GridColDef = {
        field: "pl_percentage",
        headerName: "Pl Percentage",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const currentPriceColumn: GridColDef = {
        field: "current_price",
        headerName: "Current Price",
        minWidth: 100,
        width: 200,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const industryColumn: GridColDef = {
        field: "industry",
        headerName: "Industry",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };


    const nameColumn: GridColDef = {
        field: "name",
        headerName: "Name",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const descriptionColumn: GridColDef = {
        field: "description",
        headerName: "Description",
        minWidth: 100,
        width: 350,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };
    const linkUrlColumn: GridColDef = {
        field: "linkUrl",
        headerName: "Link URL",
        minWidth: 100,
        width: 350,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };
    const colorColumn: GridColDef = {
        field: "color",
        headerName: "Color",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const actionColumn: GridColDef = {
        field: "action",
        headerName: "Actions",
        minWidth: 100,
        width: 300,
        maxWidth: 350,
        flex: 1,
        renderCell: getActions,
        resizable: false,
    };

    const columns: GridColDef[] = [];
    if (selectedCategory === 'Modal text content') {
        columns.push(idColumn);
        columns.push(imageColumn);
        columns.push(nameColumn);
        columns.push(descriptionColumn);
        columns.push(actionColumn);
    } else if (selectedCategory === 'Link Website') {
        columns.push(idColumn);
        columns.push(imageColumn);
        columns.push(nameColumn);
        columns.push(linkUrlColumn);
        columns.push(colorColumn);
        columns.push(actionColumn);
    } else if (selectedCategory === 'Story Card') {
        columns.push(idColumn);
        columns.push(imageColumn);
        columns.push(actionColumn);
    } else {
        columns.push(idColumn);
        columns.push(logoColumn);
        columns.push(stockColumn);
        columns.push(srColumn);
        columns.push(industryColumn);
        columns.push(closingPriceColumn);
        columns.push(currentPriceColumn);
        columns.push(actionColumn);
    }

    const handleAddList = () => {
        setOpenCategoryModal(true);
    }

    const handleCloseStockImage = (data: any) => {
        console.log("data==>", data);

        if (data?.length) {
            const index = contentType.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...contentType];
            console.log("newArray[ListIndex]", newArray[ListIndex]);

            newArray[ListIndex].categories = data;
            setListName(data);
        }

        setOpenStockImage(false);
    }

    const handleAddCategory = () => {
        if (selectedCategory === 'Stock List With Image') {
            setOpenStockImage(true)
        }

        if (selectedCategory === 'Story Card') {
            setOpenStoryCard(true);
        }
    }

    const handleEditCategory = () => {
        setDataFor('edit')
        setEditCategoryModal(true);
    }

    const handleDeleteCategory = () => {
        setDataFor('delete')
        setEditCategoryModal(true);
    }

    const handleAddRecord = () => {

       

        if (selectedCategory === 'Stock List With Image') {
            rowsData.map((stock:any)=>{
                setstockItemIds((prevSelected: any) => {
                    if (prevSelected?.includes(stock._id)) {
                        return prevSelected?.filter((id: any) => id !== stock._id);
                    } else {
                        return [...prevSelected, stock._id];
                    }
                });
            })
            setOpenStockListImage(true);
        }
        if (selectedCategory === 'Modal text content') {
            setOpenModalText(true);
        }
        if (selectedCategory === 'Story Card') {
            setOpenStoryCard(true);
            setStoryCard(true);
        }

        if (selectedCategory === 'Link Website') {
            setOpenWebsiteLink(true);
        }
    }

    const handleCloseContentList = () => {
        setOpenContentListName(false);
    }

    const handleClose = (data: any) => {
        if (data?.style) {
            const index = contentType.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...contentType];
            newArray[ListIndex].categories = data;
            setContentTYpe(newArray);
        }

        setOpenCategoryModal(false);
    }

    const handleCloseCategory = () => {
        setOpenCategoryModal(false);
    }

    const handleCloseEdit = (data: any) => {
        console.log("data==>", data);
        if (data.length) {
            const index = contentType.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...contentType];
            newArray[ListIndex].categories = data;
            setListName(data);
        }
        setStoryCard(false);
        setOpenStoryCard(false);
    }

    const handleCloseEditCategory = (data: any) => {
        console.log("listName", data);
        if (data?.length) {
            const index = contentType.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...contentType];
            newArray[ListIndex].categories = data;
            setListName(data);
        }
        setOpenModalText(false);
        setEditCategoryModal(false);
    }

    const handleCloseAddStockModal = (data: any) => {
        console.log("data==>",data);
        setstockItemIds([]);
        const index = contentType.map((item: any) => item._id === list?._id)
        const ListIndex = index.findIndex((item: any) => item !== -1);
        const newArray: any = [...contentType];
        const newCategories = newArray[ListIndex].categories;
        const categoryIndex = newCategories.findIndex((item: any) => item?._id === selectedStockCategory);
        setOpenStockListImage(false);
        if (data?.length) {
            newArray[ListIndex].categories[categoryIndex].stockIds = data;

            setRowsData(
                data.map((stock: any, index: any) => {
                    return ({
                        key: stock._id,
                        id: index + 1,
                        _id: stock._id,
                        image: stock?.image,
                        shortName: stock?.shortName,
                        sr: stock?.sr,
                        earningType: stock?.earningType,
                        date: moment(stock?.date).format('ll'),
                        pl_percentage: stock?.pl_percentage,
                        industry: stock?.industry,
                        current_price: stock?.current_price.toFixed(2),
                    })
                })
            );
            setListName(newCategories);
            setStockIds([]);
        }

    }

    const handleCloseWebsiteLink = (data: any) => {
        const index = contentType.map((item: any) => item._id === list?._id)
        const ListIndex = index.findIndex((item: any) => item !== -1);
        const newArray: any = [...contentType];
        newArray[ListIndex].categories = data;
        setListName(data);
        setOpenWebsiteLink(false);

    }

    const handleCloseDelete = () => {
        setOpen(false);
    }

    const handleChangeList = (e?: any) => {
        const index:any = contentType.filter((item: any) => item._id === e.target.value);
        console.log("index",index);
        
        setList(index[0]);
        setListName(index[0].categories);
        setSelectedCategory(index[0].style);
        setSelectedStockCategory('');
        if (index[0].style === 'Modal text content') {
            setRowsData(
                index[0].categories.map((data: any, index: any) => ({
                    key: data._id,
                    id: index + 1,
                    _id: data._id,
                    image: data?.image[0],
                    name: data?.name,
                    description: data?.description,
                }))
            )
        }
        if (index[0].style === 'Story Card' || index[0].style === 'Stock List With Image' ) {
            setRowsData([]);
        }
        if (index[0].style === 'Link Website') {
            setRowsData(
                index[0].categories.map((data: any, index: any) => ({
                    key: data._id,
                    id: index + 1,
                    _id: data._id,
                    image: data?.image[0],
                    linkUrl: data?.linkUrl,
                    name: data?.name,
                    color: data?.color,
                }))
            )
        }
    }

    const handleCategory = (item: any) => {
      
        setstockItemIds([]);
        setStockIds([]);
        console.log("item==>", item);

        if (selectedCategory === 'Story Card') {
            setSelectedStockCategory(item?._id)
            item?.image !== '' &&
                setRowsData(item?.image?.map((item: any, index: any) => ({
                    key: index + 1,
                    id: index + 1,
                    image: item,

                })));
        } else {
          setSelectedStockCategory(item?._id)
            setRowsData(
                item.stockIds.map((stock: any, index: any) => {
                    // setStockIds((prevSelected: any) => {
                    //     if (prevSelected?.includes(stock._id)) {
                    //         return prevSelected?.filter((id: any) => id !== stock._id);
                    //     } else {
                    //         return [...prevSelected, stock._id];
                    //     }
                    // });
                    return ({
                        key: stock._id,
                        id: index + 1,
                        _id: stock._id,
                        image: stock?.image,
                        shortName: stock?.shortName,
                        sr: stock?.sr,
                        earningType: stock?.earningType,
                        date: moment(stock?.date).format('ll'),
                        pl_percentage: stock?.pl_percentage,
                        industry: stock?.industry,
                        current_price: stock?.current_price?.toFixed(2),
                    })
                })
            );
        }

    }


    const handleDelete = async () => {
        setOpen(false);
        console.log("selectedStockCategory", selectedStockCategory);
        console.log("listName?._id", list?._id);
        const index = contentType.map((item: any) => item._id === list?._id)
        const ListIndex = index.findIndex((item: any) => item !== -1);
        const newArray: any = [...contentType];
        const newCategories = newArray[ListIndex].categories;
        const categoryIndex = newCategories.findIndex((item: any) => item?._id === selectedStockCategory);

        if (selectedCategory === 'Modal text content' || selectedCategory === 'Link Website') {
            await DeleteCategoryContentList(stockIdForDelete[0], list?._id)
                .then((res) => {
                    if (res.status === 200) {
                        setNotify({
                            open: true,
                            type: IToastTypes.Success,
                            message: res?.data?.message,
                        });
                    }
                }).catch((res) => {
                    setNotify({
                        open: true,
                        type: IToastTypes.Error,
                        message: res?.response?.data?.message,
                    });
                })
        }

        if (selectedCategory === 'Stock List With Image') {
            console.log("stockIdForDelete", stockIdForDelete);

           

            const deletedStock = stockIds.filter((item: any) => item !== stockIdForDelete[0]);
            setstockItemIds((prevItems: any) => prevItems.filter((item: any) => item !== stockIdForDelete[0]));
            console.log("deletedStock==>", deletedStock);

            await AdddStockInContentCategory(deletedStock, selectedStockCategory)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res==>", res?.data?.data?.stockIds);
                        newArray[ListIndex].categories[categoryIndex].stockIds = res?.data?.data?.stockIds;
                        setListName(newCategories);
                        if(res?.data?.data?.stockIds.length>0){
                        setRowsData(
                            res?.data?.data?.stockIds.map((stock: any, index: any) => {
                                setstockItemIds((prevSelected: any) => {
                                    if (prevSelected?.includes(stock._id)) {
                                        return prevSelected?.filter((id: any) => id !== stock._id);
                                    } else {
                                        return [...prevSelected, stock._id];
                                    }
                                });
                                return ({
                                    key: stock._id,
                                    id: index + 1,
                                    _id: stock._id,
                                    image: stock?.image,
                                    shortName: stock?.shortName,
                                    sr: stock?.sr,
                                    earningType: stock?.earningType,
                                    date: moment(stock?.date).format('ll'),
                                    pl_percentage: stock?.pl_percentage,
                                    industry: stock?.industry,
                                    current_price: stock?.current_price?.toFixed(2),
                                })
                            })
                        );
                        }else {
                            setRowsData([]) ;
                        }
                        setNotify({
                            open: true,
                            type: IToastTypes.Success,
                            message: res?.data?.message,
                        });
                        setStockIds([]);

                    }
                }).catch((res) => {
                    setNotify({
                        open: true,
                        type: IToastTypes.Error,
                        message: res?.response?.data?.message,
                    });
                })
        }


        if (selectedCategory === 'Story Card') {
            let formData = new FormData();
            formData.append('_id', selectedStockCategory);
            formData.append('image', imageToDelete);

            await DeleteImagesContentList(formData)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res==>", res?.data?.data);
                        newArray[ListIndex].categories[categoryIndex] = res?.data?.data;
                        // setListName(res?.data?.data);
                        setRowsData(res?.data?.data[0]?.image?.map((item: any, index: any) => ({
                            key: index + 1,
                            id: index + 1,
                            image: item,

                        })));
                        setNotify({
                            open: true,
                            type: IToastTypes.Success,
                            message: res?.data?.message,
                        });
                    }
                }).catch((res) => {
                    setNotify({
                        open: true,
                        type: IToastTypes.Error,
                        message: res?.response?.data?.message,
                    });
                })
        }


    }

    const getContentListData = async () => {
        setIsLoadingContentTyeList(true);
        setstockItemIds([]);

        await getContentList()
            .then((res) => {
                if (res.status === 200) {
                    console.log("Response==>", res.data.data);

                    const data = res?.data?.data;
                    setContentTYpe(data);

                    setList(data?.[0]?.style);
                    setDefaultValue(data.length > 0 ? data[0]?.style : '')
                    setListName(data[0].categories);
                    setSelectedCategory(data[0].style);
                    setSelectedStockCategory(data[0].categories[0]?._id)
                    setRowsData(
                        data[0].categories[0].stockIds.map((stock: any, index: any) => {
                            // setstockItemIds((prevSelected: any) => {
                            //     if (prevSelected?.includes(stock._id)) {
                            //         return prevSelected?.filter((id: any) => id !== stock._id);
                            //     } else {
                            //         return [...prevSelected, stock._id];
                            //     }
                            // });
                            return ({
                                key: stock._id,
                                id: index + 1,
                                _id: stock._id,
                                image: stock?.image,
                                shortName: stock?.shortName,
                                sr: stock?.sr,
                                earningType: stock?.earningType,
                                date: moment(stock?.date).format('ll'),
                                pl_percentage: stock?.pl_percentage,
                                industry: stock?.industry,
                                current_price: stock?.current_price?.toFixed(2),
                            })
                        })
                    );
                }
            })
        setIsLoadingContentTyeList(false);
    }


    useEffect(() => {
        getContentListData();
        // setTimeout(() => {
        //     if(!isLoadingContentTypeList){
        //         setList(contentType?.[0]);
        //     }              
        //   }, 2000);
    }, [])

    return (
        <Box sx={styles.root} >
            <HeaderComponent handleDrawer={handleDrawer} heading={'Content Types'} />
            <Box sx={styles.pageHeader} className='customScroll'>

                {/* <TextSelect
                    id="categories"
                    value={list}
                    defaultValue={defaultValue}
                    label='Select'
                    onChange={(event: any) => handleChangeList(event)}
                    options={contentType}
                    style={{ width: isMobile ? '113px' : 'auto', marginRight: '4px', minWidth: '113px' }}
                    inputStyleProps={{ padding: isMobile ? '0px' : '10px 15px', width: isMobile ? '100%' : 'auto' }}
                /> */}

                <select 
                className="selectpicker" 
                data-style="btn-primary" 
                onChange={(event: any) => handleChangeList(event)}>
                    {contentType?.map((item:any)=>{
                        return  <option className="special" value={item?._id}>{item?.name}</option>
                    })}
                   
                </select>

                <Button
                    type="submit"
                    color="primary"
                    onClick={handleAddList}
                    sx={styles.listbtn}>
                    <img src={addIcon} alt='add' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    onClick={handleEditClick}
                    sx={styles.listbtn}>
                    <img src={editIcon} alt='edit' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleDeleteListClick}
                    sx={styles.listbtn}>
                    <img src={deleteIcon} alt='delete' height={'12px'} width={'12px'} />
                </Button>
            </Box>
            {selectedCategory == 'Stock List With Image' &&
                <Box sx={styles.pageHeader} className='customScroll'>
                    {listName.length > 0 &&
                        listName?.map((item: any) => {
                            const isSelected = selectedStockCategory === item?._id;
                            return (
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={() => handleCategory(item)}
                                    sx={styles.listCategory(isSelected)}>
                                    <Typography>{item?.name}</Typography>
                                </Button>
                            )
                        })
                    }
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleAddCategory}
                        sx={styles.listbtn}>
                        <img src={addIcon} alt='add' height={'12px'} width={'12px'} />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleEditCategory}
                        sx={styles.listbtn}>
                        <img src={editIcon} alt='edit' height={'12px'} width={'12px'} />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleDeleteCategory}
                        sx={styles.listbtn}>
                        <img src={deleteIcon} alt='delete' height={'12px'} width={'12px'} />
                    </Button>
                </Box>
            }
            {selectedCategory === 'Story Card' &&
                <Box sx={styles.pageHeader} className='customScroll'>
                    {listName.length > 0 &&
                        listName?.map((item: any) => {
                            const isSelected = selectedStockCategory === item?._id;
                            return (
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={() => handleCategory(item)}
                                    sx={styles.listCategory(isSelected)}>
                                    <Typography>{item?.name}</Typography>
                                </Button>
                            )
                        })
                    }
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleAddCategory}
                        sx={styles.listbtn}>
                        <img src={addIcon} alt='add' height={'12px'} width={'12px'} />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleEditCategory}
                        sx={styles.listbtn}>
                        <img src={editIcon} alt='edit' height={'12px'} width={'12px'} />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleDeleteCategory}
                        sx={styles.listbtn}>
                        <img src={deleteIcon} alt='delete' height={'12px'} width={'12px'} />
                    </Button>
                </Box>
            }
            <Box sx={styles.listBox} >
                <Box sx={styles.tableGrid}>
                    <DataGridTable
                        rowsData={rowsData}
                        columns={columns}
                        setRows={setRowsData}
                        setRowModesModel={setRowModesModel}
                        addContentList
                        setOpenModal={setOpenModal}
                        setModalHeading={setModalHeading}
                        setButtonText={setButtonText}
                        setOpenAddStockModal={setOpenAddStockModal}
                        handleAddRecord={handleAddRecord}
                        loading={isLoadingContentTypeList}
                    />
                </Box>
            </Box>

            {openStockImage &&
                <UpdateModal isOpenModal={openStockImage} handleClose={handleCloseStockImage}>
                    <AddStockImage handleClose={handleCloseStockImage} listId={list?._id} />
                </UpdateModal>
            }
            {openContentListName &&
                <UpdateModal isOpenModal={openContentListName} handleClose={handleCloseContentList}>
                    <ContentListName handleClose={handleCloseContentList} />
                </UpdateModal>
            }
            {openCategoryModal &&
                <UpdateModal isOpenModal={openCategoryModal} handleClose={handleClose}>
                    <AddContentList buttonText={buttonText} modalHeading={modalHeading} handleClose={handleClose} />
                </UpdateModal>
            }
            {open &&
                <ConfirmDelete open={open} handleCloseDelete={handleCloseDelete} handleDelete={handleDelete} />
            }
            {openStockListImage &&
                <UpdateModal isOpenModal={openStockListImage} handleClose={handleCloseAddStockModal}>
                    <StockListWithImage selectedStockCategory={selectedStockCategory} stockItemId={stockItemId} handleClose={handleCloseAddStockModal} />
                </UpdateModal>
            }
            {openModalText &&
                <UpdateModal isOpenModal={openModalText} handleClose={handleCloseEditCategory}>
                    <ModalTextContent handleClose={handleCloseEditCategory} listId={list?._id} />
                </UpdateModal>
            }
            {openStoryCard &&
                <UpdateModal isOpenModal={openStoryCard} handleClose={handleCloseEdit} >
                    <StoryCard storyCard={storyCard} handleClose={handleCloseEdit} listId={list?._id} selectedStockCategory={selectedStockCategory} />
                </UpdateModal>
            }

            {openWebsiteLink &&
                <UpdateModal isOpenModal={openWebsiteLink} handleClose={handleCloseWebsiteLink}>
                    <LinkWebsite handleClose={handleCloseWebsiteLink} listId={list?._id} />
                </UpdateModal>
            }

            {editCategoryModal &&
                <UpdateModal isOpenModal={editCategoryModal} handleClose={handleCloseEditCategory}>
                    <EditDeleteContentCategory listData={listName} listName={list} handleClose={handleCloseEditCategory} dataFor={dataFor} selectedCategory={selectedCategory} />
                </UpdateModal>
            }

            {editModalOpen &&
                <UpdateModal isOpenModal={editModalOpen} handleClose={handleCloseEditDelete}>
                    <EditDeleteContentList listData={listData} handleClose={handleCloseEditDelete} dataFor={dataFor} />
                </UpdateModal>
            }
        </Box>
    )
}
