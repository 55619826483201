import { Box, Button, Input, Typography } from "@mui/material";
import Logo from "../../assets/images/sanaLogo.png";
import sanaIcon from "../../assets/images/sanaLogoIcon.png";
import BackgroundImage from "../../assets/images/LoginBackground.webp";
import { Form, Formik } from "formik";
import { style } from "../../util/themes/styles";
import { AdminLogin } from "../../api/auth";
import { useNotification } from "../../util/NotificationContext";
import { IToastTypes } from "../../components/toast/Toast";
const styles = {
    body: (BackgroundImage: any) => ({
        overflowY: 'hidden',
        background: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
        [style.breakpoints.down('md')]:{
            height: '100%',
        }
    }),
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        textAlign: 'center',
        borderRadius: '20px',
        width: '550px',
        height: '700px',
        top: '10%',
        right: '10%',
        zIndex: 1,
        backgroundColor: 'rgb(255, 255, 255, 0.2)',
        backdropFilter: 'blur(5px)',
        [style.breakpoints.down('md')]:{
            right: 0,
            width: '100%',
        }
    },
    glassStyle: {
        height: '900px',
        pointerEvents: 'none',
        position: 'relative',
        width: '900px',
        zIndex: 0,
        top: '4rem',
        left: ' 5rem',
    },
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            background: '#333',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        }
    },
    loginHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '28px',
        '& .MuiTypography-root ': {
            fontSize: '62px',
            fontWeight: 700,
            color: style.palette.text.primary,
        },
        [style.breakpoints.down('md')]:{
            fontSize: '50px',
        }
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        margin: '10px',
        width: '90%',
        height: '50px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '20px',
            fontWeight: 500,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}
export const Login = (props: any) => {
    const { setIsInitialized, setLoading } = props
    const { setNotify } = useNotification();
    const validateForm = (values: any) => {
        const errors: any = {};

        if (!values.username) {
            errors.username = "username is required";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password !== "" && values.password.length < 5) {
            errors.password = "At least 5 characters required";
        }

        console.log("Validating errors -- ", errors);

        return errors;
    };

    const handleLoginFormAction = async (values: any) => {
        
        setLoading(false);

        await AdminLogin(values)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res?.data?.data?.auth, "res");
                    localStorage.setItem("Auth_Token", res?.data?.data?.auth);
                    localStorage.setItem("LoginFlag", "true");
                    setIsInitialized(true);
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            }).catch((res)=>{
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message:res?.response?.data?.message,
                });
                
            })

    }
    return (
        <Box sx={styles.body(BackgroundImage)}>
            <Box sx={styles.loginBox}>
                <Box sx={styles.loginHeader}>
                    <img src={sanaIcon} alt="ICON" height={'64px'} width={'110px'} />
                    <Typography>Login</Typography>
                </Box>
                <Box sx={styles.loginForm}>
                    <Formik
                        initialValues={{
                            username: "",
                            password: "",
                        }}
                        validate={validateForm}
                        onSubmit={handleLoginFormAction}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            isSubmitting,
                        }) => {
                            return (
                                <Form
                                    name="loginForm"
                                    className="loginForm"
                                >
                                    <div className="formik-field_wrap row auth_form pb-3">
                                        <div className="formik-field-left col col-12 auth_field">
                                            Email
                                            <Input
                                                style={{ padding: "10px 15px", width: "90%" }}
                                                id="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control username"
                                                autoComplete="off"
                                            />
                                            {errors.username && touched.username ? (
                                                <p
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: "24px",
                                                        color: "red",
                                                        fontSize: "14px",
                                                        lineHeight: '18px',
                                                        marginLeft: "12px",
                                                    }}
                                                >
                                                    {errors.username}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="formik-field_wrap row auth_form">
                                        <div className="formik-field-left col col-12 auth_field">
                                            Password
                                            <Input
                                                style={{ padding: "10px 15px", width: "90%" }}
                                                type="password"
                                                id="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="form-control password"
                                                autoComplete="off"
                                            />
                                            {errors.password && touched.password ? (
                                                <p
                                                    style={{
                                                        display: "flex",
                                                        marginBottom: "24px",
                                                        color: "red",
                                                        fontSize: "14px",
                                                        lineHeight: '18px',
                                                        marginLeft: "12px",
                                                    }}
                                                >
                                                    {errors.password}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            sx={styles.addRecordButton}>
                                            <Typography>Login</Typography>
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
            <Box sx={styles.glassStyle}>
                <img src={Logo} alt="ICON" height={'300px'} />
            </Box>
        </Box>
    )
}