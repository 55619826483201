import { Box, Button, Input, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { style } from '../../../util/themes/styles'
import { FetchStockList } from '../../../api/stocks'
import { useNotification } from '../../../util/NotificationContext'
import addCircleIcon from "../../../assets/images/addCircleIcon.png";
import clearIcon from "../../../assets/images/ClearIcon.png";
import { IToastTypes } from "../../../components/toast/Toast";
import searchIcon from "../../../assets/images/searchIcon.png";
import { AdddStockInContentCategory } from '../../../api/content'
import useDeviceType from '../../../util/useDeviceType'

const styles = {
    root: {
        height:'100%',
        overflow:'scroll',
        '& .MuiInputBase-root': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]:{
                minWidth:'100%',
                padding: '8px',
            }
        },
        
    },
    selectedList: {
        borderRadius: '8px',
        padding: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: ' space-between',
        margin: '8px 0px',
        background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))'
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        // margin: '10px',
        width: '104px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
    chipView: {
        height: '80%',
        overflow: 'scroll',
        width: '100%',
    },
    selectedChipView: {
        height: '200px',
        overflow: 'scroll',
        width: '100%',
    }
}

interface IStockListWithImage {
    handleClose?: any;
    selectedStockCategory?: any;
    stockItemId?:any;
}


const StockListWithImage = (props: IStockListWithImage) => {
    const { handleClose, selectedStockCategory ,stockItemId} = props;
    const [rowsData, setRowsData] = useState([]);
    const { setNotify } = useNotification();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedItemIds, setSelectedItemIds] = useState<any>(stockItemId);
    const [serachItems, setSearchItem] = useState<any>([]);
    const [selectedImage, setSelectedImage] = useState<any | null>(null);
    const {isMobile} = useDeviceType();
    console.log("selectedItemIds==>", selectedItemIds);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        const filteredRows = rowsData.filter((row: any) => {
            return row?.shortName?.includes(searchTerm) || row?.shortName?.toLowerCase()?.includes(searchTerm) || row?.sr?.toLowerCase()?.startsWith(searchTerm);;
        });
        setSearchItem(filteredRows);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ';' && searchTerm.trim() !== '') {
            const selectedItem: any = rowsData.find((item: any) => item?.sr.toLowerCase() === searchTerm.toLowerCase());
            if (selectedItem !== undefined) {
                if (selectedItem && !selectedItems.find((item: any) => item.id === selectedItem.id)) {
                    setSelectedItems([...selectedItems, selectedItem]);
                    setSearchTerm('');
                }

                setSelectedItemIds((prevSelected: any) => {
                    if (prevSelected?.includes(selectedItem)) {
                        return prevSelected?.filter((id: any) => id !== selectedItem._id);
                    } else {
                        return [...prevSelected, selectedItem._id];
                    }
                });
                setSearchTerm('');
            }
        }
    };

    const handleRemoveItem = (itemId: number) => {
        const updatedSelectedItems = selectedItems.filter(item => item.id !== itemId);
        setSelectedItems(updatedSelectedItems);
    };

    const handleSelect = (item: any) => {
        const selectedIndex = selectedItems.findIndex(selected => selected.id === item.id);
        if (selectedIndex === -1) {
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems.filter(selected => selected.id !== item.id));
        }

        setSelectedItemIds((prevSelected: any) => {
            if (prevSelected?.includes(item._id)) {
                return prevSelected.filter((id: any) => id !== item?._id);
            } else {
                return [...prevSelected, item._id];
            }
        });

    };

    const handleCancelLastItem = (itemToRemove: any) => {
        setSearchTerm('');
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImage(files);
        }
    };

    const handleSubmit = async () => {
        await AdddStockInContentCategory(selectedItemIds, selectedStockCategory)
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res);
                    handleClose(res?.data?.stockData);
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });

                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
        handleClose();
    }

    const getStock = async () => {
        await FetchStockList()
            .then((res) => {
                if (res.status === 200) {
                    const data = res?.data?.data;
                    setRowsData(
                        data.filter((stock: any) => !stock.isDelete)
                            .map((stock: any, index: any) => ({
                                id: index + 1,
                                _id: stock._id,
                                image: stock?.image,
                                shortName: stock?.shortName,
                                sr: stock?.sr,
                            }))
                    );
                }
            })
    }

    useEffect(() => {
        getStock();
    }, [])
    return (
        <Box width={isMobile?'300px':'500px'} height={'100%'}>
            <Stack sx={styles.root} direction={'column'} spacing={4}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    Add Stock
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Search "
                        startAdornment={
                            <img src={searchIcon} alt="clear" height='14px' width='14px' style={{ marginRight: '4px', marginBottom: '2px' }} />
                        }
                        endAdornment={(

                            <Button onClick={handleCancelLastItem} size="small">
                                <img src={clearIcon} alt="clear" height='18px' width='18px' />
                            </Button>

                        )}
                    />
                    {/* {selectedItems?.length > 0 &&
                        <ul style={styles.selectedChipView} className="customScroll">
                            {selectedItems.map((item: any) => (
                                <li key={item.id} style={styles.selectedList}>
                                    {item?.shortName}
                                    <button onClick={() => handleRemoveItem(item.id)}>
                                        <img src={clearIcon} alt="clear" height='24px' width='24px' />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    } */}
                </div>
                <div style={styles.chipView} className="customScroll">
                    {serachItems.map((item: any) => (
                        <li key={item.id} style={styles.selectedList}>
                            {item?.shortName}
                            {selectedItemIds.includes(item._id) ? 
                            <button onClick={() => handleRemoveItem(item.id)}>
                               <img src={clearIcon} alt="clear" height='22px' width='22px' />
                            </button> : <button onClick={() => handleSelect(item)}>
                                <img src={addCircleIcon} alt="clear" height='26px' width='26px' />
                            </button>}
                        </li>
                    ))}
                </div>


                <div className="form-group  mt-3" style={styles.formGroup}>
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleSubmit}
                        sx={styles.addRecordButton}>
                        <Typography>Submit</Typography>
                    </Button>
                </div>
            </Stack>
        </Box>
    )
}

export default StockListWithImage