import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const PrivateRoute = (props: any) => {
    const location = useLocation();
    const { children } = props
    const auth = localStorage.getItem("LoginFlag")
    return (

        auth ?
            <>{children}</> : (
                <Navigate to={`/`} replace state={{ from: location.pathname }} />
            )
    )
}

export default PrivateRoute