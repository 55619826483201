import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ChangeDefaultAmount, EditCronTime, EditStocksLimit, EditTradeLimit, EditWatchlistLimit, getDefaultAmount, getStockLimit, getTradeLimit, getWatchlistLimit } from '../api/config';
import { useNotification } from '../util/NotificationContext';
import { IToastTypes } from './toast/Toast';
import useDeviceType from '../util/useDeviceType';
import { style } from '../util/themes/styles';
import clearIcon from "../assets/images/ClearIcon.png";

interface IPercentageEditable {
    heading?: string;
    handleClose?: any;
}

const styles = {
    modalRoot: {
        // margin: '20px',
        [style.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        '& .css-1m1uzro-MuiButtonBase-root-MuiButton-root': {
            backgroundColor: '#5D3FD3'
        },
        '& .css-1elea6p-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
            color: '#fff'
        },
        '& .header .css-ibyx69-MuiModal-root .MuiBox-root': {
            height: '40% !important',
        },

    }
}

const PercentageEditable = (props: IPercentageEditable) => {
    const { heading, handleClose } = props;
    const [minutes, setMinutes] = useState();
    const [amount, setAmount] = useState(0);
    const [watchlistLimit, setWatchlistLimit] = useState(0);
    const [stocksLimit, setStocksLimit] = useState(0);
    const [tradeLimit, setTradeLimit] = useState(0);
    const { setNotify } = useNotification();
    const { isMobile } = useDeviceType();
    const handleMinuteChange = (event: any) => {
        const value = event.target.value;
        setMinutes(value);
    };

    const handleWatchlistLimit = (event: any) => {
        const value = event.target.value;
        setWatchlistLimit(value);
    };

    const handleTradeLimit = (event: any) => {
        const value = event.target.value;
        setTradeLimit(value);
    }

    const handleStocksLimit = (event: any) => {
        const value = event.target.value;
        setStocksLimit(value);
    };

    const handleAmountChange = (event: any) => {
        const value = event.target.value;
        setAmount(value);
    }

    const handleKeyPress = (event: any) => {
        // Check if the pressed key is the minus sign and prevent its input
        if (event.key === '-') {
            event.preventDefault();
        }
    }

    const handleUpdateWatchlistLimit = async () => {
        await EditWatchlistLimit(watchlistLimit)
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })
        handleClose();
    }

    const handleAddMinute = async () => {
        await EditCronTime(minutes)
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })
        handleClose();
    };

    const handleDefaultAmount = async () => {
        await ChangeDefaultAmount(amount)
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })
        handleClose();
    }

    const handleUpdateTradeLimit = async () => {
        await EditTradeLimit(tradeLimit)
            .then((res) => {
                if (res.status === 200) {
                    handleClose();
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });

                }
            })
        handleClose();
    }

    const handleUpdateStocksLimit = async () => {
        await EditStocksLimit(stocksLimit)
            .then((res) => {
                if (res.status === 200) {
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            })
    }

    const TradeLimit = async () => {
        await getTradeLimit()
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res?.data?.data[0]?.tradeLimit);

                    setTradeLimit(res?.data?.data[0]?.tradeLimit);
                }
            })
    }

    const StockLimit = async () => {
        await getStockLimit()
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res?.data?.data[0]?.stockLimit);

                    setStocksLimit(res?.data?.data[0]?.stockLimit);
                }
            })
    }

    const WatchListLimit = async () => {
        await getWatchlistLimit()
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res?.data?.data[0]?.watchlistLimit);

                    setWatchlistLimit(res?.data?.data[0]?.watchlistLimit);
                }
            })
    }

    const DefaultAmount = async () => {
        await getDefaultAmount()
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res);

                    setAmount(res?.data?.data[0]?.balance);
                }
            })
    }

    useEffect(() => {
        TradeLimit();
        StockLimit();
        WatchListLimit();
        DefaultAmount();
    }, [])



    return (
        <Box sx={styles.modalRoot} >

            <Grid sx={{ marginBottom: '20px' }}>
                <Typography variant='h4'>{heading ? heading : "Heading"}</Typography>

            </Grid>

            <Grid container spacing={2} alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
                <Grid item>
                    {heading == 'Market frequency' &&
                        <TextField
                            id="outlined-number"
                            label="Minutes"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={minutes}
                            onChange={(event) => handleMinuteChange(event)}
                        />
                    }
                    {heading == 'Watchlist per user' &&
                        <TextField
                            id="outlined-number"
                            label="Watchlist limit"
                            type="number"
                            inputProps={{
                                onKeyPress: handleKeyPress,
                                min: "0"
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={watchlistLimit}
                            onChange={(event) => handleWatchlistLimit(event)}
                        />
                    }
                    {heading == 'Set trade limit' &&
                        <TextField
                            id="outlined-number"
                            label="Trade limit"
                            type="number"
                            InputLabelProps={{
                                shrink: true,

                            }}
                            inputProps={{
                                onKeyPress: handleKeyPress,
                                min: "0"
                            }}
                            variant="outlined"
                            value={tradeLimit}
                            onChange={(event) => handleTradeLimit(event)}
                        />
                    }
                    {heading == 'Stocks per watchlist' &&
                        <TextField
                            id="outlined-number"
                            label="Stocks"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                onKeyPress: handleKeyPress,
                                min: "0"
                            }}
                            variant="outlined"
                            value={stocksLimit}
                            onChange={(event) => handleStocksLimit(event)}
                        />
                    }
                    {heading == 'Set amount limit' &&
                        <TextField
                            id="outlined-number"
                            label="Amount"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                onKeyPress: handleKeyPress,
                                min: "0"
                            }}
                            variant="outlined"
                            value={minutes}
                            onChange={(event) => handleAmountChange(event)}
                        />
                    }
                </Grid>
                <Grid item>
                    {heading == 'Market frequency' &&
                        <Button variant="contained" color="primary" onClick={handleAddMinute}>
                            Save
                        </Button>}
                    {
                        heading == 'Watchlist per user' &&
                        <Button variant="contained" color="primary" onClick={handleUpdateWatchlistLimit}>
                            Update
                        </Button>}
                    {heading == 'Set amount limit' &&
                        <Button variant="contained" color="primary" onClick={handleDefaultAmount}>
                            Update
                        </Button>
                    }
                    {heading == 'Set trade limit' &&
                        <Button variant="contained" color="primary" onClick={handleUpdateTradeLimit}>
                            Update
                        </Button>
                    }
                    {heading == 'Stocks per watchlist' &&
                        <Button variant="contained" color="primary" onClick={handleUpdateStocksLimit}>
                            Update
                        </Button>
                    }

                </Grid>
            </Grid>
        </Box>
    )
}

export default PercentageEditable