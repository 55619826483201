import { Box, Button, Input, Stack, TextareaAutosize, Typography } from '@mui/material'
import React, { useState } from 'react'
import { style } from '../../../util/themes/styles'
import { Form, Formik } from 'formik'
import ColorPalette from './ColorPalette'
import { CreateCategoryContentList } from '../../../api/content'
import { useNotification } from '../../../util/NotificationContext'
import { IToastTypes } from '../../../components/toast/Toast'
import useDeviceType from '../../../util/useDeviceType'

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]:{
                minWidth:'100%'
            }
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        },
        '& .MuiPaper-root': {
            backgroundColor: '#271A59'
        },
        '& .MuiOutlinedInput-root .MuiSelect-select': {
            '-webkit-box-shadow': 'none',
            caretColor: 'transparent'
        },
        '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            backgroundColor: '#271A59'
        },
        '& textarea ': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            fontSize: '18px',
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]: {
                minWidth: '100%',
                fontSize: '12px',
            }
        },
        [style.breakpoints.down('md')]: {
            fontSize: '12px'
        }
    },
    root: {
        '& .MuiInputBase-root': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]:{
                minWidth:'100%'
            }
        },
        '& textarea ': {
            background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
            fontSize: '18px',
            border: 'none',
            borderRadius: '8px',
            minWidth: '350px',
            padding: '16px',
            [style.breakpoints.down('md')]: {
                minWidth: '100%',
                fontSize: '12px',
            }
        },
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    addRecordButton: {
        background: "linear-gradient(180deg, #CDC3F1 0%, #927EE2 0.01%, #422D96 100%)",
        padding: '10px',
        // margin: '10px',
        width: '104px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        }
    },
}

interface IModalTextContent {
    handleClose?: any;
    listId?: any;
}
const ModalTextContent = (props: IModalTextContent) => {
    const { handleClose, listId } = props;
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const { setNotify } = useNotification();
    const {isMobile} = useDeviceType();
    const handleMessage = (e: any) => { }

    const handleSubmit = () => { }
    const validateForm = () => { }
    const handleLoginFormAction = async (values: any) => {
        console.log("Values==>", values);
        let formData = new FormData();

        formData.append('typename', 'contents');
        formData.append('categoryNames', values.name);
        formData.append('image', values.image);
        formData.append('description', values.description);
        formData.append('listId', listId);
        formData.append('color', values.color);
        await CreateCategoryContentList(formData)
            .then((res) => {
                if (res.status === 200) {
                    console.log("res==>", res?.data?.data);
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                    handleClose()
                }
            })
        handleClose();
    }

    const handleColorSelect = (color: string, setFieldValue: any) => {
        setSelectedColor(color);
        setFieldValue("color", color)
    };

    const handleChangeImage = (e: any, setFieldValue: any) => {
        const file = e.target.files[0];
        console.log("file==>", e.target.files);
        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            console.log("extension==>",extension);
            if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
                setFieldValue("image", file)
            }
            
        }
    }

    return (
        <Box width={isMobile?'300px':'500px'}>
            <Box sx={styles.loginForm}>
                <Formik
                    initialValues={{
                        name: "",
                        image: "",
                        color: '',
                        description: '',
                    }}
                    validate={validateForm}
                    onSubmit={handleLoginFormAction}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting,
                    }) => {
                        return (
                            <Form
                                name="loginForm"
                                className="loginForm"
                            >
                                <div className="formik-field_wrap row auth_form pb-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        Name
                                        <Input
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            id="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="form-control name"
                                            autoComplete="off"
                                        />
                                        {errors.name && typeof errors.name === 'string' && touched.name ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.name}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formik-field_wrap row auth_form">
                                    <div className="formik-field-left col col-12 auth_field d-flex flex-column">
                                        Add Image
                                        <Input
                                            type="file"
                                            id="image"
                                            onChange={(e: any) => handleChangeImage(e, setFieldValue)}
                                        />
                                        {errors.image && typeof errors.image === 'string' && touched.image ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.image}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        <ColorPalette
                                            colors={['#663B94', '#844DA3', '#431C76', '#221C69', `linear-gradient(180deg, #5E3A66 -30.83%, #36314B 211.65%)`, `linear-gradient(180deg, #635B84 0%, #3D3462 100%)`, `linear-gradient(180deg, #635792 -30.83%, #573861 211.65%)`, ` linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)`]}
                                            onColorSelect={(e: any) => handleColorSelect(e, setFieldValue)}
                                        />
                                        {errors.color && typeof errors.color === 'string' && touched.color ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.color}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formik-field_wrap row auth_form mt-3">
                                    <div className="formik-field-left col col-12 auth_field">
                                        <TextareaAutosize
                                            style={{ width: '100%' }}
                                            id='description'
                                            color="neutral"
                                            minRows={8}
                                            placeholder='Modal text description...'
                                            onChange={handleChange}
                                        />

                                        {errors.description && typeof errors.description === 'string' && touched.description ? (
                                            <p
                                                style={{
                                                    display: "flex",
                                                    color: "#dc2626",
                                                    fontSize: "14px",
                                                    lineHeight: '18px',
                                                    marginLeft: "12px",
                                                }}
                                            >
                                                {errors.description}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group mt-3" style={styles.formGroup}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        sx={styles.addRecordButton}>
                                        <Typography>Submit</Typography>
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    )
}

export default ModalTextContent