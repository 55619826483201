import { useMediaQuery } from '@mui/material';
import { style } from './themes/styles';

const useDeviceType = () => {
    const isMobile =useMediaQuery(style.breakpoints.down('md'));
    const isWeb = useMediaQuery(style.breakpoints.up('lg'));
    const isTablet = useMediaQuery(style.breakpoints.between('md','lg'));

    return {isMobile:isMobile,isWeb:isWeb,isTablet:isTablet} as any;
}

export default useDeviceType