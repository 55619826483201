import { Box, Button, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    GridRowModesModel,
    GridColDef,
    GridActionsCellItem,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { AddNewOrder } from './components/AddNewOrder';
import { UpdateModal } from '../../components/UpdateModal/UpdateModal';
import { ConfirmDelete } from '../../components/dataGridTable/components/ConfirmDelete';
import addIcon from "../../assets/images/addIcon.png";
import editIcon from "../../assets/images/editIcon.png";
import deleteIcon from "../../assets/images/deleteIcon.png";
import { AddCategory } from './components/AddCategory';
import { TextSelect } from '../../components/textSelect/TextSelect';
import useDeviceType from '../../util/useDeviceType';
import { style } from '../../util/themes/styles';
import { AdddStock, getDiscoverList } from '../../api/discover';
import EditDeleteList from './components/EditDeleteList';
import EditDeleteCategory from './components/EditDeleteCategory';
import { AddStock } from './components/AddStock';
import { useNotification } from '../../util/NotificationContext';
import { IToastTypes } from '../../components/toast/Toast';
import HeaderComponent from '../../components/HeaderComponent';


const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px ',
        [style.breakpoints.down('md')]: {
            padding: '4px ',
        }
    },
    pageHeader: {
        display: 'flex',

        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        'select': {
            borderRadius: '8px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            border: 'none',
            background: ' var(--Primary-color, #5D3FD3)',
            marginRight: '10px',
            minWidth: '44px',
            padding: '4px'
        },
        'option': { padding: '10px 15px', width: 'auto', height: '20px' },
        '& .MuiSelect-select .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input .css-1eej7l2': {
            padding: '0px',
        },
        '& .css-1eej7l2': {
            padding: '0px',
        },
        '& .MuiTypography-root': {
            color: '#ffffff',
            fontFamily: 'Roboto',
            textTransform: 'capitalize',
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '8px',
                fontWeight: 400,
            },
            '& .css-1x16m3r': {
                top: -9,
            },
            padding: '10px 0px',
            fontWeight: 400,
            fontSize: '14px',
            overflowX: 'scroll'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            border: 'none',
            background: ' var(--Primary-color, #5D3FD3)',
            marginRight: '10px',
            minWidth: '44px',
            '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                textOverflow: 'inherit',
            },
            '&:hover': {
                background: ' var(--Primary-color, #5D3FD3)',
                '& .MuiTypography-root': {
                    color: style.palette.text.primary
                }
            },
            [style.breakpoints.down('md')]: {
                height: '34px',
                width: '38px',
                fontSize: '10px',
            }
        },
        '& .css-14f9imj-MuiFormLabel-root-MuiInputLabel-root': {
            top: -4,
            [style.breakpoints.down('md')]: {
                top: -8
            }
        },
        '& .css-sht1m5-MuiButtonBase-root-MuiButton-root': {
            '&:hover': {
                background: ' var(--Primary-color, #5D3FD3)',
                '& .MuiTypography-root': {
                    color: style.palette.text.primary
                }
            },
        },
        '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
            color: '#ffffff',
        },
        '& .css-sw9456-MuiFormControl-root-MuiTextField-root': {
            borderRadius: '8px',
            background: ' var(--Primary-color, #5D3FD3)',
            display: 'flex',
            height: '40px',
            padding: '0px',
            alignItems: 'center',
            marginRight: '10px',

        },
        '& .css-1yevg35-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '0px'
        },
        '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            color: '#ffffff',
        }
    },
    listBox: {
        background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '18px',
        padding: '20px  40px',
        margin: '20px',
        [style.breakpoints.down('md')]: {
            padding: '2px  13px',
            margin: '0px',
            overflow: 'scroll',
            width: '100%'
        }
    },
    tableGrid: {
        display: 'flex',
        padding: '50px 0px',
        justifyContent: 'center',
        [style.breakpoints.down('md')]: {
            padding: '30px 0px'
        }
    },
    listbtn: {
        display: 'flex',
        height: '40px',
        padding: ' 7px 16px',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '8px',
        background: 'var(--Disabled-text-color, #545454)',
        marginRight: '10px',
        [style.breakpoints.down('md')]: {
            height: '34px',
            padding: ' 3px 8px',
            minWidth: '43px',
        }
    },
    listCategory: (isSelected: boolean) => ({
        display: 'flex',
        height: '40px',
        padding: ' 7px 16px',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '8px',
        background: isSelected ? ' #5D3FD3' : 'var(--Disabled-text-color, #545454)',
        marginRight: '10px',
        '&:hover': {
            background: ' var(--Primary-color, #5D3FD3)',
        },
        [style.breakpoints.down('md')]: {
            height: '34px',
            padding: ' 3px 8px',
            minWidth: '43px',
        }
    }),
}


export const DiscoveryPage = ({ handleDrawer }: any) => {
    const [discoverPageState,setDiscoverPageState] = useState({

    })
    const [rowsData, setRowsData] = useState([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [openModal, setOpenModal] = useState(false);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [buttonText, setButtonText] = useState('Save');
    const [modalHeading, setModalHeading] = useState('Add New');
    const [open, setOpen] = useState(false);

    const [discoverList, setDiscoverList] = useState([]);
    const { isMobile } = useDeviceType();
    const [listName, setListName] = useState<any>([]);
    const [list, setList] = useState<any>();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [listData, setListData] = useState([]);
    const [dataFor, setDataFor] = useState('');
    const [editCategoryModal, setEditCategoryModal] = useState(false)
    const [openAddStockModal, setOpenAddStockModal] = useState(false);
    const [stockItemId, setstockItemIds] = useState<any>([]);
    const [stockIdForDelete, setStockIdForDelete] = useState(0);
    const [updatedStockList, setUpdatedStockList] = useState([]);
    const { setNotify } = useNotification();
    const [defaultValue, setDefaultValue] = useState();
    const [isLoding,setIsLoading] = useState(false);

    console.log("list", list);
    console.log("stockItemId", stockItemId);


    const CustomImageCell = (imageUrl: any) => {
        return <img src={imageUrl.data} alt='Icon' height="40px" width="40px" />
    }

    const handleEditClick = () => {
        setDataFor('edit')
        setEditModalOpen(true);
        setListData(listName)
    };

    const handleDeleteListClick = () => {
        setDataFor('delete')
        setEditModalOpen(true);
        setListData(listName)
    }

    const handleDeleteClick = (id: any) => () => {
        setOpen(true);
        setStockIdForDelete(id)
    };

    const getActions = (param: GridRenderCellParams) => {

        return [
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(param?.row._id)}
                color="inherit"
            />,
        ];
    }


    const idColumn: GridColDef = {
        field: "id",
        headerName: "ID",
        minWidth: 100,
        width: 100,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const logoColumn: GridColDef = {
        field: "image",
        headerName: "Logo",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => (
            <CustomImageCell data={cellValues.value} />
        ),
    };

    const stockColumn: GridColDef = {
        field: "shortName",
        headerName: "Stock Name",
        minWidth: 100,
        width: 250,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const srColumn: GridColDef = {
        field: "sr",
        headerName: "SR Name",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const closingPriceColumn: GridColDef = {
        field: "pl_percentage",
        headerName: "Pl Percentage",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const currentPriceColumn: GridColDef = {
        field: "current_price",
        headerName: "Current Price",
        minWidth: 100,
        width: 200,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const industryColumn: GridColDef = {
        field: "industry",
        headerName: "Industry",
        minWidth: 100,
        width: 150,
        maxWidth: 350,
        editable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: (cellValues) => cellValues.value,
    };

    const actionColumn: GridColDef = {
        field: "action",
        headerName: "Actions",
        minWidth: 100,
        width: 300,
        maxWidth: 350,
        flex: 1,
        renderCell: getActions,
        resizable: false,
    };

    const columns: GridColDef[] = [];
    columns.push(idColumn);
    columns.push(logoColumn);
    columns.push(stockColumn);
    columns.push(srColumn);
    columns.push(industryColumn);
    columns.push(closingPriceColumn);
    columns.push(currentPriceColumn);
    columns.push(actionColumn);

    const handleAddList = () => {
        setOpenModal(true);
    }

    const handleAddCategory = () => {
        setOpenCategoryModal(true)

    }

    const handleEditCategory = () => {
        setDataFor('edit')
        setEditCategoryModal(true);
    }

    const handleDeleteCategory = () => {
        setDataFor('delete')
        setEditCategoryModal(true);
    }

    const handleClose = (data?:any) => {
        setListName((prev: any)=>{
            return [...prev,data]
        })
        setOpenModal(false);
    }

    const handleCloseCategory = (data: any) => {
        if (data?.length) {
            const index = listName.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...listName];
            console.log("newArray[ListIndex]", newArray[ListIndex]);
            newArray[ListIndex].categories = data;
            setCategories(data);
        }
        setOpenCategoryModal(false);
    }

    const handleCloseEdit = (data:any) => {
        console.log("data",data);
        if(data?.length){
            setListName(
                data.map((item: any, index: any) => {
                    return item
                })
            );
        }
      
        setEditModalOpen(false);
    }

    const handleCloseEditCategory = (data: any) => {
        console.log("data==>", data);

        if (data?.length) {
            const index = listName.map((item: any) => item._id === list?._id)
            const ListIndex = index.findIndex((item: any) => item !== -1);
            const newArray: any = [...listName];
            newArray[ListIndex].categories = data;
            setCategories(data);
        }
        setEditCategoryModal(false);
    }

    const handleCloseAddStockModal = (data: any) => {
        const index = listName.map((list: any) => list?.categories?.findIndex((item: any) => item._id === selectedCategory))
        const ListIndex = index.findIndex((item: any) => item !== -1);
        const CategoryIndex = index.filter((item: any) => item !== -1);
        const newArray = [...listName];
        const newCategory = [...newArray[ListIndex].categories];
        newCategory[CategoryIndex[0]].stockIds = data;
        newArray[ListIndex].categories = newCategory
        setListName(newArray);
        setstockItemIds([]);
        setOpenAddStockModal(false);
        
    }

    const handleCloseDelete = () => {
        setOpen(false);
    }

    const handleChangeList = (e?: any) => {
        const index: any = listName.filter((item: any) => item._id === e.target.value)
        setList(index[0]);
        setCategories(index[0].categories);
        setRowsData([]);
    }

    const handleCategory = (item: any) => {
        console.log("item=>",item);
        
        // setstockItemIds([]);
        setSelectedCategory(item?._id)
        if (item?.stockIds?.length > 0) {
            setRowsData(
                item?.stockIds.map((stock: any, index: any) => {
                    // setstockItemIds((prevSelected: any) => {
                    //     if (prevSelected?.includes(stock._id)) {
                    //         return prevSelected?.filter((id: any) => id !== stock._id);
                    //     } else {
                    //         return [...prevSelected, stock._id];
                    //     }
                    // });
                    return ({
                        id: index + 1,
                        _id: stock._id,
                        image: stock?.image,
                        shortName: stock?.shortName,
                        sr: stock?.sr,
                        pl_percentage: stock?.pl_percentage,
                        industry: stock?.industry,
                        current_price: stock?.current_price.toFixed(2),
                    })
                })
            );
        }else {
            setRowsData([]);
        }

    }

    const disvoverList = async () => {
        // setstockItemIds([]);
        setIsLoading(true);
        await getDiscoverList()
            .then((res) => {
                if (res.status === 200) {
                    const data = res?.data?.data;
                    console.log("Data==>", data);
                    setDiscoverList(
                        data.map((item: any) => ({
                            data: item
                        }))
                    )
                    setListName(
                        data.map((item: any, index: any) => {
                            return item
                        })
                    );
                    setList(data[0]);
                    setDefaultValue(data.length > 0 ? data[0] : '')
                    setCategories(data[0].categories);
                    setSelectedCategory(data[0].categories[0]._id);
                    setRowsData(
                        data[0].categories[0]?.stockIds.map((stock: any, index: any) => {
                            // const selectedIndex = stockItemId.findIndex((selected:any) => selected === stock._id);
                            // setstockItemIds((prevSelected: any) => {
                            //     if (!prevSelected?.includes(stock._id)) {
                            //           console.log("stock._id");
                            //         return [...prevSelected, stock._id];
                            //     } else {
                            //         console.log("stock._id==>");
                            //         return prevSelected?.filter((id: any) => id !== stock._id);
                            //     }
                            // });
                          
                            
                            return ({
                                id: index + 1,
                                _id: stock._id,
                                image: stock?.image,
                                shortName: stock?.shortName,
                                sr: stock?.sr,
                                pl_percentage: stock?.pl_percentage,
                                industry: stock?.industry,
                                current_price: stock?.current_price.toFixed(2),
                            })
                        })
                    );
                    setIsLoading(false);
                }
            })
    }

    const handleOpenAddStockModal = () =>{
        rowsData.map((stock:any)=>{
            setstockItemIds((prevSelected: any) => {
                if (!prevSelected?.includes(stock._id)) {
                      console.log("stock._id");
                    return [...prevSelected, stock._id];
                } else {
                    console.log("stock._id==>");
                    return prevSelected?.filter((id: any) => id !== stock._id);
                }
            });
        });
        setOpenAddStockModal(true)
    }

    const handleDelete = async () => {
        setOpen(false);
        const deletedStock = stockItemId.filter((item: any) => item !== stockIdForDelete);
        setstockItemIds((prevItems: any) => prevItems.filter((item: any) => item !== stockIdForDelete));
        await AdddStock(deletedStock, selectedCategory)
            .then((res) => {
                if (res.status === 200) {
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });

                    handleClose();
                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
    }

    useEffect(() => {
        disvoverList();
    }, [])

    return (
        <Box sx={styles.root} >
            <HeaderComponent handleDrawer={handleDrawer} heading={'Discover'} />
            <Box sx={styles.pageHeader} className='customScroll'>
                {/* <TextSelect
                    id="categories"
                    value={list}
                    label='Select'
                    defaultValue={defaultValue}
                    onChange={(event: any) => handleChangeList(event)}
                    options={listName}
                    style={{ width: isMobile ? '113px' : 'auto', marginRight: '4px', minWidth: '113px' }}
                    inputStyleProps={{ padding: isMobile ? '0px' : '10px 15px', width: isMobile ? '100%' : 'auto' }}
                /> */}
                <select
                    className="selectpicker"
                    // data-style="btn-primary" 
                    onChange={(event: any) => handleChangeList(event)}>
                    {listName?.map((item: any) => {
                        return (

                            <option className="special" value={item?._id}>
                                <div style={{ padding: "10px 5px" }}>
                                    {item?.name}
                                </div>
                            </option>)
                    })}

                </select>
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleAddList}
                    sx={styles.listbtn}>
                    <img src={addIcon} alt='add' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    onClick={handleEditClick}
                    sx={styles.listbtn}>
                    <img src={editIcon} alt='edit' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleDeleteListClick}
                    sx={styles.listbtn}>
                    <img src={deleteIcon} alt='delete' height={'12px'} width={'12px'} />
                </Button>
            </Box>
            <Box sx={styles.pageHeader} className='customScroll'>
                {categories.length > 0 &&
                    categories?.map((item: any) => {
                        const isSelected = selectedCategory === item?._id;
                        return (
                            <Button
                                type="submit"
                                color="primary"
                                onClick={() => handleCategory(item)}
                                sx={styles.listCategory(isSelected)}>
                                <Typography>{item?.name}</Typography>
                            </Button>
                        )
                    })
                }
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleAddCategory}
                    sx={styles.listbtn}>
                    <img src={addIcon} alt='add' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleEditCategory}
                    sx={styles.listbtn}>
                    <img src={editIcon} alt='edit' height={'12px'} width={'12px'} />
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={handleDeleteCategory}
                    sx={styles.listbtn}>
                    <img src={deleteIcon} alt='delete' height={'12px'} width={'12px'} />
                </Button>
            </Box>
            <Box sx={styles.listBox} >
                <Box sx={styles.tableGrid}>
                    <DataGridTable
                        rowsData={rowsData}
                        columns={columns}
                        setRows={setRowsData}
                        setRowModesModel={setRowModesModel}
                        addStock
                        setOpenModal={setOpenModal}
                        setModalHeading={setModalHeading}
                        setButtonText={setButtonText}
                        setOpenAddStockModal={handleOpenAddStockModal}
                        loading={isLoding}
                    />
                </Box>
            </Box>
            {openModal &&
                <UpdateModal isOpenModal={openModal} handleClose={handleClose}>
                    <AddNewOrder buttonText={buttonText} modalHeading={modalHeading} handleClose={handleClose} />
                </UpdateModal>
            }
            {openCategoryModal &&
                <UpdateModal isOpenModal={openCategoryModal} handleClose={handleCloseCategory}>
                    <AddCategory buttonText={buttonText} listName={list} modalHeading={modalHeading} handleClose={handleCloseCategory} />
                </UpdateModal>
            }
            {editModalOpen &&
                <UpdateModal isOpenModal={editModalOpen} handleClose={handleCloseEdit}>
                    <EditDeleteList listData={listData} handleClose={handleCloseEdit} dataFor={dataFor} />
                </UpdateModal>
            }
            {editCategoryModal &&
                <UpdateModal isOpenModal={editCategoryModal} handleClose={handleCloseEditCategory}>
                    <EditDeleteCategory listData={categories} listName={list} handleClose={handleCloseEditCategory} dataFor={dataFor} />
                </UpdateModal>
            }
            {openAddStockModal &&
                <UpdateModal isOpenModal={openAddStockModal} handleClose={handleCloseAddStockModal}>
                    <AddStock setRowsDataUpdated={setRowsData} selectedCategory={selectedCategory} handleClose={handleCloseAddStockModal} stockItemId={stockItemId} setstockItemIds={setstockItemIds} setUpdatedStockList={setUpdatedStockList} />
                </UpdateModal>
            }
            {open &&
                <ConfirmDelete open={open} handleCloseDelete={handleCloseDelete} handleDelete={handleDelete} />
            }
        </Box>
    )
}
