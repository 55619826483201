import axios from 'axios'
import { GET_NOTIFICATION_CATEGORY, GET_NOTIFICATION_HISTORY, SEND_NOTIFICATION } from '../constant/common';
import _ from 'lodash';

export const SendNotification = async (category: any, message: any) => {
    console.log("category", category);
    console.log("message", message);

    const transformedString = _.startCase(category);
    try {
        return await axios.post(`${SEND_NOTIFICATION}`, {
            notificationType: transformedString,
            title: transformedString,
            message: message,
        });
    }
    catch (error) {
        throw error;
    }
}

export const NotificationTypeList = async () => {
    try {
        return await axios.get(`${GET_NOTIFICATION_CATEGORY}`,
        );
    }
    catch (error) {
        throw error;
    }
}

export const NotificationHistory = async() =>{
    try {
        return await axios.get(`${GET_NOTIFICATION_HISTORY}`,
        );
    }
    catch (error) {
        throw error;
    }
}
