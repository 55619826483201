import { Box, FormControlLabel, Grid, List, ListItem, ListItemText, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { style } from '../../util/themes/styles';
import { FetchSwitchList, UpdateSwitch } from '../../api/switch';
import HeaderComponent from '../../components/HeaderComponent';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px ',
        [style.breakpoints.down('md')]: {
            padding: '4px ',
        }
    },
    pageHeader: {
        display: 'flex',
        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        '& .MuiTypography-root': {
            color: '#ffffff'
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '18px',
                fontWeight: 500,
            }
        }
    },
    listBox: {
        background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

        display: 'flex',
        flexDirection: 'column',
        borderRadius: '18px',
        padding: '40px',
        margin: '20px',
        '& .css-b3w89n-MuiButtonBase-root-MuiTab-root.Mui-selected': {
            color: '#7D65DC',
            background: '#5D3FD380',
        },
        '& .css-1fmutb9-MuiTabs-indicator': {
            backgroundColor: "#7D65DC",
        },
        '& .css-b3w89n-MuiButtonBase-root-MuiTab-root': {
            textTransform: 'capitalize',
            fontSize: '18px',
        },
        '& .MuiTypography-root h6': {
            fontWeight: 400
        },
        '& .css-15typgz-MuiButtonBase-root-MuiChip-root': {
            height: '40px',
            width: '140px',
        },
        [style.breakpoints.down('md')]: {
            '& .css-b3w89n-MuiButtonBase-root-MuiTab-root': {
                fontSize: '10px',
            },
            '& .MuiTypography-root h6': {
                fontSize: '14px',
            },
            padding: '10px 10px',
            margin: '4px',
        }
    },
    tableGrid: {
        display: 'flex',
        padding: '50px 0px',
        justifyContent: 'center',
        '& .MuiListItem-root': {
            borderRadius: '8px',
            marginTop: '20px',
            padding: '12px',
            background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))',

        },
        '& .css-3jgaev':{
            borderRadius: '8px',
            marginTop: '20px',
            padding: '12px',
            background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))',
        },
        
        [style.breakpoints.down('md')]: {
            padding: '0px',
            '& .css-3jgaev':{
                borderRadius: '8px',
                marginTop: '20px',
                padding: '12px',
                background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))',
            }
        }
    },
    question: {
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root h6': {
                fontSize: '10px'
            }
        }
    },
    answer: {
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root h6': {
                fontSize: '8px'
            }
        }
    },
    queans: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingLeft: '12px',
        [style.breakpoints.down('md')]: {
            flexDirection: 'column',
            flexWrap: 'wrap',
            paddingLeft: '0px',
            '& .css-zqq9ev-MuiGrid-root': {
                flexDirection: 'column',
                flexWrap: 'wrap',
            }
        }
    },
    listAlign: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}

const SwitchList = ({handleDrawer}:any) => {
    const [switchOptions, setSwitchOptions] = useState({
        balanceSheet: false,
        cashFlow: false,
        currencySettings: false,
        incomeStatement: false,
        positionsByAssetClass: false,
        shariaScore: false,
        tradeExecution: false,

    });

    const handleChange = (data: any) => {
        const updatedItems = {...switchOptions,...data};
        
        setSwitchOptions((prev) => {
            return {
                ...prev,
                ...data
            }
        })
        updateSwitch(updatedItems);
    }

    const getSwitchOptions = async () => {
        await FetchSwitchList()
            .then((res) => {
                if (res.status === 200) {
                    setSwitchOptions(res?.data?.data[0]);
                }
            })
    }

    const updateSwitch = async(updatedItems:any) =>{
        await UpdateSwitch(updatedItems)
        .then((res)=>{
            if(res.status === 200){
                console.log("res==>",res);
            }
        })
    }

    useEffect(() => {
        getSwitchOptions();
    }, [])



    return (
        <Box sx={styles.root}>
            <HeaderComponent  handleDrawer={handleDrawer} heading={'Switch List'}/>
            <Box sx={styles.listBox}>
                <Box sx={styles.tableGrid}>
                    <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Balance Sheet"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.balanceSheet}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                balanceSheet: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Cash Flow"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.cashFlow}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                cashFlow: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Currency Settings"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.currencySettings}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                currencySettings: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Income Statement"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.incomeStatement}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                incomeStatement: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Positions By Asset Class"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.positionsByAssetClass}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                positionsByAssetClass: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Sharia Score"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.shariaScore}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                shariaScore: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                <Grid width={'80%'}>
                                    <ListItemText primary={
                                        <Box sx={styles.queans}>
                                            <Typography variant='h6' sx={styles.question}>{"Trade Execution"}</Typography>
                                        </Box>
                                    } />
                                </Grid>
                                <Grid>
                                    <FormControlLabel
                                        control={<Switch checked={switchOptions?.tradeExecution}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleChange({
                                                tradeExecution: checked
                                            })}
                                        />} label="" />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
    )
}

export default SwitchList