import { Box, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { style } from '../../../util/themes/styles';
import useDeviceType from '../../../util/useDeviceType';
import HeaderComponent from '../../../components/HeaderComponent';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px ',
        [style.breakpoints.down('md')]: {
            padding: '4px ',
        }
    },
    pageHeader: {
        display: 'flex',
        padding: '10px 24px',
        fontSize: '24px',
        fontWeight: 600,
        '& .MuiTypography-root': {
            color: '#ffffff'
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '18px',
                fontWeight: 500,
            }
        }
    },
    listBox: {
        background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

        display: 'flex',
        flexDirection: 'column',
        borderRadius: '18px',
        padding: '20px  0px',
        margin: '20px',
        '& .css-b3w89n-MuiButtonBase-root-MuiTab-root.Mui-selected': {
            color: '#7D65DC',
            background: '#5D3FD380',
        },
        '& .css-1fmutb9-MuiTabs-indicator': {
            backgroundColor: "#7D65DC",
        },
        '& .css-b3w89n-MuiButtonBase-root-MuiTab-root': {
            textTransform: 'capitalize',
            fontSize: '18px',
        },
        '& .MuiTypography-root h6': {
            fontWeight: 400,
        },
        '& .css-15typgz-MuiButtonBase-root-MuiChip-root': {
            height: '40px',
            width: '140px',
        },
        '& MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-1fj84c5-MuiTypography-root' :{

        },
        [style.breakpoints.down('md')]: {
            '& .css-b3w89n-MuiButtonBase-root-MuiTab-root': {
                fontSize: '12px',
            },
            '& .MuiTypography-root h6': {
                fontWeight: 400,
                fontSize: '14px',
            },
            // height: '100vh'
            margin: '2px',
        }
    },
    tableGrid: {
        display: 'flex',
        padding: '50px 0px',
        justifyContent: 'center'
    },
    question: {
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root h6': {
                fontSize: '10px'
            }
        }
    },
    answer: {
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root h6': {
                fontSize: '8px'
            }
        }
    },
    queans: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        [style.breakpoints.down('md')]: {
            flexDirection: 'column',
            flexWrap: 'wrap',
            '& .css-zqq9ev-MuiGrid-root': {
                flexDirection: 'column',
                flexWrap: 'wrap',
            }
        }
    }
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const UserDetails = ({handleDrawer}:any) => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const PersonDetails = location?.state?.data?.personalAndInvestment[0];
    const InvestmentDetails = location?.state?.data?.personalAndInvestment[1];
    const [userDetailType, setUserTypeDetails] = useState(PersonDetails);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (newValue === 1) {
            setUserTypeDetails(InvestmentDetails);
        }
    };



    return (
        <Box sx={styles.root}>
            <HeaderComponent  handleDrawer={handleDrawer} heading={'User Details'}/>
            <Box sx={styles.listBox}>
                <Box
                    sx={{ display: 'flex', bgcolor: 'transparent',justifyContent: 'center' }}
                >
                    {PersonDetails !== undefined ? <>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Personal Details" {...a11yProps(0)} />
                            <Tab label="Investment Details" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                                {PersonDetails?.questions.map((item: any, index: any) => {
                                    return (
                                        <ListItem
                                            key={value}
                                            disableGutters
                                        >
                                            <Grid container sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                                <Grid item xs={12} sm={12} md={10}>
                                                    <ListItemText primary={
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <Typography variant='h6'>{item?.questionId === PersonDetails.titleQuestion[index]._id && PersonDetails.titleQuestion[index]?.question}</Typography>
                                                            <Typography variant='h6' marginRight={'8px'}>:</Typography>
                                                        </Box>
                                                    } />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={2}>
                                                    <ListItemText primary={item?.answer[0]} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                            {InvestmentDetails?.questions.map((item: any, index: any) => {
                                    return (
                                        <ListItem
                                            key={value}
                                            disableGutters
                                        >
                                            <Grid container sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                                <Grid item xs={12} sm={12} md={10}>
                                                    <ListItemText primary={
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <Typography variant='h6'>{item?.questionId === InvestmentDetails.titleQuestion[index]._id && InvestmentDetails.titleQuestion[index]?.question}</Typography>
                                                            <Typography variant='h6' marginRight={'8px'}>:</Typography>
                                                        </Box>
                                                    } />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={2}>
                                                    <ListItemText primary={item?.answer[0]} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </TabPanel>
                    </> :
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='h6'>Data Not Found</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Box >
    )
}

