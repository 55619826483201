import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import * as feature from '../../pages';
import ProtectedRoute from "../../components/ProtectedRoute";
import SwitchList from "../../pages/switchList/switchList";

export const AppRoutes = ({handleDrawer}:any) => {
  const location = useLocation();
  const auth = localStorage.getItem("Auth_Token");
  console.log("auth==>", auth);


  return (

    <Routes>
      <Route path='/' element={<Navigate to={`/home`} replace state={{ from: location.pathname }} />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <feature.HomePage handleDrawer={handleDrawer} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/list-management"
        element={
          <feature.ListManagement handleDrawer={handleDrawer}/>
        }
      />
      <Route
        path="/discover"
        element={
            <feature.DiscoveryPage handleDrawer={handleDrawer} />
        }
      />
       <Route
        path="/content-type"
        element={
            <feature.ContentTypePage  handleDrawer={handleDrawer} />
        }
      />
      <Route
        path="/user-management"
        element={
          <feature.UserManagement handleDrawer={handleDrawer} />
        }
      />
      <Route
        path="/user-details/:id"
        element={
          <feature.UserDetails handleDrawer={handleDrawer} />
        }
      />
      <Route
        path="/feedback"
        element={
          <feature.Feedback handleDrawer={handleDrawer} />
        }
      />
      <Route
        path="/switch-list"
        element={
          <SwitchList handleDrawer={handleDrawer}/>
        }
      />
      <Route
        path="/notification"
        element={
          <feature.Notification handleDrawer={handleDrawer} />
        }
      />
      <Route
        path="/waitlist"
        element={
          <feature.Waitlist handleDrawer={handleDrawer}/>
        }
      />
    </Routes>

  )
}