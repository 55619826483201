import { Box, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModesModel,
  GridRowModes,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowEditStopReasons,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DataGridTable } from '../../components/dataGridTable/DataGridTable';
import { DataGridSearch } from '../../components/dataGridTable/components/DataGridSearch';
import { UpdateModal } from '../../components/UpdateModal/UpdateModal';
import { AddNewRecord } from './components/AddNewRecord';
import { ConfirmDelete } from '../../components/dataGridTable/components/ConfirmDelete';
import { useNavigate } from 'react-router-dom';
import { DeleteUser, FetchApprovedUsers } from '../../api/users';
import { IToastTypes } from '../../components/toast/Toast';
import { useNotification } from '../../util/NotificationContext';
import moment from 'moment';
import { style } from '../../util/themes/styles';
import useDeviceType from '../../util/useDeviceType';
import HeaderComponent from '../../components/HeaderComponent';


const styles = {
  root:{
    display:'flex',
    flexDirection:'column',
    padding:'24px ',
    [style.breakpoints.down('md')]:{
      padding:'4px ',
    }
  },
  pageHeader: {
    display: 'flex',
    padding: '10px 24px',
    fontSize: '24px',
    fontWeight: 600,
    '& .MuiTypography-root': {
      color: '#ffffff'
    },
    [style.breakpoints.down('md')]:{
    '& .MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 500,
    }
    }
  },
  listBox: {
    background: "linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%)",

    display: 'flex',
    flexDirection: 'column',
    borderRadius: '18px',
    padding: '20px  40px',
    margin: '20px',
    [style.breakpoints.down('md')]:{
      padding: '2px  13px',
      margin: '0px',
    }
  },
  tableGrid: {
    display: 'flex',
    padding: '50px 0px',
    justifyContent: 'center',
    [style.breakpoints.down('md')]:{
      padding:'30px 0px'
    }
  }
}
export const UserManagement = ({handleDrawer}:any) => {
  const [rowsData, setRowsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [openModal, setOpenModal] = useState(false);
  const [buttonText, setButtonText] = useState('Save');
  const [modalHeading, setModalHeading] = useState('Add New');
  const [open, setOpen] = useState(false);
  const [usersPageData, setUsersPageData] = useState({
    usersData: [],
    userIdForDelete: '',
    isUserDeleted: false,
    setUpdateUserDetail: {},
    loading: false,
  })
  const { isMobile } = useDeviceType();
  
  const navigate = useNavigate();
  const { setNotify } = useNotification();

  const handleEditClick = (data: any) => () => {
    setButtonText('Update');
    setUsersPageData((prev) => {
      return {
        ...prev,
        setUpdateUserDetail: data,
      }
    })
    setModalHeading('Update');
    setOpenModal(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpen(true);
    setUsersPageData((prev: any) => {
      return {
        ...prev,
        userIdForDelete: id
      }
    })
  };

  const handleDelete = async () => {
    setOpen(false);
    await DeleteUser(usersPageData?.userIdForDelete)
      .then((res) => {
        if (res.status === 200) {
          setUsersPageData((prev) => {
            return {
              ...prev,
              isUserDeleted: !usersPageData.isUserDeleted,
            }
          })
          setNotify({
            open: true,
            type: IToastTypes.Success,
            message: res?.data?.message,
          });
        }
      })

  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleDetails = (data: any) => () => {
    navigate(`/user-details/${data?.id}`, { state: { data: data } });
  }

  const getActions = (params: GridRenderCellParams) => {
    const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(params.row.id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(params.row.id)}
          color="inherit"
        />,

      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(params.row)}
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(params.row._id)}
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<VisibilityIcon />}
        label="View"
        onClick={handleDetails(params.row)}
        color="inherit"
      />,
    ];
  }

  // columns

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width:isMobile?90:150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };
  const firstNameColumn: GridColDef = {
    field: "firstName",
    headerName: "First Name",
   width:isMobile?90:150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const lastNameColumn: GridColDef = {
    field: "lastName",
    headerName: "Last Name",
   width:isMobile?90:150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const mobileNoColumn: GridColDef = {
    field: "phoneNumber",
    headerName: "Phone Number",
   width:isMobile?90:150,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const emailColumn: GridColDef = {
    field: "email",
    headerName: "Email",
   width:isMobile?90:300,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const createdAtColumn: GridColDef = {
    field: "createdAt",
    headerName: "Created At",
    width: 250,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const updatedAtColumn: GridColDef = {
    field: "updatedAt",
    headerName: "Updated At",
    width: 250,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cellValues) => cellValues.value,
  };

  const actionColumn: GridColDef = {
    field: "action",
    headerName: "Actions",
    flex: 1,
    minWidth: 100,
    width:300,
    maxWidth: 350,
    renderCell: getActions,
    resizable: false,
  };

  const columns: GridColDef[] = [];
  columns.push(idColumn);
  columns.push(firstNameColumn);
  columns.push(lastNameColumn);
  columns.push(mobileNoColumn);
  columns.push(emailColumn);
  columns.push(createdAtColumn);
  columns.push(updatedAtColumn);
  columns.push(actionColumn);

  const handleData = (data: any, searchTerm: any) => {
    const filteredRows = data.filter((row: any) => {
      return row?.email?.toLowerCase()?.includes(searchTerm) || row?.firstName?.toLowerCase()?.startsWith(searchTerm);
    });
    setRowsData(filteredRows)
  }


  const handleClose = () => {
    setOpenModal(false);
    getUsers();
  }

  const handleCloseDelete = () => {
    setOpen(false);
  }

  const getUsers = async () => {
    setUsersPageData((prev) => {
      return {
        ...prev,
        loading: true,
      }
    })
    await FetchApprovedUsers()
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          // rows
          setRowsData(
            data.filter((user: any) => !user.isDeleted)
              .map((user: any, index: any) => ({
                id: index + 1,
                _id: user._id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                phoneNumber: user?.phoneNumber,
                email: user?.email,
                createdAt:moment(user?.createdAt).format('lll'),
                updatedAt:moment(user?.updatedAt).format('lll'),
                personalAndInvestment: user?.personalAndInvestment,
                titleQuestion:user?.titleQuestion,
              }))
          );
          setUsersPageData((prev) => {
            return {
              ...prev,
              usersData: res?.data?.data,
            }
          })
        }
      })
      .catch((res)=>{
        setNotify({
            open: true,
            type: IToastTypes.Error,
            message:res?.response?.data?.message,
        });
        
    })
    setUsersPageData((prev) => {
      return {
        ...prev,
        loading: false,
      }
    })
  }

  const handleIsEmpty = () => {
    getUsers();
  }



  useEffect(() => {
    getUsers();
  }, [usersPageData.isUserDeleted])


  return (
    <Box sx={styles.root}>
      <HeaderComponent  handleDrawer={handleDrawer} heading={' User Management'}/>
      <Box sx={styles.listBox}>
        <DataGridSearch rowsData={rowsData} handleData={handleData} handleIsEmpty={handleIsEmpty} />
        <Box sx={styles.tableGrid}>
          <DataGridTable
            rowsData={rowsData}
            columns={columns}
            setRows={setRowsData}
            setRowModesModel={setRowModesModel}
            addRecord
            setOpenModal={setOpenModal}
            setModalHeading={setModalHeading}
            setButtonText={setButtonText}
            loading={usersPageData?.loading}
          />
        </Box>
      </Box>
      {openModal &&
        <UpdateModal isOpenModal={openModal} handleClose={handleClose}>
          <AddNewRecord buttonText={buttonText} modalHeading={modalHeading} setUpdateUserDetail={usersPageData?.setUpdateUserDetail} handleClose={handleClose} />
        </UpdateModal>}
      {open &&
        <ConfirmDelete open={open} handleCloseDelete={handleCloseDelete} handleDelete={handleDelete} />
      }
    </Box>
  )
}
