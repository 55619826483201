import { createTheme } from '@mui/material/styles';
import { baseTheme } from './base';

const theme = createTheme(baseTheme, {
    palette: {
        primary: {
            10: '#5D3FD3',
            50: '#EFECFB',
            100: '#CDC3F1',
            200: '#B4A7EB',
            300: '#927EE2',
            400: '#7D65DC',
            500: '#5D3FD3',
            600: '#5539C0',
            700: '#422D96',
            800: '#332374',
            900: '#271A59',
          },
        black: {
            50: '#E6E6E6',
            100: '#B0B0B0',
            200: '#8A8A8A',
            300: '#545454',
            400: '#333333',
            500: '#000000',
            600: '#000000',
            700: '#000000',
            800: '#000000',
            900: '#000000',
        },
        error: {
            main: '#DC4C4B',
            contrastText: '#FEFEFE',
        },
        success: {
            main: '#34C759',
            contrastText: '#FEFEFE',
        },
        background: {
            main: '#1F252F'
        },
        pfcardbackground: {
            main: '#272D36',
        },
        loginpagebackground: {
            main: '#000000'
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#8E98A8',
            disabled: '#B9B9B9',
        },
        typography: {
           fontFamily: ['Roboto'].join(','),
           large:{
                regular: {
                    fontFamily: ['Roboto'].join(','),
                    fontWeight: 400,
                    fontSize: '34px',
                    lineHeight: '41px',
                },
                medium: {
                    fontFamily: ['Roboto'].join(','),
                    fontWeight: 500,
                    fontSize: '34px',
                    lineHeight: '41px',
                },
                bold: {
                    fontFamily: ['Roboto'].join(','),
                    fontWeight: 700,
                    fontSize: '34px',
                    lineHeight: '41px',
                }
           },
           headline: {
            fontFamily: ['Roboto'].join(','),
            fontWeight: 600,
            fontSize: '17px',
            lineHeight: '22px',
           },
           h1: {
            regular: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 400,
                fontSize: '28px',
                lineHeight: '34px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '28px',
                lineHeight: '34px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '28px',
                lineHeight: '34px',
            }
          },
          h2: {
            regular: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '28px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '22px',
                lineHeight: '28px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '22px',
                lineHeight: '28px',
            }
          },
          h3: {
            regular: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '25px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '25px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '25px',
            }
          },
          h4: {
            regular: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 400,
                fontSize: '17px',
                lineHeight: '22px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '17px',
                lineHeight: '22px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '17px',
                lineHeight: '22px',
            },
          },
          h5: {
            regular: {
                fontFamily: ['Raleway'].join(','),
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '21px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '21px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '21px',
            },
          },
          h6: {
            regular: {
                fontFamily: ['Raleway'].join(','),
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '18px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '18px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '13px',
                lineHeight: '18px',
            },
          },
          caption: {
            regular: {
                fontFamily: ['Raleway'].join(','),
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '16px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '16px',
            },
          },
          caption2: {
            regular: {
                fontFamily: ['Raleway'].join(','),
                fontWeight: 400,
                fontSize: '11px',
                lineHeight: '13px',
            },
            medium: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 500,
                fontSize: '11px',
                lineHeight: '13px',
            },
            bold: {
                fontFamily: ['Roboto'].join(','),
                fontWeight: 700,
                fontSize: '11px',
                lineHeight: '13px',
            },
          }
    },
   
}
})as any;

export { theme, theme as style };