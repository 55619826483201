import ApexCharts, { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react"
import { getCreatedUser } from "../../api/dashboard";
import useDeviceType from "../../util/useDeviceType";


export const DataChart = () => {
    const { isMobile } = useDeviceType();

    const [state, setState] = useState([{
        name: 'Users',
        data: [],
        style: {}
    }]);


    const getCreatedUserForGraph = async () => {
        await getCreatedUser()
            .then((res) => {
                if (res.status === 200) {
                    const data = res?.data?.data.map((users: any) => {
                        return users.total;
                    })

                    setState([{
                        name: 'Users',
                        data: data,
                        style: {
                            colors: ['#0D1021']
                        }
                    }])
                }
            })
    }

    useEffect(() => {
        setTimeout(getCreatedUserForGraph, 1000)
    }, []);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '14px',
                fontWeight: 500,
                colors: ['#F44336', '#E91E63', '#9C27B0']
            }
        },
        markers: {
            size: 0,
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            labels: {
                style: {
                    colors: '#fff',
                    fontSize: '14px'
                }
            }

        },
        yaxis: {
            labels: {
                style: {
                    colors: '#fff',
                    fontSize: '14px'
                },
                minWidth: 0,
                formatter: function (value) {
                    return value + '';
                },
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            },
            theme: 'dark'
        },
        fill: {
            type: 'gradient',
            colors: ['#7D65DC'], // Bar fill color
            gradient: {
                shade: 'dark',
                //   type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#B4A7EB'], // Gradient color
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            colors: ['#B4A7EB'], // Stroke color
        },


    }
    return (
        <div>
            <div id="chart" style={{ margin: isMobile ? '0px' : '16px' }}>
                <div id="chart-timeline">
                    <ReactApexChart
                        options={options}
                        series={state}
                        type="bar"
                        height={550}
                    />
                </div>
            </div>
        </div>
    )
}