import { TextField, MenuItem } from '@mui/material';
import _ from 'lodash';
import { Children } from 'react';

interface IInputProps {
    includeNoneOption?: boolean;
    required?: boolean;
    size?: 'MEDIUM' | 'SMALL';
    labelStyles?: any;
    inputStyleProps?: any;
    id: any;
    inputInlineStyle?: any;
    handleChange?: () => void;
    options?: any;
    style?: any;
    [other: string]: any;
    label?: string;
    defaultValue?:any;
    value?:any;
}

export function TextSelect(props: Readonly<IInputProps>) {
    const {
        value,
        id,
        style,
        options,
        inputStyleProps,
        onChange,
        label,
        defaultValue
    } = props;
    console.log("value",value);
    
    return (
        <TextField
            id={id}
            select
            value={value}
            defaultValue={defaultValue}
            label={label}
            placeholder={label}
            fullWidth
            onChange={onChange}
            variant="outlined"
            sx={style}
            SelectProps={{
                style: inputStyleProps,
                MenuProps: {
                    PaperProps: {
                        className: 'customScroll',
                        sx: {
                            maxHeight: '200px',
                            backgroundColor: '#545454',
                            backdropFilter: 'blur(5px)',
                            zIndex: 1,
                            color: '#fff',
                            '& .css-wydw6p .MuiOutlinedInput-root .MuiSelect-icon': {
                                color: '#fff'
                            }

                        }
                    }
                }
            }}
        >
            {Children.toArray(
                options.map((item: any) => {
                    if (typeof (item) === "string") {
                        const transformedString = _.startCase(item);
                        return (
                            <MenuItem value={item}>
                                {transformedString}
                            </MenuItem>
                        )
                    } if(item.name){
                        return (
                            <MenuItem value={item}>
                                {item.name}
                            </MenuItem>
                        )
                    } if(item.selectType){
                        return (
                            <MenuItem value={item}>
                                {item.listName}
                            </MenuItem>
                        )
                    } else {
                        return (
                            <MenuItem value={item._id ? item._id : item}>
                                {item._id ? item._id : item}
                            </MenuItem>
                        )
                    }
                })
            )}
        </TextField>
    );
}
