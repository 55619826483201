import Toast from "../../components/toast/Toast";
import { useNotification } from "../../util/NotificationContext";
import { useEffect, useState } from "react";

const AppNotification = () => {
  const { notify } = useNotification();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(notify.open);
  }, [notify]);
  return <Toast open={open} setOpen={setOpen} message={notify.message} type={notify?.type}/>;
};

export default AppNotification;
