import { createContext, useContext, useState } from "react";
import { AdminLogout } from "../api/auth";
import { useNotification } from "./NotificationContext";
import { IToastTypes } from "../components/toast/Toast";

const authContextObject = createContext<any>({
  init: (conf: any) => { },
  login: () => { },
  logout: () => { },
  getToken: () => { },
  getRoles: {},
  roles: () => { },
  isInitialized: true,
  isAuthenticated: () => false,
  hasRequiredRoles: (
    requiredRoles: string[] | undefined,
    roles: string[]
  ): boolean => {
    return false;
  },
});

const useAuth = () => useContext(authContextObject);

const AuthContextProvider = ({ children }: any) => {
  const [isInitialized, setIsInitialized] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAppInitialized, setIsAppInitialized] = useState(true);
  const [currentTitle, setCurrentTitle] = useState<string>('Sana Admin');

  const { setNotify } = useNotification();
  // const isAuthenticated = () => {}
  const logout = async () => {
    const authToken = localStorage.getItem("Auth_Token");

    await AdminLogout(authToken)
      .then((res) => {
        if (res.status === 200) {
          console.log(res, "res");
          localStorage.clear();
          setIsInitialized(false);
        }
      })
    console.info("Logging Out");
   
    

    // keycloak?.logout({redirectUri:window.location.origin}).then(()=>{
    //   keycloak.clearToken();
    // })
  };

  const getToken = () => { }

  const getUserName = () => { }


  return (
    <authContextObject.Provider
      value={{
        logout: logout,
        getToken: getToken,
        isAppInitialized: isAppInitialized,
        setAppInitialized: setIsAppInitialized,
        // init: (x: any) => initKeycloak(x),
        isInitialized: isInitialized,
        setIsInitialized: setIsInitialized,
        setIsAuthenticated: setIsAuthenticated,
        isAuthenticated: isAuthenticated,
        getUserName: getUserName,
        currentTitle: currentTitle,
        setCurrentTitle: setCurrentTitle,
      }}
    >
      {children}
    </authContextObject.Provider>
  )
}

export { useAuth, AuthContextProvider };
