import { Box, Button, Grid, Input, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { style } from '../../../util/themes/styles';
import editIcon from '../../../assets/images/editIcon.png';
import deleteIcon from '../../../assets/images/deleteIcon.png';
import { DeleteCategory, EditCategory } from '../../../api/discover';
import { useNotification } from '../../../util/NotificationContext';
import { IToastTypes } from '../../../components/toast/Toast';
import { DeleteCategoryContentList, EditCategoryContentList } from '../../../api/content';
import AddStockImage from './AddStockImage';
import StoryCard from './StoryCard';
import useDeviceType from '../../../util/useDeviceType';

const styles = {
    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .internal-autofill-selected': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#FFFFFF',
            '.MuiSelect-select, input': {
                padding: '8px 10px',
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& .MuiSelect-icon': {
                color: 'rgba(0, 0, 0, 0.54)'
            },
            '& .MuiInputBase-input': {
                WebkitBoxShadow: "0 0 0 1000px #FFFFFF inset",
                WebkitTextFillColor: "#FFF",
                caretColor: 'white',
            },
            '& fieldset': {
                border: '1px solid #959CA8'
            },
            '&:hover fieldset': {
                border: '1px solid #5B67FF'
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #5B67FF',
                filter: '0px 0px 3px rgba(0, 118, 222, 0.5)',
                borderRadius: '4px'
            },
            '&.Mui-error': {
                border: '1.5px solid  #EA1616',
                '& fieldset': {
                    border: '1.5px solid #EA1616',
                    filter: 'none',
                    borderRadius: '4px'
                }
            }
        },
        '& .MuiPaper-root': {
            backgroundColor: '#271A59'
        },
        '& .MuiOutlinedInput-root .MuiSelect-select': {
            '-webkit-box-shadow': 'none',
            caretColor: 'transparent'
        },
        '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-o821ap-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            backgroundColor: '#271A59'
        },
        [style.breakpoints.down('md')]: {
            fontSize: '12px'
        }
    },
    addRecordButton: {
        borderRadius: '8px',
        background: ' var(--Primary-color, #5D3FD3)',
        padding: ' 7px 16px',
        margin: '10px',
        height: '40px',
        '& .MuiTypography-root': {
            color: style.palette.text.primary,
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'capitalize',
            [style.breakpoints.down('md')]: {
                fontSize: '12px',
                fontWeight: 400,
            }
        },
        '& .MuiSvgIcon-root': {
            color: style.palette.text.primary
        },
        '&:hover': {
            backgroundColor: style.palette.primary[400],
            '& .MuiTypography-root': {
                color: style.palette.text.primary
            }
        },
    },
    queans: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        paddingLeft: '12px',
        [style.breakpoints.down('md')]: {
            flexDirection: 'column',
            flexWrap: 'wrap',
            paddingLeft: '0px',
            '& .css-zqq9ev-MuiGrid-root': {
                flexDirection: 'column',
                flexWrap: 'wrap',
            }
        }
    },
    question: {
        '& .MuiTypography-root h6': {
            fontSize: '16px'
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root h6': {
                fontSize: '10px'
            }
        }
    },
    listAlign: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiStack-root': {
            width: '100%',
            background: '#343942',
            padding: ' 10px',
            borderRadius: '8px',
        },
        '& .css-p1wwq1-MuiInputBase-root-MuiInput-root::after': {
            borderBottom: 'none'
        },
        img: {
            height: '20px'
        },
        [style.breakpoints.down('md')]: {
            '& .MuiTypography-root': {
                fontSize: '12px'
            }
        }
    },
    selectedChipView: {
        width: '100%',
        bgcolor: 'transparent',
        overflow: 'scroll',
        height: '300px',
    },
    selectedStock: {
        width: '100%',
        bgcolor: 'transparent',
        overflow: 'scroll',
        height: '100px',
    },
    selectedList: {
        borderRadius: '8px',
        padding: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: ' space-between',
        margin: '8px 0px',
        background: 'var(--linear-grediant-bg, linear-gradient(180deg, rgba(94, 94, 94, 0.30) -30.83%, rgba(255, 255, 255, 0.08) -0.4%, rgba(255, 255, 255, 0.27) 211.65%))'
    },

}

interface IEditDeleteList {
    handleClose?: any;
    listData?: any;
    dataFor?: string;
    listName?: any;
    selectedCategory?:string;
}


const EditDeleteContentCategory = (props: IEditDeleteList) => {
    const { handleClose, listData, dataFor, listName,selectedCategory } = props;
    const [list, setList] = useState([...listData]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
    const { setNotify } = useNotification();
    const [editListDetailModal, setEditListDetailModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({});
    const [editItemIndex,setEditItemIndex] = useState<any>();
    const [buttonText, setButtonText] = useState('Update');
    const [modalHeading, setModalHeading] = useState('Edit List');
    const {isMobile} = useDeviceType();
    const handleChange = (newName: any, index: any) => {
        const updatedItems = [...list];
        updatedItems[index].name = newName;
        setList(updatedItems);
    };

    const handleListItemClick = (itemId: any, item: any) => {
        const selectedIndex = selectedItems.findIndex(selected => selected._id === itemId);
        if (selectedIndex === -1) {
            setSelectedItems([...selectedItems, item]);
            setSelectedItemIds([...selectedItemIds,itemId])
            setList(list.filter(selected => selected._id !== itemId));
        } else {
            setSelectedItems(selectedItems.filter(selected => selected._id !== itemId));
            setSelectedItemIds(selectedItemIds.filter((selected:any)=> selected._id !== itemId));
        }
    }

    const handleSaveChanges = async () => {
        await EditCategoryContentList(list, listName._id)
            .then((res) => {
                if (res.status === 200) {
                    console.log("res?.data?.data",res?.data?.data);
                    
                    handleClose(res?.data?.data);
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
        handleClose();
    }

    const handleDeleteList = async () => {
        await DeleteCategoryContentList(selectedItemIds, listName._id)
            .then((res) => {
                if (res.status === 200) {
                    setNotify({
                        open: true,
                        type: IToastTypes.Success,
                        message: res?.data?.message,
                    });
                    handleClose(list);
                }
            }).catch((res) => {
                setNotify({
                    open: true,
                    type: IToastTypes.Error,
                    message: res?.response?.data?.message,
                });
            })
        handleClose();
    }

    const isSelected = (item: any) => {
        return selectedItemIds.includes(item);
    };

    const handleSubmit = (values:any) =>{
        console.log("Values",values);
        const updatedItems = [...list];
        console.log("updatedItems",updatedItems,editItemIndex);
        
        updatedItems[editItemIndex].name = values.name;
        updatedItems[editItemIndex].image = values.image;
        updatedItems[editItemIndex].color = values.color;
        setList(updatedItems);
        setEditListDetailModal(false);
    }

    const handleListEditClick = (index: any, item: any) => {
        setEditListDetailModal(true);
        setItemToEdit(item)
        setEditItemIndex(index);
        setButtonText('Update');
        setModalHeading('Edit List')
    }


    return (
        <>
        {!editListDetailModal ?
        <Box width={isMobile?'300px':'500px'} height={isMobile?'100%':'500px'} >
            {selectedItems?.length > 0 &&
                <ul style={styles.selectedStock} className="customScroll">
                    {selectedItems.map((item: any) => (
                        <li key={item.id} style={styles.selectedList}>
                            {item?.name}
                        </li>
                    ))}
                </ul>
            }
            <List sx={styles.selectedChipView} className="customScroll" >
                {listData?.map((list: any, index: any) => {
                    return (
                        <ListItem
                            disableGutters
                        >
                            <Grid sx={styles.listAlign}>
                                {dataFor === 'edit' ?
                                    <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                        <Input
                                            type='text'
                                            style={{ padding: "10px 15px", width: "100%" }}
                                            id="listName"
                                            value={list?.name}
                                            onChange={(e: any) => handleChange(e.target.value, index)}
                                            className="listName"
                                        />
                                        <img src={editIcon} alt='edit' height={'20px'} width={'20px'} onClick={() => handleListEditClick(index, list)}/>
                                    </Stack> :
                                    <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'} className={isSelected(list._id) ? 'selected' : ''} sx={{ cursor: 'pointer' }} onClick={() => handleListItemClick(list._id, list)} >
                                        <ListItemText primary={
                                            <Typography variant='h6' sx={styles.question}>{list?.name}</Typography>
                                        } />
                                        <img src={deleteIcon} alt='edit' height={'20px'} width={'20px'} onClick={() => handleListItemClick(list._id, list)} />
                                    </Stack>
                                }
                            </Grid>
                        </ListItem>
                    )
                })}
            </List>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                {dataFor === 'edit' ?
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleSaveChanges}
                        sx={styles.addRecordButton}>
                        <Typography>{'Save Changes'}</Typography>
                    </Button> :
                    <Button
                        type="submit"
                        color="primary"
                        onClick={handleDeleteList}
                        sx={styles.addRecordButton}>
                        <Typography>{'Submit'}</Typography>
                    </Button>}
            </Grid>
        </Box>
        : 
        selectedCategory === 'Story Card' ? 
        <StoryCard  storyCard={false} handleClose={handleSubmit} listId={listName._id} itemToEdit={itemToEdit} dataFor={dataFor}/> :
        <AddStockImage handleClose={handleSubmit} listId={listName._id} itemToEdit={itemToEdit} dataFor={dataFor} />
        }</>
    )
}

export default EditDeleteContentCategory