import { useLocation, useNavigate } from "react-router-dom";
import { ISideNavSubMenu } from "../../../util/sidemenuconfig";
import { Box, Divider, Grid, SvgIcon, Typography } from "@mui/material";
import { style } from "../../../util/themes/styles";
import { useState } from "react";
import useDeviceType from "../../../util/useDeviceType";
// import compass from 'boxicons';


interface INavBarItemProps {
    item: ISideNavSubMenu,
    isFirst: boolean

}

const styles = {
    containerDividerStyle: {
        mx: '20px',
        mt: '4px',
        mb: '24px',
        border: '1px solid #C6CAD1',
    },
    sectionTitleStyle: (isSelected: boolean) => ({
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 600,
        whiteSpace: 'pre-line',
        textAlign: 'center',
        color: isSelected ? style.palette.text.primary : style.palette.text.secondary,
        '&:hover': {
            '& .MuiTypography-root': {
                whiteSpace: 'no-wrap',
            }
        },
        [style.breakpoints.down('md')]: {
            fontSize: '10px',
            fontWeight: 500,


        }
    }),
    subMenuContainer: (isSelected: boolean, isMobile: boolean) => ({
        // height: '38px',
        // my: '13px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90px',
        backgroundColor: isSelected ? style.palette.primary[400] : 'initial',
        '&:hover': {
            cursor: 'pointer',
            // height: '46px',
            width: 'fit-content',
            flexDirection: 'row',
            padding: '16px',
            pr: '38px',
            zIndex:10,
            backgroundColor: style.palette.primary[400],
            color: style.palette.text.primary,
            borderRadius: '100px',
            boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2)',
            filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))',
            transition: 'all .3s ease-in-out',
            '& .MuiGrid-root': {
                justifyContent: 'start',
            },
            '& .MuiSvgIcon-root': {
                ml: '38px',
                color: '#FFFFFF'
            },
            '& .MuiTypography-root': {
                display: 'inline',
                color: style.palette.text.primary,
                fontSize: '16px',
                fontWeight: 500,
            },
            '& img': {
                margin: '0px 10px'
            }
        },
        [style.breakpoints.down('md')]: {
            fontSize: '10px',
            fontWeight: 500,
            height: '60px',
           
            '&:hover': {
                padding: '16px',
                borderRadius: '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90px',
                backgroundColor: isSelected ? style.palette.primary[400] : 'initial',
                '& .MuiTypography-root': {
                    display: 'inline',
                    color: style.palette.text.primary,
                    fontSize: '12px',
                    fontWeight: 500,
                },
            }
        }

    }),
    iconContainer: (isSelected: boolean) => ({
        py: '7px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            // color: isSelected ? style?.palette?.primary[500] : '#7E8592',
        },

    }),
    sectionStyle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    navbarIcon: {
        height: '24px',
        width: '24px',
        tintColor: `${style.palette.primary[50]}`
    }
}
const NavBarItem = ({ item: { title, urlKey, icon }, isFirst }: INavBarItemProps) => {
    const [isHovered, setHovered] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { isMobile } = useDeviceType();
    const isSelected = pathname.split('/')[1] === urlKey.split('/')[1];
    const onTabClick = (urlKey: string) => {
        // if (urlKey.includes('logout')) {
        //     // logout();
        //     return;
        // }
        navigate(urlKey);
    };

    const filterStyle = {
        marginBottom: '4px',
        filter: isHovered ? 'grayscale(100%)' : 'none',
        transition: 'filter 0.3s ease-in-out',
        // mixBlendMode: `multiply,
        color: 'blue',
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <Box sx={styles.subMenuContainer(isSelected, isMobile)} onClick={() => onTabClick(urlKey)}>
            <img src={icon} alt="ICON" style={{ ...filterStyle, height: isMobile ? '18px' : '24px', width: isMobile ? '18px' : '24px' }} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} />
            {title && <Typography sx={styles.sectionTitleStyle(isSelected)}>{title}</Typography>}
        </Box>
    )
}

export default NavBarItem;