import axios from "axios"
import { GET_ANALYTICS, GET_USER_GRAPH } from "../constant/common"


export const getCreatedUser = async() =>{
    try{
        return await axios.get(`${GET_USER_GRAPH}`);
    }
    catch (error) {
        throw error;
    }
}

export const getAnalytics = async() =>{
    try{
        return await axios.get(`${GET_ANALYTICS}`);
    }
    catch (error) {
        throw error;
    }
}