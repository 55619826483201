// export const WEBURL = "http://stock.prometteur.in:8181/admin";      //Local
export const WEBURL = "http://3.28.139.120:8181/admin";      // Live

//User Management section
export const GET_USER  = `${WEBURL}/user/getUser?adminApproved=true`;
export const DELETE_USER = `${WEBURL}/user/deleteUser`;
export const EDIT_USER = `${WEBURL}/user/editUser`;
export const ADD_USER = `${WEBURL}/user/userCreate`;

//Stock Management section
export const GET_STOCK = `${WEBURL}/stock/categorizeStock`;
export const DELETE_STOCK = `${WEBURL}/stock/deleteStock`;
export const EDIT_STOCK = `${WEBURL}/stock/editStocks`;
export const CREATE_STOCK = `${WEBURL}/stock/createStock`;

// Feedback section
export const FEEDBACK = `${WEBURL}/dashboard/userFeedback`;

// auth section
export const LOGIN =`${WEBURL}/user/login`;
export const LOGOUT =`${WEBURL}/user/logout`;

//Notification section
export const SEND_NOTIFICATION = `${WEBURL}/dashboard/userEmailNotification`;
export const GET_NOTIFICATION_CATEGORY = `${WEBURL}/dashboard/notificationCategory`;
export const GET_NOTIFICATION_HISTORY = `${WEBURL}/dashboard/getPushNotification`;

//Dashboard graph and analytics section
export const GET_USER_GRAPH = `${WEBURL}/user/userCreatedChart`;
export const GET_ANALYTICS = `${WEBURL}/dashboard/analytics`;

//Waitlist section
export const GET_WAITLIST_USERS = `${WEBURL}/user/getUser?adminApproved=false`;
export const APPROVE_WAITLIST = `${WEBURL}/user/getUserWaitlist`;
export const USER_KYC = `${WEBURL}/user/getKYC`;

//Switchlist section
export const GET_SWITCHLIST = `${WEBURL}/switchList/getSwitchList`;
export const UPDATE_SWITCHLIST = `${WEBURL}/switchList/updateSwitchList`;

//Discover section
export const GET_DISCOVERLIST = `${WEBURL}/discover/getList`;
export const GET_DISCOVER_CREATE_LIST = `${WEBURL}/discover/createList`;
export const DISCOVER_EDIT = `${WEBURL}/discover/editList`;
export const DISCOVER_DELETE_LIST = `${WEBURL}/discover/deleteList`;
export const DISCOVER_CATEGORY_NAME = `${WEBURL}/discover/createCategoryList`;
export const DISCOVER_CATEGORY_EDIT = `${WEBURL}/discover/editCategoryList`;
export const DISCOVER_CATEGORY_DELETE = `${WEBURL}/discover/deleteCategoryList`;
export const ADD_STOCK_IN_CATEGORY = `${WEBURL}/discover/addStockInCategory`;

//Global settings section
export const WATCHLIST_LIMIT = `${WEBURL}/user/watchListLimit`;
export const TRADE_LIMIT = `${WEBURL}/dashboard/tradeLimit`;
export const STOCKS_LIMIT = `${WEBURL}/user/stockLimit`;
export const CHANGE_DEFAULT_AMOUNT = `${WEBURL}/user/changeDefaultAmount`;
export const EDIT_CRON = `${WEBURL}/cron/editCronTime`;

export const GET_TRADE_LIMIT = `${WEBURL}/dashboard/getTradeLimit`;
export const GET_STOCK_LIMIT = `${WEBURL}/user/getStockLimit`;
export const GET_WATCHLIST_LIMIT = `${WEBURL}/user/getWatchlistLimit`;
export const GET_DEFAULT_AMOUNT = `${WEBURL}/user/getDefaultAmount`;

//Content list type section
export const GET_CONTENTLIST = `${WEBURL}/content/getContentList`;
export const CREATE_CONTENTLIST = `${WEBURL}/content/createContentList`;
export const EDIT_CONTENTLIST = `${WEBURL}/content/editContentList`;
export const DELETE_CONTENTLIST = `${WEBURL}/content/deleteContentList`;
export const CREATE_CATEGORY_CONTENT = `${WEBURL}/content/createCategoryContentList`;
export const EDIT_CATEGORY_cONTENT = `${WEBURL}/content/editCategoryContentList`;
export const DELETE_CATEGORY_cONTENT = `${WEBURL}/content/deleteCategoryContentList`;
export const ADD_STOCK_IN_CONTENT_CATEGORY = `${WEBURL}/content/addStockInContentCategory`;
export const ADD_IMAGES_IN_STOCKCATEGORIES = `${WEBURL}/content/editCategoryContentListImages`;
export const DELETE_IMAGES_IN_STOCKCATEGORY = `${WEBURL}/content/deleteStoryContentImage`;






